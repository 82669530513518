import HorizontalLine from '../UI/HorizontalLine';
import EmptyBlock from '../UI/EmptyBlock';
import { BsFillPlayFill } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { AiOutlineFileSearch } from "react-icons/ai";
import assessmentArtLarge from '../../images/assessment-art-large.svg';
import classes from './AssessmentDetail.module.css';
import { useState, useEffect } from 'react';
import { database } from '../../util/firebase';
import { ref, onValue } from 'firebase/database';
import Capstone from './Capstone';

const AssessmentDetail = (props) => {
    let standards = '';

    let matchingStandards = [];
    if(props.assessment.standards && props.standardsToShow) {
        Object.keys(props.standardsToShow).forEach(state => {
            Object.keys(props.standardsToShow[state]).forEach(standard_group => {
                if(props.assessment.standards[state]) {
                    if(props.assessment.standards[state]) {
                        if(props.assessment.standards[state][standard_group]) {
                            Object.keys(props.assessment.standards[state][standard_group]).forEach(code => {
                                matchingStandards.push(code);
                            })
                        };
                    };
                };
            })
        })
    };

    if(matchingStandards.length > 0) {
        standards = matchingStandards.map(standard => standard.split(' ').slice(0, 1)).slice(0, 1) + matchingStandards.map(standard => standard.split(' ').slice(-1)).join(', ');
    };

    const [hasLoaded, setHasLoaded] = useState(false);
    const [capstoneKeys, setCapstoneKeys] = useState([]);
    useEffect(() => {
        onValue(ref(database, `assessments/${props.assessment._id}/stimuli`), (snapshot) => {
            if(snapshot.exists()) {
                setCapstoneKeys(Object.keys(snapshot.val()))
            } else {
                setCapstoneKeys([]);
            }});
        setHasLoaded(true);
    }, [props.assessment]);

    return (
        <>
            { props.onAssign &&
                <button className={ classes.actionButton } onClick={ props.onAssign.bind(null, props.assessment._id, props.assessment.name, props.assessment.description, props.assessment.capstone_num) }>
                    <div className={ classes.centerWrapper}>
                        Assign&nbsp;<BsFillPlayFill />
                    </div>
                </button>
            }
            { props.onOpenInEditor && 
                <button className={ `${classes.actionButtonAlt} ${classes.moreShifted}` } onClick={ props.onOpenInEditor.bind(null, props.assessment._id) }>
                    <div className={ classes.centerWrapper}>
                    <FaRegEdit />&nbsp;Edit
                    </div>
                </button>
            }
            {
                props.onGoDeep &&
                <button className={ `${classes.actionButtonAlt} ${classes.shifted}` } onClick={ props.onGoDeep.bind(null, props.assessment._id) }>
                    <div className={ classes.centerWrapper}>
                    <AiOutlineFileSearch />&nbsp;Detailed View
                    </div>
                </button>
            }
            {
                props.onEditDetails && !props.onOpenInEditor && !props.onAssign &&
                <button className={ `${classes.actionButton} ${classes.even}` } onClick={ props.onEditDetails.bind(null, props.assessment._id) }>
                    <div className={ classes.centerWrapper}>
                        Edit Details
                    </div>
                </button>
            }
            {
                // TODO: Make this archive instead of delete
                props.onDelete && !props.onOpenInEditor && !props.onAssign &&
                <button className={ classes.actionButtonAlt } onClick={ props.onDelete }>
                    <div className={ classes.centerWrapper}>
                        Delete Assessment
                    </div>
                </button>
            }
            <div className={classes.artWrapper}>
                <img src={ assessmentArtLarge } alt='' className={ classes.assessmentArt } />
            </div>
            <div className={ classes.popupContent }>
                <div className={ classes.assessmentName }>{ props.assessment.name }{ !props.assessment.published && ' (Unpublished)'}</div>
                <div className={ classes.assessmentDescription }>{ props.assessment.description }</div>
                <div className={ classes.detailFlex }>
                    <div className={classes.leftBox}>
                        <div className={ classes.capstonesWrapper }>{ props.assessment.capstone_num } Capstone{ props.assessment.capstone_num !== 1 && 's' }</div>
                    </div>
                    <div className={classes.rightBox}>
                        <div className={ classes.standardsWrapper }>{ standards }</div>
                    </div>
                </div>
                <HorizontalLine />
                { capstoneKeys?.length > 0 && 
                    capstoneKeys.map((key, index) =>
                        <Capstone capstoneKey={key} index={index} key={key} onRemove={props.onRemove} last={index === capstoneKeys.length - 1} numbered preview canExpandQuestions withLines isAdmin={props.isAdmin} />
                )}
                { hasLoaded && capstoneKeys === null && <EmptyBlock>No questions found.</EmptyBlock>}
            </div>
        
        </>
    );
};

export default AssessmentDetail;