import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import { ref, onValue, update } from 'firebase/database';
import QuestionAndAnswers from '../components/Cards/QuestionAndAnswers';
import Button from '../components/UI/Button';
import FeedbackPopup from '../components/UI/FeedbackPopup';
import ProgressBar from '../components/UI/ProgressBar';
import EmptyBlock from '../components/UI/EmptyBlock';
import AssessmentCompleteBlock from '../components/UI/AssessmentCompleteBlock';

const Attempt = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const [hasLoadedCapstones, setHasLoadedCapstones] = useState(false);
    const [unfinishedAttemptData, setUnfinishedAttemptData] = useState({});
    const [capstonesData, setCapstonesData] = useState(null);
    const [selectedAnswersData, setSelectedAnswersData] = useState(null);
    const [monitoringGroupID, setMonitoringGroupID] = useState('');
    const [attemptStatus, setAttemptStatus] = useState('');
    const { id: attempt_id } = useParams()
    useEffect(() => {
        const attemptRef = ref(database, `attempts/${attempt_id}/stimuli`);
        onValue(attemptRef, (snapshot) => {
            if (!snapshot.val()) return;

            const capstonesArray = Object.keys(snapshot.val()).map((capstoneKey) => {
                const capstone = snapshot.val()[capstoneKey];
                const questionsArray = Object.keys(capstone.questions).map((questionKey) => {
                        return {
                            question_key: questionKey,
                            ...capstone.questions[questionKey],
                        };
                });
            
                questionsArray.sort((a, b) => a.index - b.index);
                    return {
                        capstone_key: capstoneKey,
                        ...capstone,
                        questions: questionsArray,
                    };
            });
            
            capstonesArray.sort((a, b) => a.index - b.index);
            setCapstonesData(capstonesArray);
        });
        setHasLoadedCapstones(true);
    }, [attempt_id]);
    useEffect(() => {
        const selectedAnswersRef = ref(database, `attempts/${attempt_id}/selected_answers`);
        onValue(selectedAnswersRef, (snapshot) => {setSelectedAnswersData(snapshot.val());});
    }, [attempt_id]);
    useEffect(() => {
        if(!monitoringGroupID || !authUserData) return;
        if(!authUserData.token) return;

        const statusRef = ref(database, `attempts/${attempt_id}/status`);
        onValue(statusRef, (snapshot) => {
            if(snapshot.val() === 'available' && authUserData?.unfinished_attempts && authUserData.unfinished_attempts[attempt_id]) {
                const updates = {};
                updates[`attempts/${attempt_id}/is_focus`] = true;
                updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/is_focus`] = true;
                updates[`attempts/${attempt_id}/status`] = 'in_progress';
                updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/status`] = 'in_progress';
                updates[`users/${authUserData.token}/unfinished_attempts/${attempt_id}/status`] = 'in_progress';
                updates[`attempts/${attempt_id}/start_time`] = Date.now();
                update(ref(database), updates);
            };
        
            setAttemptStatus(snapshot.val());
        });
    }, [attempt_id, monitoringGroupID, authUserData]);
    useEffect(() => {
        const monitoringGroupRef = ref(database, `attempts/${attempt_id}/monitoring_group_id`);
        onValue(monitoringGroupRef, (snapshot) => {setMonitoringGroupID(snapshot.val());});
    }, [attempt_id]);
    useEffect(() => {
        if(!authUserData) return;
        if(!authUserData.token) return;

        const unfinishedAttemptRef = ref(database, `users/${authUserData.token}/unfinished_attempts/${attempt_id}`);
        onValue(unfinishedAttemptRef, (snapshot) => {setUnfinishedAttemptData(snapshot.val());});
    }, [attempt_id, authUserData])

    const [hasFinalized, setHasFinalized] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [hasAcceptedFeedback, setHasAcceptedFeedback] = useState(true);

    const [compositeDisplay, setCompositeDisplay] = useState({});
    useEffect(() => {
        if(!hasLoadedCapstones || !capstonesData) return;
        if(hasSubmitted || !hasAcceptedFeedback) return;

        if(selectedAnswersData === null || !selectedAnswersData) {
            // TODO: Make this only add the anchors if it is drag and drop
            const updatedDisplay = {
                capstone_key: capstonesData[0].capstone_key,
                index: capstonesData[0].index,
                image: capstonesData[0].image,
                text: capstonesData[0].text,
                question: { ...capstonesData[0].questions[0] },
                selected_answers: [],
                incorrect_message: capstonesData[0].questions[0].incorrect_message
            }

            if(capstonesData[0].questions[0].type === 'drag_and_drop') {
                updatedDisplay.question.anchors = {...capstonesData[0].questions[0].anchors};
                updatedDisplay.question.anchor_answer_pairs = {};
            
                for(let key in capstonesData[0].questions[0].anchors) {
                    updatedDisplay.question.anchor_answer_pairs[key] = null;
                };
            }

            setCompositeDisplay(updatedDisplay);
        } else {
            let compositeDisplay = null;
            let next_question_found = false;
            for(let i = 0; i < capstonesData.length; i++) {
                const capstone_key = capstonesData[i].capstone_key;
                
                if(!next_question_found && selectedAnswersData.hasOwnProperty(capstone_key)) {
                    for (let j = 0; j < capstonesData[i].questions.length; j++) {
                        const question_key = capstonesData[i].questions[j].question_key;
                        if (!selectedAnswersData[capstone_key].hasOwnProperty(question_key) && !next_question_found) {
                            next_question_found = true;
                            compositeDisplay = {
                                capstone_key: capstonesData[i].capstone_key,
                                index: capstonesData[i].index,
                                image: capstonesData[i].image,
                                text: capstonesData[i].text,
                                question: { ...capstonesData[i].questions[j] },
                                selected_answers: [],
                                incorrect_message: capstonesData[i].questions[j].incorrect_message,
                            };

                            if(capstonesData[i].questions[j].type === 'drag_and_drop') {
                                compositeDisplay.question.anchors = capstonesData[i].questions[j].anchors;
                                compositeDisplay.question.anchor_answer_pairs = {};

                                for(let key in capstonesData[i].questions[j].anchors) {
                                    compositeDisplay.question.anchor_answer_pairs[key] = null;
                                };
                            };
                        };
                    };
                } else if(!next_question_found) {
                    next_question_found = true;
                    compositeDisplay = {
                        capstone_key: capstonesData[i].capstone_key,
                        index: capstonesData[i].index,
                        image: capstonesData[i].image,
                        text: capstonesData[i].text,
                        question: { ...capstonesData[i].questions[0] },
                        selected_answers: [],
                        incorrect_message: capstonesData[i].questions[0].incorrect_message
                    };

                    if(capstonesData[i].questions[0].type === 'drag_and_drop') {
                        compositeDisplay.question.anchors = capstonesData[i].questions[0].anchors;
                        compositeDisplay.question.anchor_answer_pairs = {};
                    
                        for(let key in capstonesData[i].questions[0].anchors) {
                            compositeDisplay.question.anchor_answer_pairs[key] = null;
                        };
                    };
                };

            };

            setCompositeDisplay(compositeDisplay);
        };

    }, [selectedAnswersData, capstonesData, hasLoadedCapstones, hasSubmitted, hasAcceptedFeedback]);

    const [feedbackToDisplay, setFeedbackToDisplay] = useState('');
    useEffect(() => {
        if(!hasSubmitted) return;
        if(!selectedAnswersData[compositeDisplay.capstone_key][compositeDisplay.question.question_key].hasOwnProperty('was_correct')) return;

        if(selectedAnswersData[compositeDisplay.capstone_key][compositeDisplay.question.question_key].was_correct) {
            setFeedbackToDisplay("Great job.");
        } else {
            setFeedbackToDisplay(`Not quite. ${compositeDisplay.incorrect_message}`);
        }
    }, [selectedAnswersData, compositeDisplay, hasSubmitted]);

    const selectAnswerHandler = (key) => {
        if(compositeDisplay.question.type === 'multi_select') setCompositeDisplay(data => {
            if(data.selected_answers.includes(key)) {
                // get the index of the key
                const index = data.selected_answers.indexOf(key);

                // remove it from the array
                const updatedArray = [...data.selected_answers.slice(0, index), ...data.selected_answers.slice(index + 1)];

                // return the array without the key
                return {...data, selected_answers: updatedArray};
            } else {
                // push the key to the array
                let updatedArray = [...data.selected_answers];
                updatedArray.push(key);

                // return the array
                return {...data, selected_answers: updatedArray};
            }});
        if(compositeDisplay.question.type === 'multiple_choice') setCompositeDisplay(data => ({...data, selected_answers: [key]}));
    };

    const submitAnswerHandler = () => {
        if(percent_complete === 100 && !hasFinalized) {
            const updates = {};
            updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/percent_complete`] = percent_complete;
            updates[`attempts/${attempt_id}/status`] = 'submitted';
            updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/status`] = 'submitted';
            updates[`attempts/${attempt_id}/end_time`] = Date.now();
            updates[`users/${authUserData.token}/unfinished_attempts/${attempt_id}`] = {};
            updates[`users/${authUserData.token}/finished_attempts/${attempt_id}/`] = { name: unfinishedAttemptData.name, feedback_text: 'Generating feedback...' };
            update(ref(database), updates);
            setHasFinalized(true);
            return;
        };

        const updates = {};
        updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/percent_complete`] = percent_complete;
        if (compositeDisplay.question.type === 'short_constructed_response') {
            updates[`attempts/${attempt_id}/selected_answers/${compositeDisplay.capstone_key}/${compositeDisplay.question.question_key}/answer_text_long`] = compositeDisplay.selected_answers[0];
        } else if (compositeDisplay.question.type === 'text_entry') {
            updates[`attempts/${attempt_id}/selected_answers/${compositeDisplay.capstone_key}/${compositeDisplay.question.question_key}/answer_text`] = compositeDisplay.selected_answers[0];
        } else if (compositeDisplay.question.type === 'drag_and_drop') {
            updates[`attempts/${attempt_id}/selected_answers/${compositeDisplay.capstone_key}/${compositeDisplay.question.question_key}/anchor_answer_pairs`] = compositeDisplay.question.anchor_answer_pairs;
        } else {
            for(let i = 0; i < compositeDisplay.selected_answers.length; i++) {
                updates[`attempts/${attempt_id}/selected_answers/${compositeDisplay.capstone_key}/${compositeDisplay.question.question_key}/answer_ids/${compositeDisplay.selected_answers[i]}`] = true;
            }
        }
        update(ref(database), updates);
        setHasSubmitted(true);
        setHasAcceptedFeedback(false);
    };

    const acceptFeedback = () => {
        setHasAcceptedFeedback(true);
        setHasSubmitted(false);
        setFeedbackToDisplay('');

        if(percent_complete === 100 && !hasFinalized) {
            const updates = {};
            updates[`attempts/${attempt_id}/status`] = 'submitted';
            updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/status`] = 'submitted';
            updates[`attempts/${attempt_id}/end_time`] = Date.now();
            updates[`users/${authUserData.token}/unfinished_attempts/${attempt_id}`] = {};
            updates[`users/${authUserData.token}/finished_attempts/${attempt_id}/`] = {...unfinishedAttemptData, status: null};
            update(ref(database), updates);
            setHasFinalized(true);
        };
    };

    let percent_complete = 0;
    if (selectedAnswersData !== null) {
        let total_questions = 0
        let answered_questions = 0

        for(const key in capstonesData) {
            // eslint-disable-next-line
            for(const secondaryKey in capstonesData[key].questions) {
                total_questions++;
            };
        };

        for(const key in selectedAnswersData) {
            for(const secondaryKey in selectedAnswersData[key]) {
                if(selectedAnswersData[key][secondaryKey].hasOwnProperty('was_correct'))
                    answered_questions++;
            };
        };

        percent_complete = Math.round(answered_questions / total_questions * 100);
    };

    useEffect(() => {
        if(!attempt_id || !monitoringGroupID) return;

        const handlePageBlur = () => {
            const updates = {};
            updates[`attempts/${attempt_id}/is_focus`] = false;
            updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/is_focus`] = false;
            update(ref(database), updates);
        };
    
        const handlePageFocus = () => {
            const updates = {};
            updates[`attempts/${attempt_id}/is_focus`] = true;
            updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/is_focus`] = true;
            update(ref(database), updates);
        };

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                handlePageFocus();
            } else {
                handlePageBlur();
            }
        };

        window.addEventListener('blur', handlePageBlur);
        window.addEventListener('focus', handlePageFocus);
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('pagehide', handlePageBlur);
        window.addEventListener('pageshow', handlePageFocus);
    
        const updates = {};
        updates[`attempts/${attempt_id}/is_focus`] = true;
        updates[`monitoring_groups/${monitoringGroupID}/attempts/${attempt_id}/is_focus`] = true;
        update(ref(database), updates);

        // Clean up the event listeners when the component unmounts
        return () => {
            window.removeEventListener('blur', handlePageBlur);
            window.removeEventListener('focus', handlePageFocus);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('pagehide', handlePageBlur);
            window.removeEventListener('pageshow', handlePageFocus);
        };
    }, [attempt_id, monitoringGroupID]);

    const onEditTextEntry = (value) => {
        if(value === '') setCompositeDisplay(data => ({...data, selected_answers: []}));
        else setCompositeDisplay(data => ({...data, selected_answers: [value]}));
    }

    const onSelectRegion = (answer_regions) => {
        let updatedAnswers = []

        for(let i = 0; i < answer_regions.length; i++) {
            if(answer_regions[i].isCorrect) {
                for(let j = 0; j < Object.keys(compositeDisplay.question.answers).length; j++) {
                    const key = Object.keys(compositeDisplay.question.answers)[j];
                    const rect = compositeDisplay.question.answers[key];
                    if(rect.startX === answer_regions[i].startX && rect.startY === answer_regions[i].startY && rect.endX === answer_regions[i].endX && rect.endY === answer_regions[i].endY) {
                        updatedAnswers.push(key);
                    };
                };
            };
        };

        setCompositeDisplay(data => ({...data, selected_answers: updatedAnswers}));
    };

    const onMatchAnswer = (draggable_id, anchor_id) => {
        if(anchor_id) {
            const updatedDisplay = {...compositeDisplay};
            if(updatedDisplay.question.anchor_answer_pairs[anchor_id] === null) {
                updatedDisplay.question.anchor_answer_pairs[anchor_id] = draggable_id;
                for(let key in updatedDisplay.question.anchor_answer_pairs) {
                    if(updatedDisplay.question.anchor_answer_pairs[key] === draggable_id && key !== anchor_id) {
                        updatedDisplay.question.anchor_answer_pairs[key] = null;
                    
                    }
                }
            }
            setCompositeDisplay(updatedDisplay);
        } else {
            const updatedDisplay = {...compositeDisplay};
            for(let key in updatedDisplay.question.anchor_answer_pairs) {
                if(updatedDisplay.question.anchor_answer_pairs[key] === draggable_id) {
                    updatedDisplay.question.anchor_answer_pairs[key] = null;
                }
            }

            setCompositeDisplay(updatedDisplay);
        }
    };

    const onSelectRange = (answer_ranges) => {
        let updatedAnswers = []

        for(let i = 0; i < answer_ranges.length; i++) {
            if(answer_ranges[i].isCorrect) {
                for(let j = 0; j < Object.keys(compositeDisplay.question.answers).length; j++) {
                    const key = Object.keys(compositeDisplay.question.answers)[j];
                    const region = compositeDisplay.question.answers[key];
                    if(region.startChar === answer_ranges[i].startChar && region.endChar === answer_ranges[i].endChar && region.text === answer_ranges[i].text) {
                        updatedAnswers.push(key);
                    };
                };
            };
        };

        setCompositeDisplay(data => ({...data, selected_answers: updatedAnswers}));
    }

    return(
        <SingleColumn>
            { (attemptStatus === 'in_progress' || attemptStatus === 'available') &&
                <>
                {compositeDisplay?.capstone_key && <QuestionAndAnswers capstoneData={compositeDisplay} numbered index={compositeDisplay.index} onSelect={selectAnswerHandler} onEditTextEntry={onEditTextEntry} onSelectRegion={onSelectRegion} onMatchAnswer={onMatchAnswer} onSelectRange={onSelectRange}/>}
                
                <Button disabled={((!compositeDisplay ||
                    !compositeDisplay.selected_answers ||
                    compositeDisplay.selected_answers.length === 0) &&
                    (!compositeDisplay ||
                    !compositeDisplay.question ||
                    !compositeDisplay.question.anchor_answer_pairs ||
                    Object.values(compositeDisplay.question.anchor_answer_pairs)
                        .filter(val => val !== null)
                        .length === 0)) || hasSubmitted} onClick={submitAnswerHandler}>
                            Submit
                </Button>
                <ProgressBar percent={percent_complete}/>
                {hasSubmitted &&
                    <FeedbackPopup show title={feedbackToDisplay !== '' ? (feedbackToDisplay === "Great job." ? "Correct!" : "Incorrect!") : ''}>
                        <p>{feedbackToDisplay === '' ? 'Checking answer...' : feedbackToDisplay}</p>
                        <Button onClick={acceptFeedback} disabled={hasAcceptedFeedback || feedbackToDisplay === ''}>{percent_complete === 100 ? 'Finish & Submit' : 'Next Question'}</Button>
                    </FeedbackPopup> 
                }
                </>
            }
            { attemptStatus === 'submitted' && !hasFinalized &&
                <EmptyBlock>This attempt has already been submitted.</EmptyBlock>
            }
            { attemptStatus === 'submitted' && hasFinalized &&
                <AssessmentCompleteBlock />
            }
            { attemptStatus === 'paused' &&
                <EmptyBlock>This attempt has been paused by your instructor.</EmptyBlock>
            }
            { (attemptStatus === 'ready' || attemptStatus === 'queued') &&
                <EmptyBlock>This attempt has not yet been started by your instructor.</EmptyBlock>
            }
            { !attemptStatus && 
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </SingleColumn>
    );
};

export default Attempt;