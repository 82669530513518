import classes from './QuestionDetailed.module.css';
import { HiOutlineTrophy } from 'react-icons/hi2';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked, MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { database } from '../../util/firebase';
import { ref, get, child } from 'firebase/database';
import onePointIcon from '../../images/rubric-one-point.svg';
import ImageCanvas from '../UI/ImageCanvas';
import TapTheWordInput from '../UI/TapTheWordInput';
import DragDropAnchorSelector from '../UI/DragDropAnchorSelector';

const QuestionDetailed = (props) => {
    /* console.log(props.questionData) */

    const [correctAnswers, setCorrectAnswers] = useState({});
    useEffect(() => {
        if(!props.expanded) return;
        if(!props.showCorrect) return;
        if(Object.keys(correctAnswers)?.length > 0) return;

        get(child(ref(database), `answers/${props.questionData.key}`))
            .then((snapshot) => {
                if (snapshot.exists()) { 
                    setCorrectAnswers(snapshot.val());
                }
        })

    }, [props, correctAnswers])

    const [globalStats, setGlobalStats] = useState({});
    useEffect(() => {
        if(!props.showStats) return;
        if(Object.keys(globalStats)?.length > 0) return;

        get(child(ref(database), `global_stats/${props.questionData.stimuliKey}/${props.questionData.key}`))
            .then((snapshot) => {
                if (snapshot.exists()) {
                    setGlobalStats(snapshot.val());
                }
        })

    }, [props, globalStats])

    const getStat = (answer_id) => {
        if(!globalStats.total_attempts || globalStats.total_attempts < 10) return ''
        else {
            if(globalStats[answer_id]) {
                return `${Math.floor(globalStats[answer_id] / globalStats.total_attempts * 100)}%`
            } else {
                return `0%`;
            }
        }
    }

    return(
        <div className={ `${classes.questionWrapper} ${props.last ? classes.last : ''}` }>
            <div className={ `${classes.question} ${ props.expanded ? classes.autoHeight : '' }` }>
                <div className={classes.flexRow}>
                    {props.questionData.is_capstone && 
                        <div className={classes.iconSpace}>
                        <HiOutlineTrophy />
                        </div>    
                    }
                    <div className={`${classes.questionText} ${props.questionData.is_capstone ? classes.hasIcon : ''}`} onClick={props.expanded ? props.claimExpanded.bind(null, '') : props.claimExpanded.bind(null, props.questionData.key)}>
                        {
                            props.showStats && globalStats &&
                            <>
                                <div className={classes.percentBubble}>
                                    {(globalStats.total_attempts < 10 || !globalStats.total_attempts) ? 'New' : `${Math.floor(globalStats.correct_count / globalStats.total_attempts * 100)}%`}
                                </div>&nbsp;
                            </>
                        }
                        Q:&nbsp;{props.questionData.prompt}
                    </div>
                </div>
                {
                    props.questionData.answers && props.expanded &&
                    <div className={ `${classes.answers}` }>
                        {
                            props.questionData.type === 'multiple_choice' && Object.keys(props.questionData.answers)
                                .map(key => key !== 'percent_selected' && <div className={classes.answerRow} key={key}>
                                    { (props.showStats && getStat(key) !== '') ?
                                        <div className={classes.globalStat}>{getStat(key)}</div> : ''
                                    }
                                    <div className={classes.icon}>
                                        {props.showCorrect ? (correctAnswers[key]?.is_correct ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />) : <MdOutlineRadioButtonUnchecked />}
                                    </div>
                                    <div className={classes.answerText}>
                                        {props.questionData.answers[key]}
                                    </div>
                                </div>)
                        }
                        {
                            props.questionData.type === 'multi_select' && Object.keys(props.questionData.answers)
                                .map(key => <div className={classes.answerRow} key={key}>
                                        { (props.showStats && getStat(key) !== '') ?
                                            <div className={classes.globalStat}>{getStat(key)}</div> : ''
                                        }
                                        <div className={classes.icon}>
                                            {props.showCorrect ? (correctAnswers[key]?.is_correct ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />) : <MdOutlineCheckBoxOutlineBlank />}
                                        </div>
                                        <div className={classes.answerText}>
                                            {props.questionData.answers[key]}
                                        </div>
                                    </div>)
                        }
                        { props.questionData.type === 'text_entry' &&
                            <div className={classes.textAnswers}>
                                <strong>Accepted Answers:</strong>&nbsp;
                                {
                                    Object.keys(props.questionData.answers).map((key, index) => <span key={key}>{props.questionData.answers[key]}{index === Object.keys(props.questionData.answers).length - 1? '' : <>,&nbsp;</>}</span>)
                                }
                            </div>
                        }
                        { props.questionData.type === 'short_constructed_response' && 
                            <div className={classes.rubricAnswers}>
                                <strong>Rubric:</strong><br />
                                {
                                    Object.keys(props.questionData.answers).map((key, index) => 
                                        <div className={classes.rubricRow} key={key}>
                                            <img src={ onePointIcon }  className={ classes.onePointIcon } alt='' />
                                            {props.questionData.answers[key]}
                                        </div>
                                )}
                            </div>
                        }
                        { props.questionData.type === 'hot_spot' && !props.showCorrect &&
                            <div className={classes.hotSpotAnswers}>
                                <strong>Answer Regions:</strong>
                                <ImageCanvas imagesrc={props.questionData.image} initialAnswers={Object.keys(props.questionData.answers)?.map(key => ({ rect: props.questionData.answers[key], isCorrect: false}))} static onChangeAnswerRegions={null} onError={null} />
                            </div>
                        }
                        { props.questionData.type === 'hot_spot' && props.showCorrect && Object.keys(correctAnswers).length > 0 &&
                            <div className={classes.hotSpotAnswers}>
                                <strong>Answer Regions:</strong>
                                <ImageCanvas imagesrc={props.questionData.image} initialAnswers={Object.keys(props.questionData.answers)?.map(key => ({ rect: props.questionData.answers[key], isCorrect: correctAnswers[key]?.is_correct}))} static onChangeAnswerRegions={null} onError={null} />
                            </div>
                        }
                        { props.questionData.type === 'tap_the_word' && !props.showCorrect &&
                            <div className={classes.tapTheWordAnswers}>
                                <strong>Answers:</strong>
                                <TapTheWordInput stimuliText={props.questionData.stimulus_text} initialAnswers={Object.keys(props.questionData.answers)?.map(key => ({ region: props.questionData.answers[key], isCorrect: false}))} static onChangeAnswerRanges={null} onError={null} />
                            </div>
                        }
                        { props.questionData.type === 'tap_the_word' && props.showCorrect && Object.keys(correctAnswers).length > 0 &&
                            <div className={classes.tapTheWordAnswers}>
                                <strong>Answers:</strong>
                                <TapTheWordInput stimuliText={props.questionData.stimulus_text} initialAnswers={Object.keys(props.questionData.answers)?.map(key => ({ region: props.questionData.answers[key], isCorrect: correctAnswers[key]?.is_correct}))} static onChangeAnswerRanges={null} onError={null} />
                            </div>
                        }
                        { props.questionData.type === 'drag_and_drop' &&
                            <>
                                <DragDropAnchorSelector imagesrc={props.questionData.image} initialAnchors={props.questionData.anchors} static onChangeAnchors={null} onError={null} />
                                <div className={classes.label}><strong>Draggable Answers{ props.showCorrect ? ' & Matching Anchors' : '' }:</strong></div>
                                {
                                    Object.keys(props.questionData.answers).map((key, index) => 
                                        <div className={`${classes.anchorRow} ${index === props.questionData.anchors.length - 1 ? classes.last : ''}`} key={key}>
                                            {props.showCorrect && Object.keys(props.questionData.anchors).filter(secondaryKey => correctAnswers[key]?.matching_anchors[secondaryKey]).map(secondaryKey => <div key={secondaryKey} className={classes.anchorLabel}>{props.questionData.anchors[secondaryKey].display_num}</div>)}
                                            <div className={classes.anchorText}>{props.questionData.answers[key]}</div>
                                        </div>
                                )}
                            </>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default QuestionDetailed;