import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import PageHeaderText from '../components/UI/PageHeaderText';
import { database } from '../util/firebase';
import { ref, onValue } from 'firebase/database';
import CapstoneDetailed from '../components/Cards/CapstoneDetailed';
import ScrollableCard from '../components/UI/ScrollableCard';
import AssessmentHeader from '../components/Cards/AssessmentHeader';
import LeftColumn from '../components/UI/LeftColumn';
import RightColumn from '../components/UI/RightColumn';
import CheckboxInput from '../components/UI/CheckboxInput';
import DropdownInput from '../components/UI/DropdownInput';
import RevealBelow from '../components/UI/RevealBelow';
import TextBlock from '../components/UI/TextBlock';
import EmptyBlock from '../components/UI/EmptyBlock';
import { getAuth, sendEmailVerification } from 'firebase/auth';

const AssessmentDeepDive = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);
    const { id: assessment_id } = useParams()

    const navigate = useNavigate();

    /* const [hasLoaded, setHasLoaded] = useState(false); */
    const [standardsToShow, setStandardsToShow] = useState('')
    const [assessment, setAssessment] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    useEffect(() => {
        onValue(ref(database, `assessments/${assessment_id}`), (snapshot) => {
            if(snapshot.exists()) {
                setAssessment(snapshot.val());

                let standards = '';

                let matchingStandards = [];
                if(snapshot.val()?.standards && authUserData?.standards_environment) {
                    Object.keys(authUserData.standards_environment).forEach(state => {
                        Object.keys(authUserData.standards_environment[state]).forEach(standard_group => {
                            if(snapshot.val().standards[state]) {
                                if(snapshot.val().standards[state]) {
                                    if(snapshot.val().standards[state][standard_group]) {
                                        Object.keys(snapshot.val().standards[state][standard_group]).forEach(code => {
                                            matchingStandards.push(code);
                                        })
                                    };
                                };
                            };
                        })
                    })
                };

                if(matchingStandards.length > 0) {
                    standards = matchingStandards.map(standard => standard.split(' ').slice(0, 1)).slice(0, 1) + matchingStandards.map(standard => standard.split(' ').slice(-1)).join(', ');
                };

                
                let filterOptions = [{ text: 'All Standards', value: 'all-standards'}]
                for(let i = 0; i < matchingStandards.length; i++) {
                    filterOptions.push({ text: matchingStandards[i], value: matchingStandards[i] })
                }

                setFilterOptions(filterOptions);
                setStandardsToShow(standards);
            };
        });
        /* setHasLoaded(true); */
    }, [assessment_id, authUserData]);

    const [capstoneData, setCapstoneData] = useState({});
    useEffect(() => {
        if(!assessment?.stimuli) return

        // get all capstone data 
        for(let key in assessment.stimuli) {
            onValue(ref(database, `stimuli/${key}`), (snapshot) => {
                if(snapshot.exists()) {
                    setCapstoneData(state => {
                        let newCapstoneData = {...state};
                        newCapstoneData[key] = snapshot.val();
                        return newCapstoneData
                    })
                }
            });
        }
    }, [assessment])

    const [showAnswers, setShowAnswer] = useState(false);
    const [showGlobalStats, setShowGlobalStats] = useState(false);

    const toggleGlobalStats = () => {
        setShowGlobalStats(state => !state);
    }

    const toggleAnswers = () => {
        setShowAnswer(state => !state);
    }

    const [filteredStandard, setFilteredStandard] = useState('all-standards')
    const standardFilterHandler = (val) => {
        setFilteredStandard(val);
    };

    function isStandardPresent(data, standard) {
        // Check if 'stimuli' key exists and is an object
        if (typeof data.standards !== 'object') {
            return false;
        }
        
        // Traverse each territory/state
        for (let territory in data.standards) {
            if (data.standards.hasOwnProperty(territory)) {
                let grades = data.standards[territory];
                
                // Traverse each grade level
                for (let grade in grades) {
                    if (grades.hasOwnProperty(grade)) {
                        let standards = grades[grade];
                        
                        // Check if the standard exists in the grade level
                        if (standards.hasOwnProperty(standard)) {
                            return true;
                        }
                    }
                }
            }
        }
        
        // Standard not found
        return false;
    }

    const [verificationSent, setVerificationSent] = useState(false);
    const sendVerification = () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            sendEmailVerification(user)
                .then(() => {
                    console.log('Verification email sent.');
                    setVerificationSent(true);
                })
                .catch((error) => {
                    console.error('Error sending verification email:', error);
                });
        } else {
            console.error('No authenticated user data available.');
        }
    };

    return (
        <div style={{ display: 'flex', minHeight: '100%', justifyContent: 'center', flexWrap: 'wrap'}}>
            { authUserData && authUserData.isEmailVerified && !('student' in authUserData?.roles) && !('unverified_teacher' in authUserData?.roles) && !('rejected_verification' in authUserData?.roles) &&
            <>
                <LeftColumn stackable>
                    <PageHeaderText><MdArrowBack onClick={ () => {navigate('/assessments')} } style={{cursor: 'pointer'}}/>&nbsp;Detailed View</PageHeaderText>
                    <RevealBelow>
                    <PageHeaderText small>Options</PageHeaderText>
                        <CheckboxInput value={showGlobalStats} checked={showGlobalStats} onChange={toggleGlobalStats} last={false}>Show global stats</CheckboxInput>
                        <CheckboxInput value={showAnswers} checked={showAnswers} onChange={toggleAnswers} last={true}>Show correct answers</CheckboxInput>
                        {
                            filterOptions && filterOptions.length > 2 &&
                            <>
                                <PageHeaderText small>Filter by Standard</PageHeaderText>
                                <DropdownInput onChange={standardFilterHandler} value={filteredStandard} options={filterOptions} />
                            </>
                        }
                    </RevealBelow>
                    <ScrollableCard>
                        {
                            assessment &&
                            <AssessmentHeader assessment={assessment} standards={standardsToShow}/>
                        }
                        {
                            assessment?.stimuli && 
                            Object.keys(assessment.stimuli).filter(key => filteredStandard === 'all-standards' || isStandardPresent(capstoneData[key], filteredStandard))
                                .map((key, index) => <CapstoneDetailed key={key} capstoneData={{...capstoneData[key], stimuliKey: key}} showCorrect={showAnswers} showStats={showGlobalStats} last={index === Object.keys(assessment.stimuli).filter(key => filteredStandard === 'all-standards' || isStandardPresent(capstoneData[key], filteredStandard)).length - 1} />)
                        }
                    </ScrollableCard>
                </LeftColumn>
                <RightColumn narrow stackable>
                    <br />
                    <br />
                    <br />
                    <TextBlock>
                        In <strong>Detailed View</strong>, you can see all capstone and non-capstone questions. Click any question to see its answer choices.
                    </TextBlock>
                    <br />
                    <PageHeaderText small>Options</PageHeaderText>
                    <CheckboxInput value={showGlobalStats} checked={showGlobalStats} onChange={toggleGlobalStats} last={false}>Show global stats*</CheckboxInput>
                    <CheckboxInput value={showAnswers} checked={showAnswers} onChange={toggleAnswers} last={true}>Show correct answers</CheckboxInput>
                    {
                        filterOptions && filterOptions.length > 2 &&
                        <>
                            <PageHeaderText small>Filter by Standard</PageHeaderText>
                            <DropdownInput onChange={standardFilterHandler} value={filteredStandard} options={filterOptions} />
                        </>
                    }
                    <TextBlock>
                        <span style={{color: '#C9C9C9'}}>*Global stats represent the total answer choice frequency across all Brain Raider assessments.</span>
                    </TextBlock>
                </RightColumn>
            </>
            }
            {
                (!authUserData || ('student' in authUserData?.roles) || ('rejected_verification' in authUserData?.roles)) &&
                <EmptyBlock sideMargins>You are not authorized to view this resource.</EmptyBlock>
            }
            {
                (authUserData && ('unverified_teacher' in authUserData?.roles) && authUserData.isEmailVerified) &&
                <EmptyBlock sideMargins>We are working on verifying your teacher status - this page will unlock within 24 hours!</EmptyBlock>
            }
            {
                (authUserData && !(('student' in authUserData?.roles) || ('rejected_verification' in authUserData?.roles)) && !authUserData.isEmailVerified) && !verificationSent &&
                <EmptyBlock sideMargins>Please <span onClick={sendVerification} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>verify your email</span> to access this page.</EmptyBlock>
            }
            {
                (authUserData && !(('student' in authUserData?.roles) || ('rejected_verification' in authUserData?.roles)) && !authUserData.isEmailVerified) && verificationSent &&
                <EmptyBlock sideMargins><strong>Verification email sent!</strong> Please check your junk mail - the email might land there because the link includes a very long unique verification code.<br /><br />Refresh this page after you have verified your email.</EmptyBlock>
            }
        </div>
    );
};

export default AssessmentDeepDive;