import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import Slider from '../components/UI/Slider';
import Button from '../components/UI/Button';
import ButtonGroup from '../components/UI/ButtonGroup';
import StudentMonitor from '../components/Cards/StudentMonitor';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import CheckboxInput from '../components/UI/CheckboxInput';
import StandaloneError from '../components/UI/StandaloneError';
import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import { ref, update, onValue, increment, get, child, set } from 'firebase/database';
import EmptyBlock from '../components/UI/EmptyBlock';
import { getAnalytics, logEvent } from "firebase/analytics";

// TODO: Only attach listeners to monitoring groups that are 'active'
// TODO: Make starting any assessment check to make sure it is 'ready' first
const MonitorPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const [monitorGroupData, setMonitorGroupData] = useState({});
    useEffect(() => {
        const updateMonitorGroupData = (key, group_data) => {
            const updatedMonitorGroupData = monitorGroupData;
            updatedMonitorGroupData[key] = {...group_data};
            setMonitorGroupData((prevState) => ({...prevState, [key]: group_data}));
        };

        if(authUserData?.monitoring_groups) {
            Object.keys(authUserData.monitoring_groups).forEach(key => {
                const monitorGroupRef = ref(database, `monitoring_groups/${key}`);
                onValue(monitorGroupRef, (snapshot) => {updateMonitorGroupData(snapshot?.ref._path.pieces_[1], snapshot?.val());});
            });
        };
    
        // eslint-disable-next-line
    }, [authUserData?.monitoring_groups]);

    const [showDrawer, setShowDrawer] = useState(false);
    const [specificAttempts, setSpecificAttempts] = useState([]);

    const [submissionError, setSubmissionError] = useState('');

    const checkboxClickHandler = (key) => {
        setSpecificAttempts((prevState) => {
            if (prevState.includes(key)) {
              return prevState.filter((k) => k !== key);
            } else {
              return [...prevState, key];
            }
        });
        setSubmissionError('');
    };

    const submitFormHandler = (event) => {
        event.preventDefault();

        if(specificAttempts.length === 0) {
            setSubmissionError('You must select at least one attempt.');
            return;
        };

        setShowDrawer(false);
    };

    const monitor_groups_to_display = Object.keys(monitorGroupData).filter(key => monitorGroupData[key].active).filter(key => specificAttempts.includes(key) || specificAttempts.length === 0).map(key =>
        ({ key: key,
            assessment_name: monitorGroupData[key].assessment.name,
            group_name: monitorGroupData[key].group.name,
            attempts: Object.keys(monitorGroupData[key].attempts).map(secondaryKey =>
                ({ key: secondaryKey, ...monitorGroupData[key].attempts[secondaryKey]}))}));

    const clearSpecificAttemptsHandler = () => {
        setSpecificAttempts([]);
    };

    const [sliderIndex, setSliderIndex] = useState(0);

    const startStudentAttempt = async (user_id, attempt_id, monitoring_id, status) => {
        if(status === 'available' || status === 'in_progress' || status === 'queued') return;

        const updates = {};
        let newStatus = 'in_progress';
        if(status === 'ready') {newStatus = 'available'};

        updates[`attempts/${attempt_id}/status`] = newStatus;
        updates[`users/${user_id}/unfinished_attempts/${attempt_id}/status`] = newStatus;
        updates[`monitoring_groups/${monitoring_id}/attempts/${attempt_id}/status`] = newStatus;

        const teacher_group_key = authUserData?.monitoring_groups[monitoring_id]?.group_id;
        if(teacher_group_key) updates[`users/${authUserData.token}/groups/${teacher_group_key}/active_assessments`] = increment(1);
        update(ref(database), updates);
    };

    const pauseStudentAttempt = async (user_id, attempt_id, monitoring_id, status) => {
        if(status === 'paused' || status === 'queued') return;

        const updates = {};
        updates[`attempts/${attempt_id}/status`] = 'paused';
        updates[`users/${user_id}/unfinished_attempts/${attempt_id}/status`] = 'paused';
        updates[`monitoring_groups/${monitoring_id}/attempts/${attempt_id}/status`] = 'paused';

        const teacher_group_key = authUserData?.monitoring_groups[monitoring_id]?.group_id;
        if(teacher_group_key && status !== 'ready') updates[`users/${authUserData.token}/groups/${teacher_group_key}/active_assessments`] = increment(-1);
        update(ref(database), updates);
    };

    const endStudentAttempt = async (user_id, attempt_id, monitoring_id, assessment_name, status) => {
        if(status === 'submitted' || status === 'queued') return;

        const updates = {};
        updates[`attempts/${attempt_id}/status`] = 'submitted';
        updates[`monitoring_groups/${monitoring_id}/attempts/${attempt_id}/status`] = 'submitted';
        updates[`attempts/${attempt_id}/end_time`] = Date.now();
        updates[`users/${user_id}/unfinished_attempts/${attempt_id}`] = {};
        updates[`users/${user_id}/finished_attempts/${attempt_id}`] = { name: assessment_name, feedback_text: 'This attempt was closed by your teacher.' };

        // Add to the active assessment counter because it will be decremented by the grading function
        if(status === 'paused' || status === 'ready') {
            const teacher_group_key = authUserData?.monitoring_groups[monitoring_id]?.group_id;
            if(teacher_group_key) updates[`users/${authUserData.token}/groups/${teacher_group_key}/active_assessments`] = increment(1);
        }
        update(ref(database), updates);
    };

    const startAllAttempts = async (monitor_group) => {
        const updates = {};
        let num_attempts = 0;
        const teacher_group_key = authUserData?.monitoring_groups[monitor_group.key]?.group_id;

        // get the monitoring group and see if it is a demo group
        try {
            get(child(ref(database), `monitoring_groups/${monitor_group.key}`))
                .then((snapshot) => {
                    if (snapshot.exists()) {
                        if(snapshot.val().is_demo) {
                            set(child(ref(database), `user_accounts/${authUserData.token}/has_started_demo_assessment`), true);

                            try {
                                const analytics = getAnalytics();
                                logEvent(analytics, 'has_started_demo_assessment');
                            } catch (error) {
                                console.error('Error logging event:', error);
                            }

                            get(child(ref(database), `active_demo_assessments/${monitor_group.key}`))
                            .then((snapshot) => {
                                if(!snapshot.exists()) {
                                    console.log('restarting demo group')
                                    set(child(ref(database), `active_demo_assessments/${monitor_group.key}`), true)
                                }
                            })
                        } else {
                            set(child(ref(database), `user_accounts/${authUserData.token}/has_started_real_assessment`), true);

                            try {
                                const analytics = getAnalytics();
                                logEvent(analytics, 'has_started_real_assessment');
                            } catch (error) {
                                console.error('Error logging event:', error);
                            }
                        }
                    }
                })
        } catch(error) {
            console.error(error)
        }

        for (const attempt of monitor_group.attempts) {
            if(attempt.status !== 'submitted' && attempt.status !== 'in_progress' && attempt.status !== 'available' && attempt.status !== 'queued') {
                let newStatus = 'in_progress';
                if(attempt.status === 'ready') {newStatus = 'available'};

                updates[`attempts/${attempt.key}/status`] = newStatus;
                updates[`users/${attempt.user_id}/unfinished_attempts/${attempt.key}/status`] = newStatus;
                updates[`monitoring_groups/${monitor_group.key}/attempts/${attempt.key}/status`] = newStatus;
                num_attempts++;
            };
        };
        
        if(teacher_group_key) updates[`users/${authUserData.token}/groups/${teacher_group_key}/active_assessments`] = increment(num_attempts);

        update(ref(database), updates);
    };

    const pauseAllAttempts = async (monitor_group) => {
        const updates = {};
        let num_attempts = 0;
        const teacher_group_key = authUserData?.monitoring_groups[monitor_group.key]?.group_id;

        for (const attempt of monitor_group.attempts) {
            if(attempt.status !== 'submitted' && attempt.status !== 'paused' && attempt.status !== 'queued') {
                updates[`attempts/${attempt.key}/status`] = 'paused';
                updates[`users/${attempt.user_id}/unfinished_attempts/${attempt.key}/status`] = 'paused';
                updates[`monitoring_groups/${monitor_group.key}/attempts/${attempt.key}/status`] = 'paused';
                if(attempt.status !== 'ready') num_attempts++;
            };
        };
        
        if(teacher_group_key) updates[`users/${authUserData.token}/groups/${teacher_group_key}/active_assessments`] = increment(-1 * num_attempts);
        update(ref(database), updates);
    };

    const endAllAttempts = async (monitor_group) => {
        if(window.confirm('Are you sure? You will not be able to reopen any of these attempts.')) {
            setConcludedMonitorGroups(groups => [...groups, monitor_group.key]);

            const updates = {};
            let num_attempts = 0;
            const teacher_group_key = authUserData?.monitoring_groups[monitor_group.key]?.group_id;

            for (const attempt of monitor_group.attempts) {
                if(attempt.status !== 'submitted' && attempt.status !== 'queued') {
                    updates[`attempts/${attempt.key}/status`] = 'submitted';
                    updates[`monitoring_groups/${monitor_group.key}/attempts/${attempt.key}/status`] = 'submitted';
                    updates[`attempts/${attempt.key}/end_time`] = Date.now();
                    updates[`users/${attempt.user_id}/unfinished_attempts/${attempt.key}`] = {};
                    updates[`users/${attempt.user_id}/finished_attempts/${attempt.key}`] = { name: monitor_group.assessment_name, feedback_text: 'This attempt was closed by your teacher.' };
                    if(attempt.status === 'paused' || attempt.status === 'ready') num_attempts++;
                };
            };

            // We add to the number of active assessments here (only add paused and ready ones) because the grading function will decrement this value
            if(teacher_group_key) updates[`users/${authUserData.token}/groups/${teacher_group_key}/active_assessments`] = increment(num_attempts);

            // Archive the result on the teacher's results page if it was totally empty
            let is_empty = true;
            for(const attempt in monitorGroupData[monitor_group.key].attempts) {
                console.log(monitorGroupData[monitor_group.key].attempts[attempt])
                if(!(monitorGroupData[monitor_group.key].attempts[attempt].status === 'ready' || monitorGroupData[monitor_group.key].attempts[attempt].status === 'available' || !monitorGroupData[monitor_group.key].attempts[attempt].percent_complete || monitorGroupData[monitor_group.key].attempts[attempt].percent_complete === 0)) {
                    is_empty = false;
                }
            }
            if(is_empty) updates[`users/${authUserData.token}/monitoring_groups/${monitor_group.key}/archived`] = true;
            update(ref(database), updates);
        };
    };

    if(sliderIndex === 1 && monitor_groups_to_display.length === 0) {
        clearSpecificAttemptsHandler();
        setSliderIndex(0);
    };

    const [concludedMonitorGroups, setConcludedMonitorGroups] = useState([]);

    return (
        <SingleColumn>
            { authUserData && !('student' in authUserData?.roles) &&
                <>
                    <div>
                        <PageHeaderText>Monitor</PageHeaderText>
                        <Slider index={sliderIndex} firstOption='All Attempts' secondOption='Specific Attempts...' onFirstOption={() => {clearSpecificAttemptsHandler(); setSliderIndex(0);}} onSecondOption={() => {setShowDrawer(true); setSubmissionError(''); setSliderIndex(1);}}/>
                        { 
                            monitor_groups_to_display && 
                            monitor_groups_to_display.filter(monitor_group => !concludedMonitorGroups.includes(monitor_group.key)).map((monitor_group) =>
                            <div key={monitor_group.key}>
                                <PageHeaderText small key={monitor_group.key}>{monitor_group.assessment_name} ({monitor_group.group_name})</PageHeaderText>
                                { monitor_group.attempts?.filter(attempt => attempt.status !== 'submitted').sort((a, b) => monitor_group.attempts[a]?.last_name.toLowerCase().localeCompare(monitor_group.attempts[b]?.last_name.toLowerCase())).map((attempt, index) => 
                                    <StudentMonitor attempt={attempt} monitoringGroup={monitor_group.key} assessmentName={monitor_group.assessment_name} last={index === monitor_group.attempts.filter(attempt => attempt.status !== 'submitted').length - 1} key={attempt.key} onStart={startStudentAttempt} onPause={pauseStudentAttempt} onEnd={endStudentAttempt} />
                                )}
                                <ButtonGroup>
                                <Button fitted onClick={startAllAttempts.bind(null, monitor_group)}>Start All</Button>
                                <Button fitted onClick={pauseAllAttempts.bind(null, monitor_group)} yellow>Pause All</Button>
                                <Button fitted onClick={endAllAttempts.bind(null, monitor_group)} red>End All</Button>
                                </ButtonGroup>
                            </div>
                        )}
                        {
                            (!monitor_groups_to_display || monitor_groups_to_display.length) === 0 &&
                            <EmptyBlock>No active attempts found.</EmptyBlock>
                        }
                    </div>
                    <PulloutDrawer show={showDrawer} header={'Select Attempts'} onCancel={() => {setShowDrawer(false); clearSpecificAttemptsHandler(); setSliderIndex(0);}}>
                    <form>
                        <PageHeaderText small>Select attempts to monitor...</PageHeaderText>
                        { monitorGroupData &&
                            Object.keys(monitorGroupData).filter(key => monitorGroupData[key].active === true).length === 0 &&
                            <EmptyBlock>No active attempts found.</EmptyBlock>
                        }
                        { monitorGroupData &&
                            Object.keys(monitorGroupData).filter(key => monitorGroupData[key].active === true).map((key, index) => <CheckboxInput value={key} checked={specificAttempts.includes(key)} key={key} onChange={checkboxClickHandler} last={index === Object.keys(monitorGroupData).filter(key => monitorGroupData[key].active === true).length - 1}>{monitorGroupData[key].assessment.name} ({monitorGroupData[key].group.name})</CheckboxInput>)
                        }
                        <Button onClick={ submitFormHandler } disabled={monitorGroupData && Object.keys(monitorGroupData).filter(key => monitorGroupData[key].active === true).length === 0}>Submit</Button>
                        <StandaloneError>{ submissionError }</StandaloneError>
                    </form>
                    </PulloutDrawer>
                </>
            }
            {
                (!authUserData || ('student' in authUserData?.roles)) &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </SingleColumn>
    );
}

export default MonitorPage;