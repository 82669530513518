import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import EmptyBlock from '../components/UI/EmptyBlock';
import TextInput from '../components/UI/TextInput';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import Button from '../components/UI/Button';
import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import { push, child, set, /*remove,*/ update, ref, onValue } from 'firebase/database';
import FeedbackTable from '../components/Cards/FeedbackTable';
import StandaloneError from '../components/UI/StandaloneError';
import TopRightButton from '../components/UI/TopRightButton';

const FeedbackPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const [allFeedbackObject, setAllFeedbackObject] = useState(null);
    useEffect(() => {
        onValue(ref(database, `feedback`), (snapshot) => {setAllFeedbackObject(snapshot.val());});
    }, []);

    var feedback = []
    if (allFeedbackObject) {Object.keys(allFeedbackObject).forEach((key) => {feedback.push({_id: key, ...allFeedbackObject[key]})});};

    const [sort, setSort] = useState(0);
    const sortClickHandler = () => {
        setSort(state => {
            if(state === 0) {return -1;}
            if(state === -1) {return 1;}
            return 0;
        });
    };

    const [filterText, setFilterText] = useState('');
    const sortAndFilterFeedback = () => {
        let modifiedFeedback = [...feedback];

        if(filterText !== '') {
            modifiedFeedback = modifiedFeedback.filter(
                item => filterText ?
                (item.concept.toLowerCase().includes(filterText.toLowerCase()) ||
                item.skills.join(' ').toLowerCase().includes(filterText.toLowerCase()) ||
                item.misconceptions?.join(' ').toLowerCase().includes(filterText.toLowerCase())) :
                item
            );
        };

        if(sort === 1) {
            modifiedFeedback.sort((a, b) => {
                if(a.concept.toLowerCase() < b.concept.toLowerCase()) {return -1;}
                if(a.concept.toLowerCase() > b.concept.toLowerCase()) {return 1;}
                return 0;
            })
        } else if(sort === -1) {
            modifiedFeedback.sort((a, b) => {
                if(a.concept.toLowerCase() < b.concept.toLowerCase()) {return 1;}
                if(a.concept.toLowerCase() > b.concept.toLowerCase()) {return -1;}
                return 0;
            })
        }
        
        modifiedFeedback.reverse();

        return modifiedFeedback;
    };

    const sortedFilteredFeedback = sortAndFilterFeedback();

    const [showDetails, setShowDetails] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modified, setModified] = useState(false);
    const [editingFeedback, setEditingFeedback] = useState({ _id: '', concept: '', skills: [''], misconceptions: [''] });
    const [editingFeedbackErrors, setEditingFeedbackErrors] = useState({ concept: '', all_skills: '', skills: [''], all_misconceptions: '', misconceptions: [''] });
    const editFeedbackHandler = (feedback) => {
        let feedbackToEdit = JSON.parse(JSON.stringify(feedback));
        if(feedbackToEdit.skills?.length > 0) {feedbackToEdit.skills.push('');}
        else {feedbackToEdit.skills = ['']};
        if(feedbackToEdit.misconceptions?.length > 0) {feedbackToEdit.misconceptions.push('');}
        else {feedbackToEdit.misconceptions = ['']};
        setEditingFeedback(feedbackToEdit);
        let initialSkillErrors = new Array(feedbackToEdit.skills.length).fill('');
        let initialMisconceptionErrors = new Array(feedbackToEdit.misconceptions.length).fill('');
        setEditingFeedbackErrors({ concept: '', all_skills: '', skills: initialSkillErrors, all_misconceptions: '', misconceptions: initialMisconceptionErrors })
        setShowDetails(true);
    };

    const deleteFeedbackHandler = (feedback_id) => {
        if(window.confirm('Are you sure?')) {
            // remove(ref(database, `feedback/${feedback_id}`));
            const updates = {};
            updates[`feedback/${feedback_id}/archived`] = true;
            update(ref(database), updates)
        };
    };

    const skillChangeHandler = (index, new_value) => {
        setModified(true);
        const updatedSkillText = new_value;
        let updatedSkills = [...editingFeedback.skills];
        let updatedErrors = [...editingFeedbackErrors.skills];
        updatedSkills[index] = updatedSkillText;
        updatedErrors[index] = false;

        // Clear error message if there are no remaining errors
        let errorCounter = 0;
        for(let i = 0; i < updatedSkills.length; i++) {
            if(updatedErrors[i]) {errorCounter++;};
        };
        if(errorCounter === 0) {setEditingFeedbackErrors(state => ({...state, all_skills: ''}))};

        // Add another row if the user has typed text into the last row, or remove the last row if it is empty
        if(index === updatedSkills.length - 1 && updatedSkillText !== '') {
            updatedSkills.push('');
            updatedErrors.push(false)
        } else if(index === updatedSkills.length - 2 && updatedSkillText === '' && updatedSkills.length > 3) {
            updatedSkills.pop();
            updatedErrors.pop();
        };
        
        setEditingFeedback(state => ({...state, skills: updatedSkills}));
        setEditingFeedbackErrors(state => ({...state, skills: updatedErrors}));
    };

    const misconceptionChangeHandler = (index, new_value) => {
        setModified(true);
        const updatedMisconceptionText = new_value;
        let updatedMisconceptions = [...editingFeedback.misconceptions];
        let updatedErrors = [...editingFeedbackErrors.misconceptions];
        updatedMisconceptions[index] = updatedMisconceptionText;
        updatedErrors[index] = false;
        setEditingFeedbackErrors(state => ({...state, misconceptions: updatedErrors}))

        // Clear error message if there are no remaining errors
        let errorCounter = 0;
        for(let i = 0; i < updatedMisconceptions.length; i++) {
            if(updatedErrors[i]) {errorCounter++;};
        };
        if(errorCounter === 0) {setEditingFeedbackErrors(state => ({...state, all_misconceptions: ''}))};

        // Add another row if the user has typed text into the last row, or remove the last row if it is empty
        if(index === updatedMisconceptions.length - 1 && updatedMisconceptionText !== '') {
            updatedMisconceptions.push('');
            updatedErrors.push('')
        } else if(index === updatedMisconceptions.length - 2 && updatedMisconceptionText === '' && updatedMisconceptions.length > 2) {
            updatedMisconceptions.pop();
            updatedErrors.pop();
        };
        
        setEditingFeedback(state => ({...state, misconceptions: updatedMisconceptions}));
    };

    const submitFeedbackHandler = async (event) => {
        event.preventDefault();
    
        if(!modified) {return;};

        // Validate the entered skills and display feedback to the user
        const skillArray = [];
        let updatedSkillErrors = [...editingFeedbackErrors.skills];
        let skillCounter = 0;
        let skillErrorCounter = 0;
        let skillsSoFar = [];

        for(let i = 0; i < editingFeedback.skills.length; i++) {
            if(editingFeedback.skills[i] === '' && i !== editingFeedback.skills.length - 1) {
                updatedSkillErrors[i] = true;
                skillErrorCounter++;
            }
            if(editingFeedback.skills[i] !== '') {
                if(editingFeedback.skills[i].includes('.')) {
                    setEditingFeedbackErrors(errors => ({...errors, all_skills: "Skills must not include periods."}));
                    updatedSkillErrors[i] = true;
                    skillErrorCounter++;
                };
                if(editingFeedback.skills[i].split(" ")[0].slice(-3) !== 'ing') {
                    setEditingFeedbackErrors(errors => ({...errors, all_skills: "The first word of each skill must be a verb ending in 'ing'."}));
                    updatedSkillErrors[i] = true;
                    skillErrorCounter++;
                }
                if(Array.from(editingFeedback.skills[i])[0] !== Array.from(editingFeedback.skills[i])[0].toLowerCase()) {
                    setEditingFeedbackErrors(errors => ({...errors, all_skills: "The first letter of each skill must be lowercase."}));
                    updatedSkillErrors[i] = true;
                    skillErrorCounter++;
                }

                if(skillsSoFar.includes(editingFeedback.skills[i])) {
                    setEditingFeedbackErrors(errors => ({...errors, all_skills: 'There cannot be duplicate skills.'}));
                    updatedSkillErrors[i] = true;
                    skillErrorCounter++;
                } else {
                    skillsSoFar.push(editingFeedback.skills[i])
                };
    
                skillArray.push(editingFeedback.skills[i]);
                skillCounter++;
            };
        };
    
        // Validate the entered misconceptions and display feedback to the user
        const misconceptionArray = [];
        let updatedMisconceptionErrors = [...editingFeedbackErrors.misconceptions];
        let misconceptionErrorCounter = 0;
        let misconceptionsSoFar = [];
        for(let i = 0; i < editingFeedback.misconceptions.length; i++) {
            if(editingFeedback.misconceptions[i] !== '') {
                if(editingFeedback.misconceptions[i].includes(',') || editingFeedback.misconceptions[i].includes('.')) {
                    setEditingFeedbackErrors(errors => ({...errors, all_misconceptions: "Misconceptions must not include punctuation."}));
                    updatedMisconceptionErrors[i] = true;
                    misconceptionErrorCounter++;
                };

                if(misconceptionsSoFar.includes(editingFeedback.misconceptions[i])) {
                    setEditingFeedbackErrors(errors => ({...errors, all_misconceptions: 'There cannot be duplicate misconceptions.'}));
                    updatedMisconceptionErrors[i] = true;
                    misconceptionErrorCounter++;
                } else {
                    misconceptionsSoFar.push(editingFeedback.misconceptions[i])
                };

                misconceptionArray.push(editingFeedback.misconceptions[i]);
            };
        };

        // Validate the entered concept and display feedback to the user
        if(skillCounter < 2 || skillErrorCounter !== 0 || misconceptionErrorCounter !== 0 || editingFeedback.concept === '' || editingFeedback.concept.includes('.') || editingFeedback.concept.includes(',')) {
            if(skillCounter < 2) {setEditingFeedbackErrors(errors => ({...errors, all_skills: "There must be at least 2 skills for each concept."}));};
            if(skillErrorCounter !== 0) {setEditingFeedbackErrors(errors => ({...errors, skills: updatedSkillErrors}))};
            if(editingFeedback.concept === '') {setEditingFeedbackErrors(errors => ({...errors, concept: "Concept cannot be blank."}));};
            if(editingFeedback.concept.includes('.') || editingFeedback.concept.includes(',')) {setEditingFeedbackErrors(errors => ({...errors, concept: "Concept cannot contain punctuation."}));};
            if(misconceptionErrorCounter !== 0) {setEditingFeedbackErrors(errors => ({...errors, misconceptions: updatedMisconceptionErrors}))};
            return;
        };

        setIsSubmitting(true);
        const feedbackData = { concept: editingFeedback.concept, skills: skillArray, misconceptions: misconceptionArray };

        // If this is new feedback, add it to the database and get the ID to add it to the local DOM
        if(!editingFeedback._id) {
            const newFeedbackKey = push(child(ref(database), 'feedback')).key;
            set(ref(database, `feedback/${newFeedbackKey}`), feedbackData);
        };

        // If this is edited feedback, edit its existing database entry and the local DOM
        if(editingFeedback._id) {
            set(ref(database, `feedback/${editingFeedback._id}`), feedbackData);
        };

        // Reset the form
        setIsSubmitting(false);
        setEditingFeedback({ _id: '', concept: '', skills: [''], misconceptions: [''] });
        setEditingFeedbackErrors({ concept: '', all_skills: '', skills: '', all_misconceptions: '', misconceptions: '' });
        setModified(false);
        setShowDetails(false);
    };
    
    return (
        <SingleColumn wide>
            { allFeedbackObject && authUserData && ('admin' in authUserData?.roles) &&
                <>
                    <PageHeaderText>Feedback</PageHeaderText>
                    <TopRightButton icon='add' onClick={ () => {setEditingFeedback({ _id: '', concept: '', skills: [''], misconceptions: [''] }); setEditingFeedbackErrors({ concept: '', all_skills: '', skills: [''], all_misconceptions: '', misconceptions: [''] }); setModified(false); setShowDetails(true);}}/>
                    <TextInput name='search' leftIcon='search' placeholder='Search...' nospaceafter value={filterText} onChange={ (value) => {setFilterText(value)} } />
                    <FeedbackTable sorted={sort} onSort={sortClickHandler} feedback={sortedFilteredFeedback} onEdit={editFeedbackHandler} onDelete={deleteFeedbackHandler} />
                    <PulloutDrawer show={showDetails} header={editingFeedback._id === '' ? 'Add Feedback' : 'Edit Feedback'} onCancel={() => {setShowDetails(false); setEditingFeedback({ _id: '', concept: '', skills: [''], misconceptions: [''] }); setEditingFeedbackErrors({ concept: '', all_skills: '', skills: [''], all_misconceptions: '', misconceptions: [''] }); setModified(false);}}>
                        <form>
                            <PageHeaderText small labelFor='feedback-concept'>Concept</PageHeaderText>
                            <TextInput name='feedback-concept' value={ editingFeedback.concept } onChange={ (value) => {setEditingFeedback(feedbackData => ({...feedbackData, concept: value})); setEditingFeedbackErrors(errors => ({...errors, concept: ''})); setModified(true);}} error={editingFeedbackErrors.concept} />
                            <PageHeaderText small labelFor='feedback-skills-0'>Skills</PageHeaderText>
                            {
                                editingFeedback.skills.map((item, index) =>
                                    <TextInput key={index} leftIcon={editingFeedbackErrors.skills[index] ? 'error-bullet' : 'bullet'} name={`feedback-skills-${index}`} value={item} onChange={ (value) => {skillChangeHandler(index, value);} } placeholder={index === editingFeedback.skills.length - 1 ? 'Add more skills...' : '' } lightplaceholder nospaceafter={index === editingFeedback.skills.length - 1 && editingFeedbackErrors.all_skills !== ''} last={index === editingFeedback.skills.length - 1} />
                                )
                            }
                            <StandaloneError spaceafter>{editingFeedbackErrors.all_skills}</StandaloneError>
                            <PageHeaderText small labelFor='feedback-misconceptions-0'>Misconceptions</PageHeaderText>
                            {
                                editingFeedback.misconceptions.map((item, index) =>
                                    <TextInput key={index} leftIcon={editingFeedbackErrors.misconceptions[index] ? 'error-bullet' : 'bullet'} name={`feedback-misconceptions-${index}`} value={item} onChange={ (value) => {misconceptionChangeHandler(index, value);} } placeholder={index === editingFeedback.misconceptions.length - 1 ? 'Add more misconceptions...' : '' } lightplaceholder nospaceafter={(index === editingFeedback.misconceptions.length - 1) && editingFeedbackErrors.all_misconceptions !== '' } last={index === editingFeedback.misconceptions.length - 1} />
                                )
                            }
                            <StandaloneError spaceafter>{editingFeedbackErrors.all_misconceptions}</StandaloneError>
                            <Button onClick={ submitFeedbackHandler } disabled={ isSubmitting || !modified }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        </form>
                    </PulloutDrawer>
                </>
            }
            { (!authUserData || !('admin' in authUserData?.roles)) && <EmptyBlock>You are not authorized to view this resource.</EmptyBlock> }
        </SingleColumn>
    );
};

export default FeedbackPage;