import classes from './EmptyBlock.module.css';

const EmptyBlock = (props) => {
    return (
        <div className={`${classes.blockWrapper} ${props.sideMargins ? classes.sideMargins : ''}` }>
            { props.children }
        </div>
    );
};

export default EmptyBlock;