import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextAreaInput from '../components/UI/TextAreaInput';
import Button from '../components/UI/Button';
import TextBlock from '../components/UI/TextBlock';
import ImportValueGroup from '../components/UI/ImportValueGroup';
import HorizontalLine from '../components/UI/HorizontalLine';
import StandaloneError from '../components/UI/StandaloneError';
import TextInput from '../components/UI/TextInput';
import DropDownInput from '../components/UI/DropdownInput';
import { v4 } from 'uuid';
import { ref as storageRef, uploadBytes } from 'firebase/storage';
import { database } from '../util/firebase';
import { ref, update, push, child } from 'firebase/database';
import { storage } from '../util/firebase';

const QuickImport = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    var destinationGroups = []
    if (authUserData?.groups) {
        Object.keys(authUserData.groups).filter(key => !authUserData.groups[key].archived).forEach((key) => {
            destinationGroups.push({ value: key, text: authUserData.groups[key].name, index: authUserData.groups[key].index });
            destinationGroups.sort((a, b) => a.index - b.index);
        });
    };

    const [inputData, setInputData] = useState('');
    const [parsedData, setParsedData] = useState([]);
    const [dataSets, setDataSets] = useState([]);

    const parseData = () => {
        const rows = inputData.trim().split('\n');
        const headerRowTerms = ['Name', 'ID', 'Email', 'Gender', 'Date of Birth', 'Birthdate', 'Birthday', 'First Name', 'Last Name'];
        
        const extractedData = rows.reduce((acc, row) => {
            // Split the row by both tabs and commas
            let values = row.split(/[\t,]+/).map(value => value.trim()); // Trim spaces around each value
    
            // Check if the row is a header row
            const isHeaderRow = values.some(value => 
                headerRowTerms.some(term => term.toLowerCase() === value.toLowerCase())
            );
    
            if (!isHeaderRow) {
                // Process the row if it's not a header row
                acc.push(values);
            }
            return acc;
        }, []);
        setParsedData(extractedData);
        
        // Generate dataSets as an array of arrays
        const dataSets = [];
        if (extractedData.length > 0) {
            // Determine the number of columns based on the first row
            const numColumns = Object.keys(extractedData[0]).length;

            // Initialize dataSets arrays
            for (let i = 0; i < numColumns; i++) {
                dataSets.push(extractedData.map(item => Object.values(item)[i]));
            }
        }

        // Call setDataSets
        setDataSets(dataSets);
        setSelections({ first_names: '', last_names: '', emails: '', email_parts: [], extra_phrase: '', email_structure: '', email_structure_arr: '', destination_group: '' });
    };

    const [selections, setSelections] = useState({ first_names: '', last_names: '', emails: '', email_parts: [], extra_phrase: '', email_structure: '', email_structure_arr: '', destination_group: '' });
    const [selectionsError, setSelectionsError] = useState({ first_names: '', last_names: '', emails: '', destination_group: '' });

    const resetForm = () => {
        setSelections({ first_names: '', last_names: '', emails: '', email_parts: [], extra_phrase: '', email_structure: '', email_structure_arr: '', destination_group: '' });
        setSelectionsError({ first_names: '', last_names: '', emails: '', destination_group: '' });
    }

    const processDataMatch = (index, value) => {
        if(value === 'first_names') setSelectionsError(state => ({...state, first_names: ''}));
        if(value === 'last_names') setSelectionsError(state => ({...state, last_names: ''}));
        const newSelections = {...selections};
        newSelections[value] = index;
        if(index === 'none' && value === 'first_names') setSelectionsError(state => ({...state, first_names: 'Please use another data source that contains first names.'}));
        if(index === 'none' && value === 'last_names') setSelectionsError(state => ({...state, last_names: 'Please use another data source that contains last names.'}));
        setSelections(newSelections)
    }

    const selectEmailParts = (index, value) => {       
        setSelections(prevSelections => {
            // Clone the previous selections
            const newSelections = { ...prevSelections };
            const { email_parts } = newSelections;
    
            // Check if the index is already in the array
            const indexPosition = email_parts.indexOf(index);
    
            if (indexPosition !== -1) {
                // If the index is found, remove it
                newSelections.email_parts = email_parts.filter(i => i !== index);
            } else {
                // If the index is not found, add it
                newSelections.email_parts = [...email_parts, index];
            }

            return newSelections;
        });
        setSelections(state => ({...state, email_structure: ''}))
    };

    const getPerms = () => {
        let arr = [];
        for(let i = 0; i < selections.email_parts.length; i++) {
            arr.push(dataSets[selections.email_parts[i]][0])
        }
        if(selections.extra_phrase !== '') arr.push(selections.extra_phrase);
        if(arr.length < 2) return [];

        const results = [];

        function permute(subArr, remainingArr) {
            if (remainingArr.length === 0) {
                results.push(subArr.join(''));
                return;
            }

            for (let i = 0; i < remainingArr.length; i++) {
                const nextSubArr = subArr.concat(remainingArr[i]);
                const nextRemainingArr = remainingArr.slice(0, i).concat(remainingArr.slice(i + 1));
                permute(nextSubArr, nextRemainingArr);
            }
        }

        // Separate strings with and without '@'
        const withAtSign = arr.filter(str => typeof str === 'string' && str.includes('@'));
        const withoutAtSign = arr.filter(str => typeof str === 'string' && !str.includes('@'));

        // Generate permutations of the strings without '@'
        if (withAtSign.length > 0) {
            const atString = withAtSign[0]; // Assume only one string can contain an '@'
            permute([], withoutAtSign);
            // Append the '@' string to each result
            results.forEach((_, index) => {
                results[index] += atString;
            });
        } else {
            // Generate permutations of all strings if no '@' string is present
            permute([], withoutAtSign);
        }

        return results;
    }

    const perms = getPerms()

    const finalizeEmailStructure = (index, value) => {
        let arr = [];
        let options = [];
        for(let i = 0; i < selections.email_parts.length; i++) {
            options.push({ search_val: dataSets[selections.email_parts[i]][0], option: selections.email_parts[i]})
        }
        if(selections.extra_phrase !== '') options.push({ search_val: selections.extra_phrase, option: 'extra_phrase'})
        
        const withPositions = options.map(option => {
            const position = selections.email_structure.indexOf(option.search_val);
            return {
                ...option,
                position: position === -1 ? Infinity : position  // Use Infinity if not found
            };
        });

        withPositions.sort((a, b) => a.position - b.position);
        arr.push(...withPositions.map(item => item.option));

        setSelections(state => ({...state, email_structure: perms[index], email_structure_arr: arr}));
    }

    const destinationGroupChangeHandler = (value) => {
        setSelections(state => ({...state, destination_group: value}));
    };

    const [isUploading, setIsUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const startImport = async () => {
        if(selections.destination_group === '' && destinationGroups.length > 0) {
            setSelections(state => ({...state, destination_group: destinationGroups[0]}));
        } else if (destinationGroups.length === 0) {
            setSelectionsError(state => ({...state, destination_group: 'You must have at least one group to use the Quick Import Tool.'}))
            return;
        }
        
        setIsUploading(true);

        let csvStrings = ['first_name,last_name,email']
        for(let i = 0; i < parsedData.length; i++) {
            let newString = '';
            newString += `${parsedData[i][selections.first_names]},`;
            newString += `${parsedData[i][selections.last_names]},`;
            if(selections.emails !== 'none') {
                newString += `${parsedData[i][selections.emails]},`;
            } else {
                for(let j = 0; j < selections.email_structure_arr.length; j++) {
                    newString += `${selections.email_structure_arr[j] === 'extra_phrase' ? selections.extra_phrase : parsedData[i][selections.email_structure_arr[j]]}`
                }
            };
            csvStrings.push(newString);
        };

        // Convert array of objects to CSV string
        const csvString = csvStrings.join('\n');

        // Create a Blob object from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Generate a unique file name for the CSV
        const csvName = `csvs/${new Date().toISOString().replace(/[-:.]/g, '') + v4()}.csv`;
        const csvRef = storageRef(storage, csvName);

        try {
            // Upload the Blob to Firebase Storage
            await uploadBytes(csvRef, blob);
            const gsPath = csvName;
    
            // Get the current user's token and update database
            const newCsvKey = push(child(ref(database), `users/${authUserData.token}/csv_uploads`)).key;
    
            const updates = {};
            updates[`users/${authUserData.token}/csv_uploads/${newCsvKey}`] = { url: gsPath, group_id: selections.destination_group };
            updates[`user_accounts/${authUserData.token}/has_uploaded_csv`] = true;
            await update(ref(database), updates);
    
            setUploadMessage('Success! Your students should appear in their group in a few moments.')
            setIsUploading(false)
        } catch (error) {
            console.error("Error uploading CSV:", error);
            setUploadMessage('Error uploading students. If the issue persists, please reach out to support@brainraider.com.')
            setIsUploading(false)
        }
    };

    const startOver = () => {
        window.location.reload();
    }

    const makeSampleEmail = () => {
        if(selections.emails !== 'none') {
            return parsedData[0][selections.emails]
        } else {
            let email = ''
            for(let j = 0; j < selections.email_structure_arr.length; j++) {
                email += `${selections.email_structure_arr[j] === 'extra_phrase' ? selections.extra_phrase : parsedData[0][selections.email_structure_arr[j]]}`
            }
            return email;
        };
    }

    return (
        <SingleColumn>
            <PageHeaderText>Quick Import Tool</PageHeaderText>
            <TextBlock gray>The Quick Import Tool can help you easily load your students into your Brain Raider groups. We are making improvements to this tool every week - data comes in many formats and our goal is to make the import process the easiest you've ever experienced. If the tool doesn't work for you, reach out to <a href="mailto:support@brainraider.com">support@brainraider.com</a> so we can help bring your students into the fold!</TextBlock>
            <HorizontalLine />
            <br />
            <TextBlock><strong>Step 1:</strong>&nbsp;Copy the data table from your roster, gradebook, or other source and paste it into the box below.</TextBlock>
            <TextAreaInput placeholder='Paste your data here' onChange={(val) => {setInputData(val); resetForm(); setParsedData([]); setDataSets([]);}} value={inputData} nospaceafter />
            { !parsedData.length > 0 &&
                <Button onClick={parseData} confirm >Analyze My Data'</Button>
            }
            <br />
            <br />
            {
                parsedData?.length > 0 && 
                <>
                    <TextBlock><strong>Step 2:</strong>&nbsp;Answer the following questions to match up your data.</TextBlock>
                    <div style={{marginLeft: '20px'}}>
                        <TextBlock gray>1. Which set of data shows student first names?</TextBlock>
                        <div style={{display: 'flex', columnGap: '14px', rowGap: '14px', flexWrap: 'wrap'}}>
                            {
                                dataSets.map((dataSet, index) => <ImportValueGroup values={dataSet} key={index} onSelect={processDataMatch} index={index} disabled={selections.first_names !== '' && selections.first_names !== index} isSelected={selections.first_names === index} selection='first_names' />)
                            }
                            <ImportValueGroup none onSelect={processDataMatch} index='none' disabled={selections.first_names !== '' && selections.first_names !== 'none'} isSelected={selections.first_names === 'none'} selection='first_names' />
                        </div>
                        <StandaloneError>{selectionsError?.first_names}</StandaloneError>
                        <br />
                        <TextBlock gray>2. Which set of data shows student last names?</TextBlock>
                        <div style={{display: 'flex', columnGap: '14px', rowGap: '14px', flexWrap: 'wrap'}}>
                            {
                                dataSets.map((dataSet, index) => <ImportValueGroup values={dataSet} key={index} onSelect={processDataMatch} index={index} disabled={selections.last_names !== '' && selections.last_names !== index} isSelected={selections.last_names === index} selection='last_names' />)
                            }
                            <ImportValueGroup none onSelect={processDataMatch}index='none' disabled={selections.last_names !== '' && selections.last_names !== 'none'} isSelected={selections.last_names === 'none'} selection='last_names' />
                        </div>
                        <StandaloneError>{selectionsError?.last_names}</StandaloneError>
                        <br />
                        <TextBlock gray>3. Which set of data shows student emails?</TextBlock>
                        <div style={{display: 'flex', columnGap: '14px', rowGap: '14px', flexWrap: 'wrap'}}>
                            {
                                dataSets.map((dataSet, index) => <ImportValueGroup values={dataSet} key={index} onSelect={processDataMatch} index={index} disabled={selections.emails !== '' && selections.emails !== index} isSelected={selections.emails === index} selection='emails' />)
                            }
                            <ImportValueGroup none onSelect={processDataMatch} index='none' disabled={selections.emails !== '' && selections.emails !== 'none'} isSelected={selections.emails === 'none'} selection='emails' />
                        </div>
                        {
                            selections.emails === 'none' &&
                            <>
                                <br />
                                <TextBlock gray>3a. Let's build emails with other data! Please select any of the following values that appear in student emails.</TextBlock>
                                <div style={{display: 'flex', columnGap: '14px', rowGap: '14px', flexWrap: 'wrap'}}>
                                    {
                                        dataSets.map((dataSet, index) => <ImportValueGroup values={dataSet} key={index} onSelect={selectEmailParts} index={index} isSelected={selections.email_parts.includes(index)} selection='email_part' />)
                                    }
                                </div>
                                <br />
                                <TextBlock gray labelFor='extra-phrase'>3b. Is there anything else we need to build email addresses? For example, the phrase "@schooldistrict.org".</TextBlock>
                                <TextInput name='extra-phrase' value={selections.extra_phrase} onChange={(value) => setSelections(state => ({...state, extra_phrase: value, email_structure: ''}))} />
                            </>
                        }
                        {
                            selections.emails === 'none' && selections.email_parts.length > 0 &&
                            <>
                                <TextBlock gray>3c. Which of these formats looks right?</TextBlock>
                                <div style={{display: 'flex', columnGap: '14px', rowGap: '14px', flexWrap: 'wrap'}}>
                                    {
                                        perms.map((perm, index) => <ImportValueGroup big values={[perm]} key={index} onSelect={finalizeEmailStructure} index={index} isSelected={perm === selections.email_structure} selection='email_structure' />)
                                    }
                                </div>
                            </>
                        }
                        {
                            ((selections.emails !== '' && selections.emails !== 'none') || (selections.emails === 'none' && selections.email_structure)) &&
                            <>
                                <br />
                                <TextBlock gray>4. Which group should these students join?</TextBlock>
                                <DropDownInput name='destination-group' value={ selections.destination_group } onChange={ destinationGroupChangeHandler } options={ destinationGroups } />
                                <br />
                                { uploadMessage === '' &&
                                    <>
                                        <TextBlock gray>5. One more check - make sure all values for this student look correct!</TextBlock>
                                        <TextBlock><span style={{ color: '#5C60F5'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>First Name:</strong></span>&nbsp;{parsedData[0][selections.first_names]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#5C60F5'}}><strong>Last Name:</strong></span>&nbsp;{parsedData[0][selections.last_names]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#5C60F5'}}><strong>Email:</strong></span>&nbsp;{makeSampleEmail()}</TextBlock>
                                        <br />
                                        <HorizontalLine />
                                        <br />
                                        <TextBlock gray><strong>Note:</strong> The Quick Import Tool generates a random password for each student. Students will need to click 'Forgot Password' to log in for the first time and set their password.</TextBlock>
                                        <Button onClick={startImport} confirm>{isUploading ? 'Importing...' : 'Import Students' }</Button>
                                    </>
                                }
                                {
                                    uploadMessage !== '' &&
                                    <>
                                        <TextBlock>{uploadMessage}</TextBlock>
                                        <Button onClick={startOver} altblue>Reset Quick Import Tool</Button>
                                    </>
                                }
                            </>
                        }
                    </div>
                </>
            }
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </SingleColumn>
    );
};

export default QuickImport;
