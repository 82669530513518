import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import Result from '../components/Cards/Result';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { database } from '../util/firebase';
import { update, ref, get } from 'firebase/database';
import EmptyBlock from '../components/UI/EmptyBlock';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';

// TODO: Consider adding teacher ID to RESULTS_DATA for access control purposes
const ResultsPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);
    const navigate = useNavigate();

    var groupsWithResults = []
    if (authUserData?.monitoring_groups) {
        Object.keys(authUserData.monitoring_groups).forEach((key) => {
            if(authUserData.monitoring_groups[key].submission_count > 0) {
                groupsWithResults.push({_id: key, ...authUserData.monitoring_groups[key]})
            };
        });
    };

    for(let i = 0; i < groupsWithResults.length; i++) {
        if(groupsWithResults[i].feedback_generated_count !== groupsWithResults[i].submission_count) {
            const updates = {}
            updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_needs_regeneration`] = true;
            updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_generated_count`] = groupsWithResults[i].submission_count;
            if(!groupsWithResults[i].feedback_text || groupsWithResults[i].feedback_generated_count === 0) {
                updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_text`] = "Generating...";
            } else {
                updates[`users/${authUserData.token}/monitoring_groups/${groupsWithResults[i]._id}/feedback_text`] = "Regenerating...";
            }
            update(ref(database), updates);
        }
    };

    const [subStatus, setSubStatus] = useState('loading')
    const [trialStatus, setTrialStatus] = useState('loading');
    const [remainingDays, setRemainingDays] = useState(1);
    // handle subscriptions
    useEffect(() => {
        if(!authUserData) return;

        const fetchSubData = async () => {
            // create a query object to the current users active subscriptions
            const q = query(
                // currentUser is provided by firebase, via getAuth().currentUser
                collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                where('status', 'in', ['trialing', 'active'])
            );
            
            // fetch the active subscriptions
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                const trialStatusRef = ref(database, `users/${authUserData.token}/trial_expiry`);
                const trailStatusInfo = await get(trialStatusRef)

                if (trailStatusInfo.exists()) {
                    const givenDateStr = trailStatusInfo.val();
                    const givenDate = new Date(givenDateStr);
                    const currentDate = new Date();

                    const hasPassed = currentDate > givenDate;
                    if(!hasPassed) {
                        setTrialStatus('active')
                    } else {
                        currentDate.setDate(currentDate.getDate() - 15);
                        const hasPassed = currentDate > givenDate;

                        const timeDifference = givenDate - currentDate;
                        const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                        setTrialStatus(hasPassed ? null : 'expiring_soon')
                        setRemainingDays(remainingDays)
                    }
                }
            }

            // assuming user only has one active subscription max
            if(querySnapshot?.docs[0]?.data()) {
                setSubStatus('active');
            } else {
                setSubStatus(null);
            }
        }

        fetchSubData()
    }, [authUserData])

    const navigateToPricing = (event) => {
        event.preventDefault();
        navigate('/#pricing');
    };

    return (
        <SingleColumn>
            { authUserData && !('student' in authUserData?.roles) && trialStatus === 'expiring_soon' && subStatus !== 'loading' && 
                <EmptyBlock sideMargins>Warning! Your free trial has ended and you do not have an active subscription. You will lose access to this resource in {remainingDays} days. <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Upgrade</span></EmptyBlock>
            }
            { authUserData && !('student' in authUserData?.roles) && (subStatus === 'active' || trialStatus === 'active' || trialStatus === 'expiring_soon' || authUserData.classlink_paid) && 
                <div>
                    <PageHeaderText>Results</PageHeaderText>
                    { (groupsWithResults.length === 0 || groupsWithResults.filter(result => !result.archived).length === 0) && <EmptyBlock>No results yet.</EmptyBlock> }
                    { groupsWithResults.length > 0 && groupsWithResults.filter(result => !result.archived).sort((a, b) => b.index - a.index).map((result, index) => <Result result={result} key={result._id} last={index === groupsWithResults.length - 1} />) }
                </div>
            }
            { authUserData && !('student' in authUserData?.roles) && (subStatus !== 'active' && trialStatus !== 'active' && trialStatus !== 'expiring_soon' && subStatus !== 'loading' && !authUserData.classlink_paid ) && 
                <EmptyBlock sideMargins>Your free trial has expired and you do not have an active subscription. Please <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>upgrade</span> to access this resource.</EmptyBlock>
            }
            { (!authUserData || ('student' in authUserData?.roles)) &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </SingleColumn>
    );
}

export default ResultsPage;