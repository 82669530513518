import classes from './AssessmentHeader.module.css';
import HorizontalLine from '../UI/HorizontalLine';
import assessmentArtLarge from '../../images/assessment-art-large.svg';

const AssessmentHeader = (props) => {
    return(
        <>
            <div className={classes.artWrapper}>
                <img src={ assessmentArtLarge } alt='' className={ classes.assessmentArt } />
            </div>
            { props.assessment &&
                <div className={ classes.popupContent }>
                    <div className={ classes.assessmentName }>{ props.assessment.name }</div>
                    <div className={ classes.assessmentDescription }>{ props.assessment.description }</div>
                    <div className={ classes.capstonesWrapper }>{ props.assessment.capstone_num } Capstone{ props.assessment.capstone_num !== 1 && 's' }</div>
                    <div className={ classes.standardsWrapper }>{ props.standards }</div>
                    <HorizontalLine />
                </div>
            }
        </>
    )
};

export default AssessmentHeader;