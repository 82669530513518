import classes from './CapstoneDetailed.module.css';
import QuestionDetailed from './QuestionDetailed';
import { useState } from 'react';

const CapstoneDetailed = (props) => {
    /* props.capstoneData && console.log(props.capstoneData); */

    const [expandedId, setExpandedId] = useState('');

    return(
        <div className={`${classes.stimuliWrapper} ${props.last ? classes.last : ''}`}>
            { props.capstoneData?.image &&
                <div className={ classes.imageWrapper }>
                    <img src={ props.capstoneData.image } alt='' className={ classes.stimuliImage }/>
                </div>
            }
            <div className={ classes.promptWrapper }>{/* props.numbered && props.index + 1 + '. ' */}{props.capstoneData?.text}</div>
            {
                props.capstoneData?.questions &&
                Object.keys(props.capstoneData.questions).filter(key => !props.capstoneData.questions[key]?.archived).sort((a, b) => props.capstoneData.questions[a].is_capstone - props.capstoneData.questions[b].is_capstone).map((key, index) => 
                    <QuestionDetailed key={key} questionData={{ ...props.capstoneData.questions[key], key: key, stimuliKey: props.capstoneData.stimuliKey, image: props.capstoneData.image, stimulus_text: props.capstoneData.text }} expanded={expandedId === key} claimExpanded={setExpandedId} showCorrect={props.showCorrect} showStats={props.showStats} last={index === Object.keys(props.capstoneData.questions).length - 1} />
                )
            }
        </div>
    );
};

export default CapstoneDetailed;