import SingleColumn from '../components/UI/SingleColumn';
import Assessment from '../components/Cards/Assessment';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import TextAreaInput from '../components/UI/TextAreaInput';
import PreviewPopup from '../components/UI/PreviewPopup';
import EmptyBlock from '../components/UI/EmptyBlock';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import Button from '../components/UI/Button';
import Slider from '../components/UI/Slider';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { database } from '../util/firebase';
import { push, child, get, set, update, ref, onValue } from 'firebase/database';
import AssessmentDetail from '../components/Cards/AssessmentDetail';
import CheckboxInput from '../components/UI/CheckboxInput';
import StandaloneError from '../components/UI/StandaloneError';
import TopRightButton from '../components/UI/TopRightButton';
import SlidingScale from '../components/UI/SlidingScale';
import TextBlock from '../components/UI/TextBlock';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import FlexToTiles from '../components/UI/FlexToTiles';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { firestoreDb } from '../util/firebase';
import FaqExpander from '../components/UI/FaqExpander';
import { getAnalytics, logEvent } from "firebase/analytics";

const AssessmentsPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

    const [subStatus, setSubStatus] = useState('loading')
    const [trialStatus, setTrialStatus] = useState(null);
    // handle subscriptions
    useEffect(() => {
        if(!authUserData) return;

        const fetchSubData = async () => {
            // create a query object to the current users active subscriptions
            const q = query(
                // currentUser is provided by firebase, via getAuth().currentUser
                collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                where('status', 'in', ['trialing', 'active'])
            );
            
            // fetch the active subscriptions
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                const trialStatusRef = ref(database, `users/${authUserData.token}/trial_expiry`);
                const trailStatusInfo = await get(trialStatusRef)

                if (trailStatusInfo.exists()) {
                    const givenDateStr = trailStatusInfo.val();
                    const givenDate = new Date(givenDateStr);
                    const currentDate = new Date();

                    const hasPassed = currentDate > givenDate;

                    setTrialStatus(hasPassed ? null : 'active')
                }
            }

            // assuming user only has one active subscription max
            if(querySnapshot?.docs[0]?.data()) {
                setSubStatus('active');
            } else {
                setSubStatus(null);
            }
        }

        fetchSubData()
    }, [authUserData])

    const [hasLoaded, setHasLoaded] = useState(false);
    const [allAssessmentsObject, setAllAssessmentsObject] = useState(null);
    useEffect(() => {
        onValue(ref(database, `assessments`), (snapshot) => {setAllAssessmentsObject(snapshot.val());});
        setHasLoaded(true);
    }, []);

    var assessments = []
    if (allAssessmentsObject) {Object.keys(allAssessmentsObject).forEach((key) => {assessments.push({_id: key, ...allAssessmentsObject[key]})});};

    const [showPreview, setShowPreview] = useState(false);
    const [previewingAssessment, setPreviewingAssessment] = useState('')
    const showPreviewClickHandler = (assessment) => {
        const updates = {};
        updates[`user_accounts/${authUserData.token}/has_viewed_assessment`] = true;

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_viewed_assessment');
        } catch (error) {
            console.error('Error logging event:', error);
        }

        update(ref(database), updates);

        setPreviewingAssessment(assessment);
        setShowPreview(true);
    };

    const [filterText, setFilterText] = useState('');
    const filterAssessments = () => {
        var filtered_assessments = [...assessments];
  
        if(authUserData && !('student' in authUserData?.roles) /* && !('unverified_teacher' in authUserData?.roles) */ && !('rejected_verification' in authUserData?.roles)) {
            const state = Object.keys(authUserData.standards_environment)[0];
            const standard_group = Object.keys(authUserData.standards_environment[state])[0];

            // Only filter the assessments if the user has typed in the search bar
            filtered_assessments = assessments.filter(assessment => 
                !assessment.archived && // Exclude assessments with 'archived' as true
                (filterText ?
                    (assessment.name.toLowerCase().includes(filterText.toLowerCase()) ||
                    (assessment.description && assessment.description.toLowerCase().includes(filterText.toLowerCase())) ||
                    (assessment.keywords && assessment.keywords.toLowerCase().includes(filterText.toLowerCase())) ||
                    (assessment.standards && Object.keys(assessment.standards).includes(state) && Object.keys(assessment.standards[state]).includes(standard_group) && Object.keys(assessment.standards[state][standard_group]).map(key => key + ' ' + assessment.standards[state][standard_group][key].text).join(' ').toLowerCase().includes(filterText.toLowerCase()))) :
                    true // Include all assessments if there's no filter text
                )
                );

            // Filter out unpublished assessments unless the user is an admin
            if(authUserData && 'admin' in authUserData?.roles) {
                filtered_assessments.sort((a, b) => !a.published - !b.published);
                return filtered_assessments;
            } else {
                const only_published_assessments = filtered_assessments.filter(assessment => assessment.published);
                return only_published_assessments;
            };
        };
    };
  
    const filteredAssessments = filterAssessments();

    const [showDrawer, setShowDrawer] = useState(false);
    const [assigningAssessment, setAssigningAssessment] = useState({ _id: '', name: '', description: '', capstone_num: 0, required_capstones: 0, noncapstone_weight: 0, use_accommodations: false });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [groupSelectStatus, setGroupSelectStatus] = useState([]);

    useEffect(() => {
        let initialGroupStatus = []
        if (authUserData?.groups) {
            Object.keys(authUserData.groups).filter(key => !authUserData.groups[key].archived).forEach((key) => {
                initialGroupStatus.push({ value: key, text: authUserData.groups[key].name, index: authUserData.groups[key].index, checked: false, student_count: authUserData.groups[key].student_count });
                initialGroupStatus.sort((a, b) => a.index - b.index);
            });
        };
        setGroupSelectStatus(initialGroupStatus);
    }, [authUserData?.groups]);

    const resetGroupStatus = () => {
        let initialGroupStatus = []
        if (authUserData?.groups) {
            Object.keys(authUserData.groups).filter(key => !authUserData.groups[key].archived).forEach((key) => {
                initialGroupStatus.push({ value: key, text: authUserData.groups[key].name, index: authUserData.groups[key].index, checked: false, student_count: authUserData.groups[key].student_count});
                initialGroupStatus.sort((a, b) => a.index - b.index);
            });
        };
        setGroupSelectStatus(initialGroupStatus);
    };

    const assignClickHandler = (id, name, description, capstone_num) => {
        setAssigningAssessment({ _id: id, name: name, description: description, capstone_num: capstone_num, required_capstones: Math.floor(capstone_num / 2), noncapstone_weight: 1, use_accommodations: false });
        setShowDrawer(true);
        setShowPreview(false);
    };

    const checkboxClickHandler = (value) => {
        let groupStatus = [...groupSelectStatus];
        const index = groupStatus.findIndex(group => group.value === value);
        groupStatus[index].checked = !groupStatus[index].checked;
        setGroupSelectStatus(groupStatus);
        setSubmissionError('');
    };

    const [submissionError, setSubmissionError] = useState('');

    const submitFormHandler = async (event) => {
        event.preventDefault();
    
        const groupSelectStatusFiltered = groupSelectStatus.filter(group => group.checked === true);
        if (groupSelectStatusFiltered.length < 1) {
            setSubmissionError('You must select at least one group.')
            return;
        };

        setIsSubmitting(true);

        const updates = {};
        
        let monitor_index = 0;
        if(authUserData?.monitoring_groups && Object.keys(authUserData.monitoring_groups).length > 0) {
            const groupIndexes = Object.values(authUserData.monitoring_groups).map(group => group.index);
            
            if (groupIndexes.length > 0) {
                monitor_index = Math.max(...groupIndexes) + 1;
            }
        }
        
        for (const group of groupSelectStatusFiltered) {
            const newMonitoringGroupKey = push(child(ref(database), 'monitoring_groups')).key;
            updates[`monitoring_groups/${newMonitoringGroupKey}/assessment`] = { name: assigningAssessment.name, assessment_id: assigningAssessment._id };
            updates[`monitoring_groups/${newMonitoringGroupKey}/group`] = { name: group.text, group_id: group.value };
            updates[`monitoring_groups/${newMonitoringGroupKey}/assigned_time`] = Date.now();
            updates[`monitoring_groups/${newMonitoringGroupKey}/active`] = true;
            updates[`monitoring_groups/${newMonitoringGroupKey}/teacher_id`] = authUserData.token;
            updates[`monitoring_groups/${newMonitoringGroupKey}/required_capstones`] = assigningAssessment.required_capstones;
            updates[`monitoring_groups/${newMonitoringGroupKey}/noncapstone_weight`] = assigningAssessment.noncapstone_weight;
            updates[`monitoring_groups/${newMonitoringGroupKey}/standards_environment`] = authUserData.standards_environment;
            updates[`monitoring_groups/${newMonitoringGroupKey}/submission_count`] = 0;
        
            const groupDemoRef = ref(database, `groups/${group.value}`);
            const groupDemoInfo = await get(groupDemoRef)
            let is_demonstration = false;

            if (groupDemoInfo.exists()) {
                const groupDemoObj = groupDemoInfo.val()
                if(groupDemoObj.is_demo_group && groupDemoObj.is_demo_group === true) {
                    updates[`monitoring_groups/${newMonitoringGroupKey}/is_demo`] = true;
                    is_demonstration = true;
                }
            }

            const groupRef = ref(database, `groups/${group.value}/student_users`);
            const snapshot = await get(groupRef);
      
            if (snapshot.exists()) {
                const studentKeys = Object.keys(snapshot.val());
            
                for (const key of studentKeys) {
                    const user_info = snapshot.val()[key];
                    const newAttemptKey = push(child(ref(database), 'attempts')).key;
                    updates[`attempts/${newAttemptKey}/user`] = { user_id: key, first_name: user_info.first_name, last_name: user_info.last_name };
                    updates[`attempts/${newAttemptKey}/assessment`] = { name: assigningAssessment.name, assessment_id: assigningAssessment._id };
                    updates[`attempts/${newAttemptKey}/monitoring_group_id`] = newMonitoringGroupKey;
                    updates[`attempts/${newAttemptKey}/status`] = 'queued';
                    updates[`attempts/${newAttemptKey}/teacher_id`] = authUserData.token;
                    if(is_demonstration) {
                        updates[`attempts/${newAttemptKey}/is_demo_attempt`] = true;
                        updates[`user_accounts/${authUserData.token}/has_assigned_demo_assessment`] = true;
                        try {
                            const analytics = getAnalytics();
                            logEvent(analytics, 'has_assigned_demo_assessment');
                        } catch (error) {
                            console.error('Error logging event:', error);
                        }
                    } else {
                        updates[`user_accounts/${authUserData.token}/has_assigned_real_assessment`] = true;
                        try {
                            const analytics = getAnalytics();
                            logEvent(analytics, 'has_assigned_real_assessment');
                        } catch (error) {
                            console.error('Error logging event:', error);
                        }
                    }
            
                    updates[`users/${key}/unfinished_attempts/${newAttemptKey}`] = { name: assigningAssessment.name, description: assigningAssessment.description, assessment_id: assigningAssessment._id, status: 'queued' };
                    updates[`monitoring_groups/${newMonitoringGroupKey}/attempts/${newAttemptKey}`] = { user_id: key, first_name: user_info.first_name, last_name: user_info.last_name, status: 'queued' };
                }
            }
      
            updates[`users/${authUserData.token}/monitoring_groups/${newMonitoringGroupKey}`] = { assessment_id: assigningAssessment._id, assessment_name: assigningAssessment.name, group_id: group.value, group_name: group.text, active: true, submission_count: 0, feedback_generated_count: 0, index: monitor_index };
            monitor_index ++;
        }
      
        await update(ref(database), updates);
      
        setIsSubmitting(false);
        setShowDrawer(false);
        setAssigningAssessment({ _id: '', name: '', description: '', capstone_num: 0, required_capstones: 0, noncapstone_weight: 0, use_accommodations: false });
        resetGroupStatus();
    };
    
    const [showDetails, setShowDetails] = useState(false);
    const [editingAssessment, setEditingAssessment] = useState({ name: '', description: '', keywords: '' });
    const [editingAssessmentErrors, setEditingAssessmentErrors] = useState({ name: '', description: '' });

    const openInEditorClickHandler = (assessment_id) => {
        navigate(`/assessment-editor/${assessment_id}`)
    };

    const submitAssessmentHandler = async (event) => {
        event.preventDefault();

        let errorsFound = 0;

        // Validate the entered name and description
        if( editingAssessment.name === '' ) {
            setEditingAssessmentErrors(errorData => ({...errorData, name: 'Name cannot be blank.'}));
            errorsFound++;
        } else if(editingAssessment.name.includes('.') || editingAssessment.name.includes(',')) {
            setEditingAssessmentErrors(errorData => ({...errorData, name: 'Name cannot include punctuation.'}));
            errorsFound++;
        } else {
            setEditingAssessmentErrors(errorData => ({...errorData, name: ''}));
        }

        if(editingAssessment.description === '') {
            setEditingAssessmentErrors(errorData => ({...errorData, description: 'Description cannot be blank.'}));
            errorsFound++;
        } else {
            setEditingAssessmentErrors(errorData => ({...errorData, description: ''}));
        }

        if(errorsFound > 0) {return;}

        setIsSubmitting(true);

        const assessmentData = { name: editingAssessment.name, description: editingAssessment.description, keywords: editingAssessment.keywords, capstone_num: 0, published: false };
        const newAssessmentKey = push(child(ref(database), 'assessments')).key;
        set(ref(database, `assessments/${newAssessmentKey}`), assessmentData);

        // Reset the form
        setIsSubmitting(false);
        setEditingAssessment({ name: '', description: '', keywords: '' });
        setShowDetails(false);
    };

    const handleRequiredCapstoneChange = (val) => {
        let new_val = parseInt(val);
        if(new_val === 0) new_val = 1;
        setAssigningAssessment(data => ({...data, required_capstones: new_val}));
    }

    const onGoDeep = (id) => {
        const updates = {};
        updates[`user_accounts/${authUserData.token}/has_viewed_assessment_detail`] = true;

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'has_viewed_assessment_detail');
        } catch (error) {
            console.error('Error logging event:', error);
        }

        update(ref(database), updates);

        navigate(`/assessments/${id}`);
    }

    const [verificationSent, setVerificationSent] = useState(false);
    const sendVerification = () => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            sendEmailVerification(user)
                .then(() => {
                    console.log('Verification email sent.');
                    setVerificationSent(true);
                })
                .catch((error) => {
                    console.error('Error sending verification email:', error);
                });
        } else {
            console.error('No authenticated user data available.');
        }
    };

    const hasMyStandards = (assessment) => {
        if(authUserData && authUserData.roles?.admin && !assessment.standards) return true;

        let matchingStandards = [];
        if(assessment.standards && authUserData?.standards_environment) {
            Object.keys(authUserData.standards_environment).forEach(state => {
                Object.keys(authUserData.standards_environment[state]).forEach(standard_group => {
                    if(assessment.standards[state]) {
                        if(assessment.standards[state]) {
                            if(assessment.standards[state][standard_group]) {
                                Object.keys(assessment.standards[state][standard_group]).forEach(code => {
                                    matchingStandards.push(code);
                                })
                            };
                        };
                    };
                })
            })
        };

        if(matchingStandards.length > 0) return true
        else return false
    }

    const navigateToPricing = (event) => {
        event.preventDefault();
        navigate('/#pricing');
    };

    return (
        <SingleColumn wide>
            { authUserData && authUserData.isEmailVerified && (subStatus === 'active' || trialStatus === 'active' || authUserData.classlink_paid ) && !('student' in authUserData?.roles) && !('unverified_teacher' in authUserData?.roles) && !('rejected_verification' in authUserData?.roles) &&
                <>
                    <div>
                        <PageHeaderText>Assessments</PageHeaderText>
                        { ('admin' in authUserData?.roles) && <TopRightButton icon='add' onClick={ () => {setEditingAssessment({ name: '', description: '', keywords: '' }); setEditingAssessmentErrors({ name: '', description: '' }); setShowDetails(true);}}/> }
                        <TextInput name='search' leftIcon='search' placeholder='Search...' nospacebefore nospaceafter value={filterText} onChange={ (value) => {setFilterText(value)} } />
                        <FlexToTiles>
                            { filteredAssessments.filter(assessment => hasMyStandards(assessment)).map((assessment, index) => <Assessment assessment={assessment} key={index} standardsToShow={authUserData?.standards_environment} onClick={showPreviewClickHandler} last={index === filteredAssessments.length - 1} />) }
                        </FlexToTiles>
                        { hasLoaded && assessments.length === 0 && <EmptyBlock>No assessments found.</EmptyBlock>}
                    </div>
                    <PreviewPopup show={showPreview} onCancel={() => {setShowPreview(false); setPreviewingAssessment('');}}>
                        <AssessmentDetail assessment={previewingAssessment} onAssign={assignClickHandler} standardsToShow={authUserData?.standards_environment} onOpenInEditor={('admin' in authUserData?.roles) && openInEditorClickHandler} isAdmin={authUserData?.roles?.admin} onGoDeep={('admin' in authUserData?.roles || 'verified_teacher' in authUserData?.roles) && onGoDeep}/>
                    </PreviewPopup>
                    <PulloutDrawer show={showDrawer} header={'Assign Assessment'} onCancel={() => {setShowDrawer(false); resetGroupStatus(); setAssigningAssessment({ _id: '', name: '', description: '', capstone_num: 0, required_capstones: 0, noncapstone_weight: 0, use_accommodations: false }); setSubmissionError('');}}>
                        <form>
                            <PageHeaderText small>Assign {assigningAssessment?.name} to...</PageHeaderText>
                            { groupSelectStatus.map((group, index) => <CheckboxInput value={group.value} checked={group.checked} key={group.value} onChange={checkboxClickHandler} disabled={group.student_count < 1} last={index === groupSelectStatus.length - 1}>{group.text} ({group.student_count} Student{group.student_count !== 1 && 's'})</CheckboxInput>) }
                            { groupSelectStatus.filter(group => group.student_count > 0).length === 0 && <EmptyBlock>You don't have any groups yet!</EmptyBlock>}
                            <div style={{marginBottom: '38px'}}>
                                <PageHeaderText small>Required Capstones</PageHeaderText>
                                {assigningAssessment?._id && <SlidingScale min={0} max={assigningAssessment.capstone_num} leftLabel="Quick" rightLabel="Thorough" onChange={handleRequiredCapstoneChange} value={assigningAssessment.required_capstones} maxlabel="All"/>}
                                <TextBlock>Assigning more capstones will improve the quality of your feedback, but it will take students longer to complete the entire assessment. </TextBlock>
                                <FaqExpander />
                            </div>
                            <div style={{marginBottom: '76px', display: 'none'}}>
                                <PageHeaderText small floatLeft>Accommodations</PageHeaderText>
                                <Slider index={ assigningAssessment.use_accommodations ? 1 : 0 } fitted firstOption='No' secondOption='Yes' onFirstOption={(event) => {event.preventDefault(); setAssigningAssessment(data => ({...data, use_accommodations: false}))}} onSecondOption={(event) => {event.preventDefault(); setAssigningAssessment(data => ({...data, use_accommodations: true}))}}/>
                            </div>
                            <Button onClick={ submitFormHandler } disabled={ isSubmitting || groupSelectStatus.filter(group => group.student_count > 0).length === 0}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                            <StandaloneError>{ submissionError }</StandaloneError>
                        </form>
                    </PulloutDrawer>
                    <PulloutDrawer show={showDetails} header='Add Assessment' onCancel={() => {setShowDetails(false); setEditingAssessment({ name: '', description: '', keywords: '' }); setEditingAssessmentErrors({ name: '', description: '' });}}>
                        <form>
                            <PageHeaderText small labelFor='assessment-name'>Name</PageHeaderText>
                            <TextInput name='assessment-name' value={ editingAssessment.name } onChange={ (value) => {setEditingAssessment(assessmentData => ({...assessmentData, name: value})); setEditingAssessmentErrors(errorData => ({...errorData, name: ''}));} } error={ editingAssessmentErrors.name }/>
                            <PageHeaderText small labelFor='assessment-description'>Description</PageHeaderText>
                            <TextAreaInput name='assessment-description' value={ editingAssessment.description } onChange={ (value) => {setEditingAssessment(assessmentData => ({...assessmentData, description: value})); setEditingAssessmentErrors(errorData => ({...errorData, description: ''}));} } error={ editingAssessmentErrors.description }/>
                            <PageHeaderText small labelFor='assessment-keywords'>Additional Keyword(s)</PageHeaderText>
                            <TextInput name='assessment-keywords' value={ editingAssessment.keywords } onChange={ (value) => {setEditingAssessment(assessmentData => ({...assessmentData, keywords: value}));} } />
                            <Button onClick={ submitAssessmentHandler } disabled={ isSubmitting }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        </form>
                    </PulloutDrawer>
                </>
            }
            { authUserData && authUserData.isEmailVerified && (subStatus !== 'active' && trialStatus !== 'active' && subStatus !== 'loading' && !authUserData.classlink_paid ) && !('student' in authUserData?.roles) && !('unverified_teacher' in authUserData?.roles) && !('rejected_verification' in authUserData?.roles) &&
                <EmptyBlock sideMargins>Your free trial has expired and you do not have an active subscription. Please <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>upgrade</span> to access this resource.</EmptyBlock>
            }
            {
                (!authUserData || ('student' in authUserData?.roles) || ('rejected_verification' in authUserData?.roles)) &&
                <EmptyBlock sideMargins>You are not authorized to view this resource.</EmptyBlock>
            }
            {
                (authUserData && ('unverified_teacher' in authUserData?.roles) && authUserData.isEmailVerified) &&
                <>
                    <div>
                        <PageHeaderText>Assessments</PageHeaderText>
                        { ('admin' in authUserData?.roles) && <TopRightButton icon='add' onClick={ () => {setEditingAssessment({ name: '', description: '', keywords: '' }); setEditingAssessmentErrors({ name: '', description: '' }); setShowDetails(true);}}/> }
                        <TextInput name='search' leftIcon='search' placeholder='Search...' nospacebefore nospaceafter value={filterText} onChange={ (value) => {setFilterText(value)} } />
                        <FlexToTiles>
                            { filteredAssessments.filter(assessment => hasMyStandards(assessment)).slice(0, 1).map((assessment, index) => <Assessment assessment={assessment} key={index} standardsToShow={authUserData?.standards_environment} onClick={showPreviewClickHandler} last={index === filteredAssessments.length - 1} />) }
                        </FlexToTiles>
                        { hasLoaded && assessments.length === 0 && <EmptyBlock>No assessments found.</EmptyBlock>}
                        <PreviewPopup show={showPreview} onCancel={() => {setShowPreview(false); setPreviewingAssessment('');}}>
                        <AssessmentDetail assessment={previewingAssessment} onAssign={assignClickHandler} standardsToShow={authUserData?.standards_environment} onOpenInEditor={('admin' in authUserData?.roles) && openInEditorClickHandler} isAdmin={authUserData?.roles?.admin} onGoDeep={('admin' in authUserData?.roles || 'verified_teacher' in authUserData?.roles) && onGoDeep}/>
                    </PreviewPopup>
                    <PulloutDrawer show={showDrawer} header={'Assign Assessment'} onCancel={() => {setShowDrawer(false); resetGroupStatus(); setAssigningAssessment({ _id: '', name: '', description: '', capstone_num: 0, required_capstones: 0, noncapstone_weight: 0, use_accommodations: false }); setSubmissionError('');}}>
                        <form>
                            <PageHeaderText small>Assign {assigningAssessment?.name} to...</PageHeaderText>
                            { groupSelectStatus.map((group, index) => <CheckboxInput value={group.value} checked={group.checked} key={group.value} onChange={checkboxClickHandler} disabled={group.student_count < 1} last={index === groupSelectStatus.length - 1}>{group.text} ({group.student_count} Student{group.student_count !== 1 && 's'})</CheckboxInput>) }
                            { groupSelectStatus.filter(group => group.student_count > 0).length === 0 && <EmptyBlock>You don't have any groups yet!</EmptyBlock>}
                            <div style={{marginBottom: '38px'}}>
                                <PageHeaderText small>Required Capstones</PageHeaderText>
                                {assigningAssessment?._id && <SlidingScale min={0} max={assigningAssessment.capstone_num} leftLabel="Quick" rightLabel="Thorough" onChange={handleRequiredCapstoneChange} value={assigningAssessment.required_capstones} maxlabel="All"/>}
                                <TextBlock>Assigning more capstones will improve the quality of your feedback, but it will take students longer to complete the entire assessment. </TextBlock>
                                <FaqExpander />
                            </div>
                            <div style={{marginBottom: '76px', display: 'none'}}>
                                <PageHeaderText small floatLeft>Accommodations</PageHeaderText>
                                <Slider index={ assigningAssessment.use_accommodations ? 1 : 0 } fitted firstOption='No' secondOption='Yes' onFirstOption={(event) => {event.preventDefault(); setAssigningAssessment(data => ({...data, use_accommodations: false}))}} onSecondOption={(event) => {event.preventDefault(); setAssigningAssessment(data => ({...data, use_accommodations: true}))}}/>
                            </div>
                            <Button onClick={ submitFormHandler } disabled={ isSubmitting || groupSelectStatus.filter(group => group.student_count > 0).length === 0}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                            <StandaloneError>{ submissionError }</StandaloneError>
                        </form>
                    </PulloutDrawer>
                    </div>
                    <EmptyBlock sideMargins><strong>We are working on verifying your teacher status.</strong>&nbsp;For now, you can still preview one demo quiz, assign it to a demo class, watch their progress on the <span onClick={() => {navigate('/monitor')}} style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}>Monitor</span> page, and see their results on the <span onClick={() => {navigate('/results')}} style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}>Results</span> page. All assessments will fully unlock after you are verified!</EmptyBlock>
                </>
            }
            {
                (authUserData && !(('student' in authUserData?.roles) || ('rejected_verification' in authUserData?.roles)) && !authUserData.isEmailVerified) && !verificationSent &&
                <EmptyBlock sideMargins>Please <span onClick={sendVerification} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>verify your email</span> to access this page.</EmptyBlock>
            }
            {
                (authUserData && !(('student' in authUserData?.roles) || ('rejected_verification' in authUserData?.roles)) && !authUserData.isEmailVerified) && verificationSent &&
                <EmptyBlock sideMargins><strong>Verification email sent!</strong> Please check your junk mail - the email might land there because the link includes a very long unique verification code.<br /><br />Refresh this page after you have verified your email.</EmptyBlock>
            }
        </SingleColumn>
    );
}

export default AssessmentsPage;