import classes from './AssessmentCompleteBlock.module.css';
import assessmentCompleteArt from '../../images/assessment-complete-art.svg';
import { useNavigate } from 'react-router-dom';
import Button from '../UI/Button';

const AssessmentCompleteBlock = () => {
    const navigate = useNavigate()

    const goToDashboard = () => {
        navigate('/dashboard');
    };

    return (
        <div className={ classes.blockWrapper }>
            <img src={ assessmentCompleteArt } alt='' className={ classes.assessmentCompleteArt } />
            <div className={ classes.textBlock }>Assessment Complete!</div>
            <Button onClick={goToDashboard}>Back to Dashboard</Button>
        </div>
    );
};

export default AssessmentCompleteBlock;