import SingleColumn from '../components/UI/SingleColumn';
import { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { database, firestoreDb } from '../util/firebase';
import { ref, update, increment } from 'firebase/database';
import HeroText from '../components/UI/HeroText';
import FeatureCard from '../components/UI/FeatureCard';
import FlexRow from '../components/UI/FlexRow';
import HomepageBottomBar from '../components/Cards/HomepageBottomBar';
import HomepageCtaBlock from '../components/Cards/HomepageCtaBlock';
import PriceCard from '../components/UI/PriceCard';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextBlock from '../components/UI/TextBlock';
import Video from '../components/UI/Video';
import ResearchCard from '../components/UI/ResearchCard';

const features = [
    {
        picture: 'library',
        title: 'Extensive Library',
        description: 'Hundreds of original questions aligned to the new science TEKS, including all new question types'
    },
    {
        picture: 'assign',
        title: 'Easy to Assign',
        description: 'Choose from a gallery of preassembled question sets or build a custom set by TEK in just a few clicks '
    },
    {
        picture: 'monitor',
        title: 'Easy to Monitor',
        description: "Our real-time dashboard lets you track your students' activity and eliminate cheating"
    },
    {
        picture: 'feedback',
        title: 'Feedback for Students',
        description: "Students get customized question-by-question feedback to help hone their skills while taking their assessment"
    },
    {
        picture: 'report',
        title: 'Feedback for Teachers',
        description: "Students' results are dynamically analyzed and teachers get concrete action steps to move learning forward"
    },
    {
        picture: 'devices',
        title: 'Works on Any Device',
        description: 'No downloads required - everyone can use Brain Raider on any device that can load a webpage'
    }
]

const individualPlanFeatures = [
    {
        offered: true,
        description: 'Access to original question banks with hundreds of TEKS-aligned questions'
    },
    {
        offered: true,
        description: 'Easily assign any combination of ready-made questions'
    },
    {
        offered: true,
        description: 'Real-time monitoring tool to eliminate cheating'
    },
    {
        offered: true,
        description: 'Automatic assessment grading and data analysis'
    },
    {
        offered: true,
        description: 'Detailed student performance reports with actionable feedback'
    },
    {
        offered: true,
        description: 'Easily share aggregated data with other teachers to drive team planning*✝'
    },
    {
        offered: false,
        description: 'Rostering and Single Sign-On via ClassLink'
    },
    {
        offered: false,
        description: 'District performance dashboards to inform curriculum development*'
    },
    {
        offered: false,
        description: 'P.O.s accepted'
    }
]

const orgPlanFeatures = [
    {
        offered: true,
        description: 'Access to original question banks with hundreds of TEKS-aligned questions'
    },
    {
        offered: true,
        description: 'Easily assign any combination of ready-made questions'
    },
    {
        offered: true,
        description: 'Real-time monitoring tool to eliminate cheating'
    },
    {
        offered: true,
        description: 'Automatic assessment grading and data analysis'
    },
    {
        offered: true,
        description: 'Detailed student performance reports with actionable feedback'
    },
    {
        offered: true,
        description: 'Easily share aggregated data with other teachers to drive team planning*✝'
    },
    {
        offered: true,
        description: 'Rostering and Single Sign-On via ClassLink'
    },
    {
        offered: true,
        description: 'District performance dashboards to inform curriculum development*'
    },
    {
        offered: true,
        description: 'P.O.s accepted'
    }
]

const HomePage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('xlarge');}, [setHeaderSize]);

    useEffect(() => {
        try {
            const updates = {};
            updates[`analytics/homepage_visits`] = increment(1);
    
            // Get the referrer domain
            const referrer = document.referrer;
            if (referrer) {
                const url = new URL(referrer);
                const hostnameParts = url.hostname.split('.');
                let domain = hostnameParts[hostnameParts.length - 2]; // Get the domain part
    
                // If the domain is "co" (like co.uk), adjust to get the actual domain
                if (domain === 'co' && hostnameParts.length > 2) {
                    domain = hostnameParts[hostnameParts.length - 3];
                }
    
                // Replace non-allowed characters with underscores
                domain = domain.replace(/[^a-zA-Z0-9_-]/g, '_');
                
                updates[`analytics/homepage_referrers/${domain}`] = increment(1);
            }
    
            update(ref(database), updates);
        } catch (error) {
            console.error(error);
        }
    }, []);
    

    const navigate = useNavigate();
    useEffect(() => {
        if (authUserData?.roles) {
            if ('student' in authUserData.roles) {
                navigate('/dashboard');
            }
        }
        }, [authUserData, navigate]);

        const navigateToSignupHandler = () => {
            navigate('/signup');
        }
        
        const navigateToQuoteGeneratorHandler = () => {
            navigate('/quote-generator');
        }

        const [subStatus, setSubStatus] = useState('loading')
        // handle subscriptions
        useEffect(() => {
            if(!authUserData) return;
    
            const fetchSubData = async () => {
                // create a query object to the current users active subscriptions
                const q = query(
                    // currentUser is provided by firebase, via getAuth().currentUser
                    collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                    where('status', 'in', ['trialing', 'active'])
                );
                
                // fetch the active subscriptions
                const querySnapshot = await getDocs(q);
    
                // assuming user only has one active subscription max
                if(querySnapshot?.docs[0]?.data()) {
                    setSubStatus('active');
                } else {
                    setSubStatus(null);
                }
            }
    
            fetchSubData()
        }, [authUserData])

        const getSubButtonText = () => {
            if(!authUserData) {
                return 'Start 30-Day Free Trial'
            } else if(subStatus === 'active' || authUserData?.classlink_paid) {
                return 'See Subscription Details'
            } else {
                return 'Get Individual Plan'
            }
        }
        
        const subButtonClickHandler = () => {
            if(!authUserData) {
                navigateToSignupHandler();
            } else if(subStatus === 'active' || authUserData?.classlink_paid) {
                navigate('/account-settings')
            } else {
                navigate('/checkout')
            }
        }

        const getSubButtonColor = () => {
            if(!authUserData) {
                return 'green'
            } else if(subStatus === 'active' || authUserData?.classlink_paid) {
                return 'purple'
            } else {
                return 'green'
            }
        }

  return (
    <SingleColumn wide>
        <HeroText>A formative assessment tool that dives deeper than ever before.{/* An online assessment tool built to enable data-driven teaching. */}{/* Improvement-centric formative assessment that captures every detail. */}</HeroText>
        <HomepageCtaBlock />
        <FlexRow withBorders width="3">
        {
            features &&
            features.map((feature, index) => <FeatureCard feature={feature} key={index}/>)
        }
        </FlexRow>
        <br />
            <Video />
        <br id="pricing" />
        <div style={{padding: "0px 16px"}}>
            <PageHeaderText>Pricing</PageHeaderText>
            <TextBlock>Affordable plans for individuals, teams, schools, and districts - right at your fingertips. No credit card required for your free trial, no calls with the sales team, just honest, straightforward pricing - once you try Brain Raider, you won't go back!</TextBlock>
        </div>
        <FlexRow width="2" noSpace>
            <PriceCard planName="Individuals" planPrice="$15/month" planSubtext="(billed monthly)" features={individualPlanFeatures} buttonText={getSubButtonText()} buttonFunction={subButtonClickHandler} buttonDisabled={false} buttonColor={getSubButtonColor()} />
            <PriceCard planName="Organizations" planPrice="$2/student" planSubtext="(billed yearly)" features={orgPlanFeatures} buttonText="Get Instant Quote" buttonFunction={navigateToQuoteGeneratorHandler} buttonDisabled={false} />
        </FlexRow>
        <div style={{maxWidth: '780px', margin: '0 auto', marginBottom: '28px' }}>
            <TextBlock lightPurple left>
                *coming summer 2025<br />
                ✝overall statistics only, not individual student results
            </TextBlock>
        </div>
        <ResearchCard variant='feedback' imageFirst topBorder />
        <ResearchCard variant='data' />
        <ResearchCard variant='targeting' imageFirst />
        <HomepageBottomBar lowMargin />
    </SingleColumn>
  );
}

export default HomePage;