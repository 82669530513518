import SingleColumn from '../components/UI/SingleColumn';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import { get, push, child, ref, update, onValue } from 'firebase/database';
import { getDownloadURL, ref as storageRef, uploadBytes/* , deleteObject */ } from 'firebase/storage';
import { storage } from '../util/firebase';
import { v4 } from 'uuid';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import TextAreaInput from '../components/UI/TextAreaInput';
import EmptyBlock from '../components/UI/EmptyBlock';
import Capstone from '../components/Cards/Capstone';
import TopRightButton from '../components/UI/TopRightButton';
import PulloutDrawer from '../components/UI/PulloutDrawer';
import Button from '../components/UI/Button';
import MultiselectInput from '../components/UI/MultiselectInput';
import StandaloneError from '../components/UI/StandaloneError';
import ImageInput from '../components/UI/ImageInput';
import Slider from '../components/UI/Slider';
import DropdownInput from '../components/UI/DropdownInput';
import TextBlock from '../components/UI/TextBlock';
import ImageCanvas from '../components/UI/ImageCanvas';
import DragDropAnchorSelector from '../components/UI/DragDropAnchorSelector';
import DragDropZoneSelector from '../components/UI/DragDropZoneSelector';
import TapTheWordInput from '../components/UI/TapTheWordInput';

const questionTypeOptions = [
  { value: "multiple_choice", text: "Multiple Choice" },
  { value: "multi_select", text: "Multiselect" },
  { value: "text_entry", text: "Text Entry" },
  { value: "hot_spot", text: "Hot Spot" },
  { value: "short_constructed_response", text: "Short Constructed Response" },
  { value: "drag_and_drop", text: "Drag and Drop" },
  { value: "tap_the_word", text: "Tap the Word"}
];

const QuestionsPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    // -- Load content needed to render the page and forms within the page -- //
    const [hasLoadedStimuli, setHasLoadedStimuli] = useState(false);
    const [allStimuliObject, setAllStimuliObject] = useState({});
    useEffect(() => {
        onValue(ref(database, `stimuli`), (snapshot) => {setAllStimuliObject(snapshot.val());});
        setHasLoadedStimuli(true);
    }, []);

    // -- Temporary Until Official Monitoring Page is Made -- // 
    // Connect to admin_results to pass data along //
    // Temporary - Listen to Answers for Admins
    const [adminData, setAdminData] = useState({});
    useEffect(() => {
        if(!authUserData?.roles?.admin) return;
        if(Object.keys(adminData).length > 0) return;

        
        onValue(ref(database, `admin_results`), (snapshot) => {
            // console.log(snapshot.val());
            setAdminData(snapshot.val());
        })
    }, [authUserData, adminData])

    const [standards, setStandards] = useState([]);
    const fetchStandards = useCallback(() => {
        let loadedStandards = [];

        /* if(authUserData?.standards_environment) {
            Object.keys(authUserData.standards_environment).forEach((state) => {
                Object.keys(authUserData.standards_environment[state]).forEach((standard_group) => {
                    get(child(ref(database), `standards/${state}/${standard_group}`)).then((snapshot) => {
                        if(snapshot.exists()) {
                            for(const key in snapshot.val()) {
                                loadedStandards.push({ value: key, text: `${key} - ${snapshot.val()[key]}`, submissionObject: { state: state, standard_group: standard_group, code: key, text: snapshot.val()[key] }});
                            };
                        };
                    });
                });
            });
        }; */


        get(child(ref(database), `standards`)).then((snapshot) => {
            if(snapshot.exists()) {
                for(const state in snapshot.val()) {
                    for(const standard_group in snapshot.val()[state]) {
                        for(const key in snapshot.val()[state][standard_group]) {
                            loadedStandards.push({ value: key, text: `${key} - ${snapshot.val()[state][standard_group][key]}`, submissionObject: { state: state, standard_group: standard_group, code: key, text: snapshot.val()[state][standard_group][key] }});
                        }
                    }
                };
            };
        });

        setStandards(loadedStandards);
    }, [/* authUserData */]);
    useEffect(() => {
        fetchStandards();
    }, [fetchStandards]);

    const [allFeedback, setAllFeedback] = useState([]);
    const listenToFeedbackHandler = useCallback (() => {
        const feedbackRef = ref(database, `feedback`);
        onValue(feedbackRef, (snapshot) => {
            let loadedFeedback = {};
        
            for(const key in snapshot.val()) {
                loadedFeedback[key] = {
                    _id: key,
                    concept: snapshot.val()[key].concept,
                    skills: snapshot.val()[key].skills,
                    misconceptions: snapshot.val()[key].misconceptions || null,
                    archived: snapshot.val()[key].archived || false
                };
            };
    
            setAllFeedback(loadedFeedback);
        });
    }, []);
    useEffect(() => {
        listenToFeedbackHandler();
    }, [listenToFeedbackHandler]);

    const stimuli = useMemo(() => {
        const newStimuli = [];
        if (allStimuliObject) {
            Object.keys(allStimuliObject).forEach((key) => {
                newStimuli.push({_id: key, ...allStimuliObject[key]});
            });
        }
        return newStimuli;
    }, [allStimuliObject]);

    const [filterText, setFilterText] = useState('');

    const filteredStimuli = useMemo(() => {
        const filterStimuli = () => {
            var filtered_stimuli = [...stimuli];

            // Only filter the stimuli if the user has typed in the search bar
            filtered_stimuli = stimuli.filter(stimulus => 
                !stimulus.archived && // Exclude stimuli with 'archived' as true
                (filterText ?
                    (stimulus.text.toLowerCase().includes(filterText.toLowerCase()) ||
                    (stimulus.questions && Object.keys(stimulus.questions).map(key => stimulus.questions[key].prompt).join(' ').toLowerCase().includes(filterText.toLowerCase())) ||
                    (stimulus.keywords && stimulus.keywords.toLowerCase().includes(filterText.toLowerCase())) ||
                    (stimulus.standards && JSON.stringify(stimulus.standards).split(')')[1].toLowerCase().includes(filterText.toLowerCase()))) :
                    true // Include all stimuli if there's no filter text
                    )
                );

            filtered_stimuli.reverse();

            return filtered_stimuli;
        };

        return filterStimuli();
    }, [filterText, stimuli]);


    // -- The following functions deal with creating, editing, and deleting Stimuli -- //
    const createStimulusClickHandler = () => {
        setEditingStimulus({ _id: '', text: '', image: '', keywords: '', standards: [] });
        setEditingStimulusErrors({text: '', image: '', standards: '' });
        setSelectedStandards([]);
        setImageURL('');
        setShowStimulusForm(true);
        setModified(true);
    };

    const editStimulusClickHandler = (key) => {
        let preselectedStandards = [];
        
        if(allStimuliObject[key].standards) {
            Object.keys(allStimuliObject[key].standards).forEach(state => {
                Object.keys(allStimuliObject[key].standards[state]).forEach(standard_group => {
                    Object.keys(allStimuliObject[key].standards[state][standard_group]).forEach(code =>
                        preselectedStandards.push(code));
                });
            });
        };

        setEditingStimulus({ _id: key, text: allStimuliObject[key].text, image: allStimuliObject[key].image, keywords: allStimuliObject[key].keywords, standards: preselectedStandards });
        setSelectedStandards(preselectedStandards);
        setEditingStimulusErrors({text: '', image: '', standards: '' });
        setImageURL(allStimuliObject[key].image);
        setShowStimulusForm(true);
    };

    const deleteStimulusHandler = (key) => {
        if(window.confirm('Are you sure?')) {
            const updates = {};
            updates[`stimuli/${key}/archived`] = true;
            update(ref(database), updates)
        };
    };


    // -- The following functions deal with creating, editing, and deleting Questions -- //
    const createQuestionClickHandler = (stimulus_id, image, text) => {
        setModified(true);
        setEditingQuestion({ _id: '', _stimulus_id: stimulus_id, prompt: '', stimuli_text: text, is_capstone: true, image: image, correct_count: 0, type: 'multiple_choice', incorrect_message: '', feedback: {}, answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false }, { text: '', isCorrect: false }] })
        setEditingQuestionErrors({ prompt: '', incorrect_message: '', answers: [false, false, false], all_answers: '', concept: '', skill_misconception: '', type: '', image: '' });
        setshowQuestionForm(true);
    };

    const editQuestionClickHandler = (question, stimulus_id, image, stimuli_text) => {
        setModified(false);
        const updatedAnswers = [];
        if(question.type === 'hot_spot') {
            if(question.answers) {
                Object.keys(question.answers).forEach(key => {
                    updatedAnswers.push({ rect: question.answers[key].rect, isCorrect: question.answers[key].isCorrect }); // add isCorrect
                });
            };
        } else if (question.type === 'tap_the_word') {
            if(question.answers) {
                Object.keys(question.answers).forEach(key => {
                    updatedAnswers.push({ region: question.answers[key].region, isCorrect: question.answers[key].isCorrect }); // add isCorrect
                });
            };
        } else if(question.type === 'drag_and_drop') {
            Object.keys(question.answers).forEach(key => {
                updatedAnswers.push({ text: question.answers[key].text, matching_anchors: question.answers[key].matching_anchors }); // add matching_anchors
            });
            updatedAnswers.push({ text: '', matching_anchors: {} });
        } else {
            if(question.answers) {
                Object.keys(question.answers).forEach(key => {
                    updatedAnswers.push({ text: question.answers[key].text, isCorrect: question.answers[key].isCorrect }); // add isCorrect
                });
            };
            updatedAnswers.push({ text: '', isCorrect: false });
        }

        let feedback_was_archived = false;
        if(question.feedback?._id) {
            if(allFeedback[question.feedback._id].archived || (question.feedback.skill && !allFeedback[question.feedback._id].skills.includes(question.feedback.skill)) || (question.feedback.misconception && !allFeedback[question.feedback._id].misconceptions.includes(question.feedback.misconception))) {
                feedback_was_archived = true;
            }
        };

        if(question.feedback?._id && !feedback_was_archived) {conceptChangeHandler(question.feedback._id);}

        if(question.is_capstone) {
            setEditingQuestion({ _id: question._id, _stimulus_id: stimulus_id, prompt: question.prompt, is_capstone: question.is_capstone, image: image, stimuli_text: stimuli_text, correct_count: question.correct_count, type: question.type, incorrect_message: question.incorrect_message, feedback: {}, answers: updatedAnswers })
            setEditingQuestionErrors({ prompt: '', incorrect_message: '', answers: [false, false, false], all_answers: '', concept: '', skill_misconception: '' });
            /* conceptChangeHandler(Object.keys(allFeedback)[0], false, true); */
            setModified(mod => false);
        }
        else if(feedback_was_archived) {
            setEditingQuestion({ _id: question._id, _stimulus_id: stimulus_id, prompt: question.prompt, is_capstone: question.is_capstone, image: image, stimuli_text: stimuli_text, correct_count: question.correct_count, type: question.type, incorrect_message: question.incorrect_message, feedback: {}, answers: updatedAnswers });
            /* conceptChangeHandler(Object.keys(allFeedback)[0], true, true); */
        }
        else if(question.feedback?.skill) {
            setEditingQuestion({ _id: question._id, _stimulus_id: stimulus_id, prompt: question.prompt, is_capstone: question.is_capstone, image: image, stimuli_text: stimuli_text, correct_count: question.correct_count, type: question.type, incorrect_message: question.incorrect_message, feedback: { _id: question.feedback._id, skill: question.feedback.skill }, answers: updatedAnswers })
            setEditingQuestionErrors({ prompt: '', incorrect_message: '', answers: [false, false, false], all_answers: '', concept: '', skill_misconception: '', type: '', image: '' });
            /* conceptChangeHandler(Object.keys(allFeedback)[0], false, true); */
            setModified(mod => false);
        }
        else if(question.feedback?.misconception) {
            setEditingQuestion({ _id: question._id, _stimulus_id: stimulus_id, prompt: question.prompt, is_capstone: question.is_capstone, image: image, stimuli_text: stimuli_text, correct_count: question.correct_count, type: question.type, incorrect_message: question.incorrect_message, feedback: { _id: question.feedback._id, misconception: question.feedback.misconception }, answers: updatedAnswers })
            setEditingQuestionErrors({ prompt: '', incorrect_message: '', answers: [false, false, false], all_answers: '', concept: '', skill_misconception: '', type: '', image: '' });
            /* conceptChangeHandler(Object.keys(allFeedback)[0], false, true); */
            setModified(mod => false);
        };

        if(question.type === 'drag_and_drop') {setEditingQuestion(questionData => ({...questionData, anchors: question.anchors}))};
        setshowQuestionForm(true);
    };

    const deleteQuestionHandler = (question_id, stimulus_id) => {
        if(window.confirm('Are you sure you want to archive this question?')) {
            const updates = {};
            updates[`stimuli/${stimulus_id}/questions/${question_id}/archived`] = true;
            update(ref(database), updates);
        };
    };


    // -- The following functions govern the behavior of the Add/Edit Stimulus form in the PulloutDrawer -- //
    const [editingStimulus, setEditingStimulus] = useState({ _id: '', text: '', image: '', keywords: '', standards: [] });
    const [editingStimulusErrors, setEditingStimulusErrors] = useState({text: '', image: '', standards: '' });
    const [showStimulusForm, setShowStimulusForm] = useState(false);

    const [selectedStandards, setSelectedStandards] = useState([]);
    const standardsModifiedHandler = (new_selections) => {
        setModified(true);
        setSelectedStandards(new_selections);
        setEditingStimulusErrors(errors => ({...errors, standards: ''}));
    };

    const [modified, setModified] = useState(false);
    const [hasChosenNewFeedback, setHasChosenNewFeedback] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitStimulusFormHandler = async (event) => {
        event.preventDefault()

        let errorsFound = 0;
        // Validate the entered text and number of standards
        if(editingStimulus.text === '' ) {
            setEditingStimulusErrors(errors => ({...errors, text: 'Text cannot be blank.'}));
            errorsFound++;
        } else {
            setEditingStimulusErrors(errors => ({...errors, text: ''}));
        };
    
        let standardsToSubmit = {};
        if(selectedStandards.length === 0) {
            setEditingStimulusErrors(errors => ({...errors, standards: 'Stimulus must have at least one standard.'}));
            errorsFound++;
        } else {
            setEditingStimulusErrors(errors => ({...errors, standards: ''}));
            selectedStandards.forEach(index => {
                const match = standards.findIndex(standard => standard.value === index);
                if(!standardsToSubmit[standards[match].submissionObject.state]) standardsToSubmit[standards[match].submissionObject.state] = {};
                if(!standardsToSubmit[standards[match].submissionObject.state][standards[match].submissionObject.standard_group]) standardsToSubmit[standards[match].submissionObject.state][standards[match].submissionObject.standard_group] = {};
                standardsToSubmit[standards[match].submissionObject.state][standards[match].submissionObject.standard_group][index] = standards[match].submissionObject.text;
            });
        };

        // If the stimulus text has been changed, check whether or not and of the questions are tap-the-word (and throw an error if so)
        if(editingStimulus._id !== '') {
            for (let key in allStimuliObject[editingStimulus._id].questions) {
                if(allStimuliObject[editingStimulus._id].questions[key].type === 'tap_the_word') {
                    // check if the stimulus text has changed
                    if(editingStimulus.text !== allStimuliObject[editingStimulus._id].text) {
                        setEditingStimulusErrors(errors => ({...errors, text: 'Text is in use by "Tap the Word" question(s).'}));
                        errorsFound++;
                    }
                }
            };
        }

        if(errorsFound > 0) {return;}
    
        setIsSubmitting(true);
    
        // Upload the associated image and get the URL where it landed
        let downloadURL = '';
        if(imageURL && typeof imageURL !== 'string') {
            // If a new image has been uploaded
            const imageName = `images/${imageURL.name.replace(' ','') + v4()}`
            const imageRef = storageRef(storage, imageName);
        
            const imageResponse = await uploadBytes(imageRef, imageURL)
            downloadURL = await getDownloadURL(imageResponse.ref);
    
        } else if (typeof imageURL === 'string') {
            // If a new image has not been uploaded but there was a previous image
            downloadURL = imageURL;
        };
    
        // If this is a new stimulus, add it to the database and get the ID to add it to the local DOM
        if(editingStimulus._id === '') {
            const newStimulusKey = push(child(ref(database), 'stimuli')).key;

            const updates = {};
            updates[`stimuli/${newStimulusKey}/text`] = editingStimulus.text;
            updates[`stimuli/${newStimulusKey}/image`] = downloadURL ? downloadURL : '';
            updates[`stimuli/${newStimulusKey}/keywords`] = editingStimulus.keywords;
            updates[`stimuli/${newStimulusKey}/standards`] = standardsToSubmit;
            update(ref(database), updates);
        };
        
        // If this is an edited stimulus, edit only its existing 'text', 'image', 'keywords', and 'standards'
        if(editingStimulus._id !== '') {
            const updates = {};
            updates[`stimuli/${editingStimulus._id}/text`] = editingStimulus.text;
            updates[`stimuli/${editingStimulus._id}/image`] = downloadURL ? downloadURL : '';
            updates[`stimuli/${editingStimulus._id}/keywords`] = editingStimulus.keywords;
            updates[`stimuli/${editingStimulus._id}/standards`] = standardsToSubmit;

            // check the assessmentIDs - if the stimuli is already on assessments, also calculate changes to their standards
            const assessmentIDsRef = ref(database, `/stimuli/${editingStimulus._id}/assessmentIDs`);
            let assessmentIDs = [];
            await get(assessmentIDsRef).then((snapshot) => {
                for(const key in snapshot.val()) {
                    assessmentIDs.push(key);
                }
            });

            if(assessmentIDs.length > 0) {
                for (let i = 0; i < assessmentIDs.length; i++) {
                    const assessmentStandardsRef = ref(database, `assessments/${assessmentIDs[i]}/standards`);
                    let updatedStandards = await get(assessmentStandardsRef).then(snapshot => snapshot.val());
        
                    const oldStandards = allStimuliObject[editingStimulus._id].standards;

                    // Iterate through the differences and update standards
                    for (const state in oldStandards) {
                        for (const group in oldStandards[state]) {
                            for (const code in oldStandards[state][group]) {
                                if (!standardsToSubmit[state]?.[group]?.[code]) {
                                    // Code exists in old, but not in new
                                    if (updatedStandards[state]?.[group]?.[code]?.count > 0) {
                                        updatedStandards[state][group][code].count -= 1;
                                    } else {
                                        delete updatedStandards[state][group][code];
                                    }
                                }
                            }
                        }
                    }
        
                    for (const state in standardsToSubmit) {
                        for (const group in standardsToSubmit[state]) {
                            for (const code in standardsToSubmit[state][group]) {
                                if (!oldStandards[state]?.[group]?.[code]) {
                                    // Code exists in new, but not in old
                                    if (!updatedStandards[state]) {
                                        updatedStandards[state] = {};
                                    }
                                    if (!updatedStandards[state][group]) {
                                        updatedStandards[state][group] = {};
                                    }
                                    updatedStandards[state][group][code] = {
                                        text: standardsToSubmit[state][group][code],
                                        count: 1
                                    };
                                }
                            }
                        }
                    }
        
                    // Apply changes to the 'standards' object
                    // Update counts, remove codes with count 0, and add missing codes
        
                    // Iterate through the 'updatedStandards' object
                    for (const state in updatedStandards) {
                        for (const group in updatedStandards[state]) {
                            for (const code in updatedStandards[state][group]) {
                                if (updatedStandards[state][group][code].count === 0) {
                                    delete updatedStandards[state][group][code];
                                }
                            }
                            if (Object.keys(updatedStandards[state][group]).length === 0) {
                                delete updatedStandards[state][group];
                            }
                        }
                        if (Object.keys(updatedStandards[state]).length === 0) {
                            delete updatedStandards[state];
                        }
                    }
        
                    updates[`assessments/${assessmentIDs[i]}/standards`] = updatedStandards;
                }
            }
            update(ref(database), updates);
        };
    
        // Reset the form
        setModified(false);
        setSelectedStandards([]);
        setEditingStimulus({ _id: '', text: '', image: '', keywords: '', standards: [] });
        setEditingStimulusErrors({text: '', image: '', standards: '' });
        setShowStimulusForm(false);
        setIsSubmitting(false);
    };

    const [imageURL, setImageURL] = useState('');
    const uploadChangeHandler = (url) => {
        setModified(true);
        setImageURL(url);
    };


    // -- The following functions govern the behavior of the Add/Edit Question form in the PulloutDrawer -- //
    const [showQuestionForm, setshowQuestionForm] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState({ _id: '', _stimulus_id: '', prompt: '', is_capstone: true, correct_count: 0, type: 'multiple_choice', image: '', incorrect_message: '', feedback: {}, answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false }, { text: '', isCorrect: false }] })
    const [editingQuestionErrors, setEditingQuestionErrors] = useState({ prompt: '', incorrect_message: '', answers: [false, false, false], all_answers: '', concept: '', skill_misconception: '', type: '', image: '' });

    const answerChangeHandler = (index, new_value) => {
        setModified(true); 

        const updatedAnswerText = new_value;
        let updatedAnswers = [...editingQuestion.answers];
        let updatedErrors = [...editingQuestionErrors.answers];
        updatedAnswers[index].text = new_value;
        updatedErrors[index] = false;

        // Clear error message if there are no remaining errors
        let errorCounter = 0;
        for(let i = 0; i < updatedAnswers.length; i++) {
            if(updatedErrors[i]) {errorCounter++;};
        };
        if(errorCounter === 0) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: ''}))};

        // Add another row if the user has typed text into the last row, or remove the last row if it is empty
        if(index === updatedAnswers.length - 1 && updatedAnswerText !== '') {
            if(editingQuestion.type === 'text_entry' || editingQuestion.type === 'short_constructed_response') updatedAnswers.push({ text: '', isCorrect: true })
            else if(editingQuestion.type === 'drag_and_drop') {updatedAnswers.push({ text: '', matching_anchors: {} })}
            else updatedAnswers.push({ text: '', isCorrect: false })
            updatedErrors.push(false)
        } else if(index === updatedAnswers.length - 2 && updatedAnswerText === '' && (updatedAnswers.length > 3 || (updatedAnswers.length > 2 && (editingQuestion.type === 'text_entry' || editingQuestion.type === 'short_constructed_response')))) {
            updatedAnswers.pop();
            updatedErrors.pop();
            if(editingQuestion.type !== 'text_entry' && editingQuestion.type !== 'short_constructed_response' && editingQuestion.type !== 'drag_and_drop') updatedAnswers[updatedAnswers.length - 1].isCorrect = false;
        };

        setEditingQuestion(questionData => ({...questionData, answers: updatedAnswers}));
        setEditingQuestionErrors(errorData => ({...errorData, answers: updatedErrors}));
    };

    const answerCorrectToggleHandler = (index) => {
        setModified(true); 

        let updatedAnswers = [...editingQuestion.answers];
        let updatedErrors = [...editingQuestionErrors.answers];
        updatedAnswers[index].isCorrect = !updatedAnswers[index].isCorrect;
        updatedErrors[index] = false;

        // Clear error message if there are no remaining errors
        let errorCounter = 0;
        for(let i = 0; i < updatedAnswers.length; i++) {
            if(updatedErrors[i]) {errorCounter++;};
            if(editingQuestion.type === 'multiple_choice' && i !== index) {
                updatedAnswers[i].isCorrect = false;
            }
        };
        if(errorCounter === 0) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: ''}))};

        setEditingQuestion(questionData => ({...questionData, answers: updatedAnswers}));
        setEditingQuestionErrors(errorData => ({...errorData, answers: updatedErrors}));
    };

    const submitQuestionFormHandler = async (event) => {
        event.preventDefault();
    
        // Count the number of answers and correct answers
        const answerData = [];
        let correct_count = 0;
        let updatedErrors = [...editingQuestionErrors.answers];
        let answersSoFar = [];
        let validationErrorCount = 0;

        // Check for duplicates and set error flags
        if(editingQuestion.type === 'hot_spot') {
            answersSoFar = [...editingQuestion.answers];
            for(let i = 0; i < editingQuestion.answers.length; i++) {
                answerData.push({correct: editingQuestion.answers[i].isCorrect, rect: {startX: editingQuestion.answers[i].startX, startY: editingQuestion.answers[i].startY, endX: editingQuestion.answers[i].endX, endY: editingQuestion.answers[i].endY}});
                if(editingQuestion.answers[i].isCorrect) {correct_count++;};
            }
        } else if(editingQuestion.type === 'tap_the_word') {
            answersSoFar = [...editingQuestion.answers];
            for(let i = 0; i < editingQuestion.answers.length; i++) {
                answerData.push({correct: editingQuestion.answers[i].isCorrect, region: {startChar: editingQuestion.answers[i].startChar, endChar: editingQuestion.answers[i].endChar, text: editingQuestion.answers[i].text }});
                if(editingQuestion.answers[i].isCorrect) {correct_count++;};
            }
        } else {
            for(let i = 0; i < editingQuestion.answers.length; i++) {
                if(editingQuestion.answers[i].text !== '') {
                    if(answersSoFar.filter(text => text === editingQuestion.answers[i].text).length > 0 && editingQuestion.type !== 'drag_and_drop') {
                        setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'There cannot be duplicate answers.'}));
                        updatedErrors[i] = true;
                        validationErrorCount++;
                    } else {
                        answersSoFar.push(editingQuestion.answers[i].text);
                    };
            
                    if(editingQuestion.type === 'drag_and_drop') {
                        if(Object.keys(editingQuestion.answers[i].matching_anchors)?.length > 0) {
                            answerData.push({ text: editingQuestion.answers[i].text, matching_anchors: editingQuestion.answers[i].matching_anchors });
                            correct_count++;
                        } else {
                            setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'All answers must have at least one matching anchor.'}));
                            updatedErrors[i] = true;
                            validationErrorCount++;
                        }
                    } else {
                        answerData.push({ text: editingQuestion.answers[i].text, correct: editingQuestion.answers[i].isCorrect });
                    }
                    if(editingQuestion.answers[i].isCorrect) {correct_count++;};
                } else if(editingQuestion.answers[i].text === '' && i !== editingQuestion.answers.length - 1) {
                    updatedErrors[i] = true;
                    validationErrorCount++;
                };
            };
        };

        // For all questions, validate everything but the feedback fields and display feedback to the user
        if(editingQuestion.prompt === '') {setEditingQuestionErrors(errorData => ({...errorData, prompt: 'Prompt cannot be blank.'})); validationErrorCount++;};
        if(correct_count < 1 && editingQuestion.type !== 'text_entry' && editingQuestion.type !== 'short_constructed_response') {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'There must be at least one correct answer.'})); validationErrorCount++;};
        if(answerData.length < 2 && editingQuestion.type !== 'text_entry' && editingQuestion.type !== 'short_constructed_response' && editingQuestion.type !== 'drag_and_drop') {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'There must be at least 2 answers.', answers: updatedErrors })); validationErrorCount++;};
        if(editingQuestion.incorrect_message.charAt(0) === editingQuestion.incorrect_message.charAt(0).toLowerCase()) {setEditingQuestionErrors(errorData => ({...errorData, incorrect_message: 'Message must start with a capital letter.'})); validationErrorCount++;};
        if(editingQuestion.incorrect_message.charAt(editingQuestion.incorrect_message.length - 1) !== '.') {setEditingQuestionErrors(errorData => ({...errorData, incorrect_message: 'Message must end with a period.'})); validationErrorCount++;};
    
        // For non-capstones, validate the remaining entered values and display feedback to the user
        if(!editingQuestion.is_capstone && (selectedConceptDetails === null || editingQuestion.feedback === null)) {
            if(selectedConceptDetails === null) {setEditingQuestionErrors(errorData => ({...errorData, concept: 'Each question must have a concept.' })); validationErrorCount++;};
            if(editingQuestion.feedback === null) {setEditingQuestionErrors(errorData => ({...errorData, skill_misconception: 'Each question must have a skill or misconception.' })); validationErrorCount++;};
        };

        // Validate criteria specific to multiple_choice questions
        if(editingQuestion.type === 'multiple_choice') {
            if(correct_count !== 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Multiple choice questions must have exactly one correct answer.'})); validationErrorCount++;};
            if(answersSoFar.length < editingQuestion.answers.length - 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Answers cannot be duplicates.', answers: updatedErrors })); validationErrorCount++;};
        }

        // Validate criteria specific to multi_select questions
        if(editingQuestion.type === 'multi_select') {
            if(answersSoFar.length < 3) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Multiselect questions must have at least three answers.'})); validationErrorCount++;};
            if(answersSoFar.length < editingQuestion.answers.length - 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Answers cannot be duplicates.', answers: updatedErrors })); validationErrorCount++;};
        }

        // Validate criteria specific to text_entry questions
        if(editingQuestion.type === 'text_entry') {
            if(answersSoFar.length < 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Text entry questions must have at least one accepted answer.'}));  validationErrorCount++;}
            else if(answersSoFar.length < editingQuestion.answers.length - 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Answers cannot be duplicates.', answers: updatedErrors })); validationErrorCount++;};
        }

        // Validate criteria specific to hot_spot questions
        /* if(editingQuestion.type === 'hot_spot') {
            setEditingQuestionErrors(errorData => ({...errorData, type: 'Hot Spot questions are not currently supported.'}));
            validationErrorCount++;
        } */
        
        // Validate criteria specific to short_constructed_response questions
        if(editingQuestion.type === 'short_constructed_response') {
            if(answersSoFar.length < 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'There must be at least one criteria to assess.'}));  validationErrorCount++;}
            else if(answersSoFar.length < editingQuestion.answers.length - 1) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'Answers cannot be duplicates.', answers: updatedErrors })); validationErrorCount++;};
            for(let i = 0; i < editingQuestion.answers.length - 1; i++) {
                let words = editingQuestion.answers[i].text.split(/\s+/);
                if(words[0] !== "Student") {
                    setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'All criteria must begin with the word "Student".'}));
                    updatedErrors[i] = true;
                    validationErrorCount++;
                } else if(editingQuestion.answers[i].text.charAt(editingQuestion.answers[i].text.length - 1) !== '.') {
                    setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'All criteria must end with a period.'}));
                    updatedErrors[i] = true;
                    validationErrorCount++;
                }
            }
        }

        // Validate criteria specific to drag_and_drop questions
        if(editingQuestion.type === 'drag_and_drop') {
            if(answerData.length < 2) {setEditingQuestionErrors(errorData => ({...errorData, all_answers: 'There must be at least 2 answers with matching anchors.', answers: updatedErrors })); validationErrorCount++;};
        };

        if(validationErrorCount !== 0) return;
    
        // Prepare data for submission
        setIsSubmitting(true);
        let questionObjectData = {};
        if(editingQuestion.is_capstone) {
            questionObjectData = {prompt: editingQuestion.prompt, is_capstone: true, correct_count, type: editingQuestion.type, incorrect_message: editingQuestion.incorrect_message};
        } else {
            questionObjectData = {prompt: editingQuestion.prompt, is_capstone: false, correct_count, type: editingQuestion.type, incorrect_message: editingQuestion.incorrect_message, feedback: editingQuestion.feedback};
        };

        if(editingQuestion.type === 'drag_and_drop') {questionObjectData.anchors = editingQuestion.anchors;};

        // If this is a new question, add it to the database 
        if(editingQuestion._id === '') {
            const newQuestionKey = push(child(ref(database), 'stimuli')).key;
        
            const questionObjectAnswers = {};
            const answerObjectAnswers = {};
            for(let i = 0; i < answerData.length; i++) {
                const newAnswerKey = push(child(ref(database), `stimuli/${editingQuestion._stimulus_id}/questions/${newQuestionKey}/answers`)).key;

                if(editingQuestion.type === 'hot_spot') {
                    questionObjectAnswers[newAnswerKey] = answerData[i].rect;
                    answerObjectAnswers[newAnswerKey] = { rect: answerData[i].rect, is_correct: answerData[i].correct};
                } else if(editingQuestion.type === 'tap_the_word') {
                    questionObjectAnswers[newAnswerKey] = answerData[i].region;
                    answerObjectAnswers[newAnswerKey] = { region: answerData[i].region, is_correct: answerData[i].correct};
                } else if(editingQuestion.type === 'drag_and_drop') {
                    questionObjectAnswers[newAnswerKey] = answerData[i].text;
                    answerObjectAnswers[newAnswerKey] = { text: answerData[i].text, matching_anchors: answerData[i].matching_anchors};
                } else {
                    questionObjectAnswers[newAnswerKey] = answerData[i].text;
                    answerObjectAnswers[newAnswerKey] = { text: answerData[i].text, is_correct: answerData[i].correct};
                }
            };

            const questionObjectDataWithAnswers = {...questionObjectData, answers: questionObjectAnswers};

            const updates = {};
            updates[`stimuli/${editingQuestion._stimulus_id}/questions/${newQuestionKey}`] = questionObjectDataWithAnswers;
            updates[`answers/${newQuestionKey}`] = answerObjectAnswers;
            update(ref(database), updates);
        };
    
        // If this is an edited question, overwrite its existing database entry
        if(editingQuestion._id !== '') {
            const questionObjectAnswers = {};
            const updates = {};
            for(let i = 0; i < answerData.length; i++) {
                const newAnswerKey = push(child(ref(database), `stimuli/${editingQuestion._stimulus_id}/questions/${editingQuestion._id}/answers`)).key;

                if(editingQuestion.type === 'hot_spot') {
                    questionObjectAnswers[newAnswerKey] = answerData[i].rect;
                    updates[`answers/${editingQuestion._id}/${newAnswerKey}`] = { rect: answerData[i].rect, is_correct: answerData[i].correct};
                } else if(editingQuestion.type === 'tap_the_word') {
                    questionObjectAnswers[newAnswerKey] = answerData[i].region;
                    updates[`answers/${editingQuestion._id}/${newAnswerKey}`] = { region: answerData[i].region, is_correct: answerData[i].correct};
                } else if(editingQuestion.type === 'drag_and_drop') {
                    questionObjectAnswers[newAnswerKey] = answerData[i].text;
                    updates[`answers/${editingQuestion._id}/${newAnswerKey}`] = { text: answerData[i].text, matching_anchors: answerData[i].matching_anchors};
                } else {
                    questionObjectAnswers[newAnswerKey] = answerData[i].text;
                    updates[`answers/${editingQuestion._id}/${newAnswerKey}`] = { text: answerData[i].text, is_correct: answerData[i].correct};
                }
            };

            const questionObjectDataWithAnswers = {...questionObjectData, answers: questionObjectAnswers};

            updates[`stimuli/${editingQuestion._stimulus_id}/questions/${editingQuestion._id}`] = questionObjectDataWithAnswers;

            // reset the global stat logs as the question has changed
            updates[`global_stats/${editingQuestion._stimulus_id}/${editingQuestion._id}`] = {};
            update(ref(database), updates);
        };
    
        // Reset the form
        resetQuestionForm();
    };
    
    const resetQuestionForm = () => {
        setIsSubmitting(false);
        setModified(false);
        setHasChosenNewFeedback(true);
        setshowQuestionForm(false);
        setEditingQuestion({ _id: '', prompt: '', is_capstone: true, correct_count: 0, type: 'multiple_choice', image: '', incorrect_message: '', feedback: {}, answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false }, { text: '', isCorrect: false }]});
        setEditingQuestionErrors({ prompt: '', incorrect_message: '', answers: [false, false, false], all_answers: '', concept: '', skill_misconception: '', type: '', image: '' });
        setSelectedConceptDetails({});
    };

    const [hasMatchedFeedback, setHasMatchedFeedback] = useState(false);
    const capstoneToggleHandler = (event) => {
        event.preventDefault();
        setModified(true);
        setHasChosenNewFeedback(true);
        setEditingQuestion(questionData => ({...questionData, is_capstone: !questionData.is_capstone}));
        setEditingQuestionErrors(errorData => ({...errorData, skill_misconception: '', concept: ''}));
        setHasMatchedFeedback(false);
        
        if (editingQuestion.feedback?._id) {conceptChangeHandler(editingQuestion.feedback._id);}
        else if (Object.keys(selectedConceptDetails).length < 1) {conceptChangeHandler(Object.keys(allFeedback).filter(key => !allFeedback[key].archived)[0]);};
    };

    const conceptOptions = []
    if (allFeedback) {
        Object.keys(allFeedback).forEach((key) => {
            if(!allFeedback[key].archived) {
                conceptOptions.push({ value: key, text: allFeedback[key].concept, index: allFeedback[key].index });
            }
        });

        conceptOptions.sort((a, b) => a.text - b.text);
    };
    const conceptChangeHandler = (value, feedbackWasDeleted, dontSetModified) => {
        if(!dontSetModified) {setModified(true);};
        if (feedbackWasDeleted) {setHasChosenNewFeedback(false); setEditingQuestionErrors(errors => ({...errors, skill_misconception: 'Concept, skill, or misconception has been deleted. Select new feedback to save.'}));}
        else {setHasChosenNewFeedback(true); setEditingQuestionErrors(errorData => ({...errorData, concept: '', skill_misconception: ''}));}

        let parsedConcept = {
            _id: value,
            concept: allFeedback[value].concept,
            skills: allFeedback[value].skills || [],
            misconceptions: allFeedback[value].misconceptions || []
        };

        setSelectedConceptDetails(parsedConcept);

        if(parsedConcept.skills?.length > 0) {
            setEditingQuestion(questionData => ({...questionData, feedback: { concept: parsedConcept.concept, _id: parsedConcept._id, skill: parsedConcept.skills[0] }}));
        } else if (parsedConcept.misconceptions?.length > 0) {
            setEditingQuestion(questionData => ({...questionData, feedback: { concept: parsedConcept.concept, _id: parsedConcept._id, misconception: parsedConcept.misconceptions[0] }}));
        }
    };

    const [selectedConceptDetails, setSelectedConceptDetails] = useState({});
    const skillMisconceptionOptions = [];
    if(allFeedback && Object.keys(selectedConceptDetails).length > 0) {
        for(let i = 0; i < selectedConceptDetails.skills?.length; i++) {
            skillMisconceptionOptions.push({ value: `[S] ${selectedConceptDetails.skills[i]}`, text: `[S] ${selectedConceptDetails.skills[i]}`, index: i });
        };
        for(let i = 0; i < selectedConceptDetails.misconceptions?.length; i++) {
            skillMisconceptionOptions.push({ value: `[M] ${selectedConceptDetails.misconceptions[i]}`, text: `[M] ${selectedConceptDetails.misconceptions[i]}`, index: i });
        };
        if(!hasMatchedFeedback) setHasMatchedFeedback(true);
    };
    const skillMisconceptionChangeHandler = (value, type, concept_id) => {
        setModified(true);
        setHasChosenNewFeedback(true);
        setEditingQuestionErrors(errors => ({...errors, skill_misconception: ''}));

        const updatedFeedback = {...editingQuestion.feedback};
        if(value.substring(0, 4) === '[S] ') {
            updatedFeedback.skill = value.substring(4);
            delete updatedFeedback.misconception;
        } else if(value.substring(0, 4) === '[M] ') {
            delete updatedFeedback.skill;
            updatedFeedback.misconception = value.substring(4);
        } else {
            delete updatedFeedback.skill;
            delete updatedFeedback.misconception;
            updatedFeedback._id = concept_id;
            if(type === 'skill') {
                updatedFeedback.skill = value;
            } else if (type === 'misconception') {
                updatedFeedback.misconception = value;
            };
        };
        setEditingQuestion(questionData => ({...questionData, feedback: updatedFeedback}))
    };
    const getSkillMisconceptionValue = () => {
        if(editingQuestion.feedback?.skill) {
            if(!hasMatchedFeedback) setHasMatchedFeedback(true);
            return `[S] ${editingQuestion.feedback.skill}`;
        } else if (editingQuestion.feedback?.misconception) {
            if(!hasMatchedFeedback) setHasMatchedFeedback(true);
            return `[M] ${editingQuestion.feedback.misconception}`;
        } else {
            if(!hasMatchedFeedback) setHasMatchedFeedback(true);
            return '';
        };
    };
    const skill_misconception_value = getSkillMisconceptionValue();

    const questionTypeChangeHandler = (value) => {
        const newType = value;
        setEditingQuestionErrors(errorData => ({ ...errorData, answers: [false, false, false], all_answers: '', image: '', type: '' }));
        setModified(true);

        if(newType === 'hot_spot' || newType === 'tap_the_word') {
            setEditingQuestion(questionData => ({...questionData, answers: []}));
        } else if(newType === 'drag_and_drop') {
            setEditingQuestion(questionData => ({...questionData, answers: [{ text: '', matching_anchors: {} }, { text: '', matching_anchors: {} }, { text: '', matching_anchors: {} }], anchors: {}}))
        } else if(!((newType === 'multiple_choice' && editingQuestion.type === 'multi_select') || (newType === 'multi_select' && editingQuestion.type === 'multiple_choice'))) {
            if(value === 'text_entry') {setEditingQuestion(questionData => ({...questionData, answers: [{ text: '', isCorrect: true }, { text: '', isCorrect: true }] }))}
            else if(value === 'short_constructed_response') {setEditingQuestion(questionData => ({...questionData, answers: [{ text: '', isCorrect: true }, { text: '', isCorrect: true }] }))}
            else {setEditingQuestion(questionData => ({...questionData, answers: [{ text: '', isCorrect: false }, { text: '', isCorrect: false }, { text: '', isCorrect: false }] }))}
        } else {
            let updatedAnswers = [...editingQuestion.answers];

            // Clear answer correctness
            for(let i = 0; i < updatedAnswers.length; i++) {
                    updatedAnswers[i].isCorrect = false;
            };
            setEditingQuestion(questionData => ({...questionData, answers: updatedAnswers}));
        }
        setEditingQuestion(questionData => ({...questionData, type: newType}));
    };
    

    // -- The following functions govern the behavior of the "Hot Spot" Add/Edit Question form in the PulloutDrawer -- //
    const onChangeAnswerRegions = (updated_regions) => {
        setEditingQuestion(data => ({...data, answers: [...updated_regions]}))
        // console.log('Questions.js received new answer regions!', updated_regions);
        setModified(true);
    }

    const onAnswerRegionError = (error) => {
        setEditingQuestionErrors(data => ({...data, all_answers: error}))
    }

    // -- The following functions govern the behavior of the "Tap the Word" Add/Edit Question form in the PulloutDrawer -- //
    const onChangeAnswerRanges = (updated_ranges) => {
        setEditingQuestion(data => ({...data, answers: [...updated_ranges]}))
        // console.log('Questions.js received new answer ranges!', updated_ranges);
        setModified(true);
    }

    const onAnswerRangeError = (error) => {
        setEditingQuestionErrors(data => ({...data, all_answers: error}))
    }


    // -- The following functions govern the behavior of the "Drag and Drop" Add/Edit Question form in the PulloutDrawer -- //
    const onChangeAnchors = (updated_anchors) => {
        let updatedAnswers = [...editingQuestion.answers];
        const remaining_anchor_ids = Object.keys(updated_anchors) || [];

        for(let i = 0; i < updatedAnswers.length; i++) {
            for(let key in updatedAnswers[i].matching_anchors) {
                if(!remaining_anchor_ids.includes(key)) {
                    delete updatedAnswers[i].matching_anchors[key];
                }
            }
        }

        setEditingQuestion(data => ({...data, anchors: updated_anchors, answers: updatedAnswers}))
        // console.log('Questions.js received new anchors!', updated_anchors);
        setModified(true);
    }

    const onAnchorError = (error) => {
        setEditingQuestionErrors(data => ({...data, anchors: error}))
    }

    const onChoiceAnchorToggle = (anchor_id, choice_answer_index) => {
        let updatedAnswers = [...editingQuestion.answers];

        if(updatedAnswers[choice_answer_index].matching_anchors[anchor_id]) {delete updatedAnswers[choice_answer_index].matching_anchors[anchor_id];}
        else {updatedAnswers[choice_answer_index].matching_anchors[anchor_id] = true;}

        setModified(true);
        setEditingQuestion(data => ({...data, answers: updatedAnswers}));
    };

    return (
        <SingleColumn>
            { allStimuliObject && authUserData && ('admin' in authUserData?.roles) &&
                <>
                    <PageHeaderText>Questions</PageHeaderText>
                    { ('admin' in authUserData?.roles) && <TopRightButton icon='add' onClick={createStimulusClickHandler}/> }
                    <TextInput name='search' leftIcon='search' placeholder='Search stimulus bank...' nospacebefore nospaceafter value={filterText} onChange={ (value) => {setFilterText(value)} } />
                    { Object.keys(allStimuliObject).length > 0 && filteredStimuli.map((stimulus, index) => <Capstone capstoneKey={stimulus._id} capstoneData={stimulus} editMode index={index} key={stimulus._id} last={index === filteredStimuli.length - 1} onAddQuestion={createQuestionClickHandler} onEdit={editStimulusClickHandler} onDelete={deleteStimulusHandler} canExpandQuestions onEditQuestion={editQuestionClickHandler} onDeleteQuestion={deleteQuestionHandler} withLines isAdmin={authUserData?.roles?.admin} adminResults={adminData?.stimuli?.hasOwnProperty(stimulus._id) ? adminData?.stimuli[stimulus._id] : null} />) }
                    { hasLoadedStimuli && filteredStimuli.length === 0 && <EmptyBlock>No questions found.</EmptyBlock>}
                    <PulloutDrawer show={showStimulusForm} header={editingStimulus._id ? 'Edit Stimulus' : 'Add Stimulus'} onCancel={() => {setShowStimulusForm(false); setEditingStimulus({ _id: '', text: '', image: '', keywords: '', standards: [] }); setEditingStimulusErrors({text: '', image: '', standards: '' });}}>
                        <form>
                            { showStimulusForm && <ImageInput onUpload={uploadChangeHandler} initialImageURL={imageURL} />}
                            <PageHeaderText small labelFor='stimulus-text'>Stimulus Text</PageHeaderText>
                            <TextAreaInput name='stimulus-text' value={ editingStimulus.text } onChange={ (value) => {setEditingStimulus(stimulusData => ({...stimulusData, text: value})); setEditingStimulusErrors(errorData => ({...errorData, text: ''})); setModified(true);}} error={ editingStimulusErrors.text }/>
                            { showStimulusForm &&
                                <>
                                    <PageHeaderText small labelFor='stimulus-standards'>Standard(s)</PageHeaderText>
                                    <MultiselectInput name='stimulus-standards' options={ standards } onModified={standardsModifiedHandler} nospace={ editingStimulusErrors.standards !== '' } selectedValues={selectedStandards}/>
                                    <StandaloneError spaceafter>{editingStimulusErrors.standards}</StandaloneError>
                                </>
                            }
                            <PageHeaderText small labelFor='stimulus-keywords'>Additional Keyword(s)</PageHeaderText>
                            <TextInput name='stimulus-keywords' value={ editingStimulus.keywords } onChange={ (value) => {setEditingStimulus(assessmentData => ({...assessmentData, keywords: value})); setModified(true);} } />
                            <Button onClick={ submitStimulusFormHandler } disabled={ isSubmitting || !modified }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        </form>
                    </PulloutDrawer>
                    <PulloutDrawer show={showQuestionForm} header={editingQuestion._id ? 'Edit Question' : 'Add Question'} onCancel={resetQuestionForm}>
                        <form>
                            <PageHeaderText small labelFor='question-prompt'>Prompt</PageHeaderText>
                            <TextAreaInput name='question-prompt' value={ editingQuestion.prompt } onChange={ (value) => {setEditingQuestion(questionData => ({...questionData, prompt: value})); setEditingQuestionErrors(errorData => ({...errorData, prompt: ''})); setModified(true);}} error={ editingQuestionErrors.prompt }/>
                            <PageHeaderText small labelFor='question-type'>Question Type</PageHeaderText>
                            <DropdownInput name='question-type' value={ editingQuestion.type } onChange={ questionTypeChangeHandler } error={ editingQuestionErrors.type } options={questionTypeOptions} />                          
                            { editingQuestion.type === 'text_entry' && <PageHeaderText small labelFor='answer-choices-0'>Accepted Answers</PageHeaderText>}
                            { editingQuestion.type === 'short_constructed_response' && <PageHeaderText small labelFor='answer-choices-0'>Rubric</PageHeaderText>}
                            { (editingQuestion.type === 'multiple_choice' || editingQuestion.type === 'multi_select') && <PageHeaderText small labelFor='answer-choices-0'>Answer Choices</PageHeaderText>}
                            { editingQuestion.type === 'hot_spot' && 
                                <>
                                    <PageHeaderText small>Accepted Answers</PageHeaderText>
                                    <TextBlock>Click and drag on the image below to create an answer region. Click any region to toggle whether or not it is a correct answer. Ctrl + click to remove unwanted regions.</TextBlock>
                                </>
                            }
                            {  (editingQuestion.type === 'text_entry' || editingQuestion.type === 'multiple_choice' || editingQuestion.type === 'multi_select') &&
                                editingQuestion.answers.map((item, index) =>
                                    <TextInput key={index} index={index} error={editingQuestionErrors.answers[index]} leftIcon={editingQuestion.type !== 'text_entry' ? item.isCorrect ? (editingQuestion.type === 'multi_select' ? 'checked-box' : 'checked-radio') : (editingQuestion.type === 'multi_select' ? 'unchecked-box' : 'unchecked-radio') : null} onIconClick={answerCorrectToggleHandler} name={`answer-choices-${index}`} value={item.text} onChange={ (value) => {answerChangeHandler(index, value);} } placeholder={index === editingQuestion.answers.length - 1 ? editingQuestion.type === 'text_entry' ? 'Add more accepted answers...' : 'Add more answer choices...' : '' } lightplaceholder nospaceafter={index === editingQuestion.answers.length - 1 && editingQuestionErrors.all_answers !== ''} last={index === editingQuestion.answers.length - 1} condensed justPadded={editingQuestion.type === 'text_entry'}/>
                                )
                            }
                            { 
                                editingQuestion.type === 'hot_spot' && <ImageCanvas imagesrc={editingQuestion.image} onChangeAnswerRegions={onChangeAnswerRegions} onError={onAnswerRegionError} initialAnswers={editingQuestion.answers}/>
                            }
                            {
                                editingQuestion.type === 'short_constructed_response' && 
                                editingQuestion.answers.map((item, index) =>
                                    <div key={index}>
                                        <TextInput key={index} index={index} error={editingQuestionErrors.answers[index]} leftIcon={index === editingQuestion.answers.length - 1 ? 'one_point_alt' : 'one_point'} name={`answer-choices-${index}`} value={item.text} onChange={ (value) => {answerChangeHandler(index, value);} } placeholder={index === editingQuestion.answers.length - 1 ? 'Add more rubric criteria...' : '' } lightplaceholder nospaceafter={index === editingQuestion.answers.length - 1 && editingQuestionErrors.all_answers !== ''} last={index === editingQuestion.answers.length - 1} condensed />
                                    </div >
                                )
                            }
                            {
                                editingQuestion.type === 'drag_and_drop' &&
                                <>
                                    <PageHeaderText small>Drag and Drop Anchors</PageHeaderText>
                                    <TextBlock>Click on the image below to create a drag and drop anchor. Ctrl + click to remove unwanted anchors.</TextBlock>
                                    <DragDropAnchorSelector imagesrc={editingQuestion.image} onChangeAnchors={onChangeAnchors} onError={onAnchorError} initialAnchors={editingQuestion.anchors} />
                                    <PageHeaderText small labelFor='answer-choices-0'>Draggable Answers</PageHeaderText>
                                    {
                                        editingQuestion.answers.map((item, index) =>
                                            <div key={index}>
                                                <TextInput index={index} error={editingQuestionErrors.answers[index]} onIconClick={answerCorrectToggleHandler} name={`answer-choices-${index}`} value={item.text} onChange={ (value) => {answerChangeHandler(index, value);} } placeholder={index === editingQuestion.answers.length - 1 ? 'Add more draggable answers...' : '' } lightplaceholder nospaceafter={index === editingQuestion.answers.length - 1 && editingQuestionErrors.all_answers !== ''} last={index === editingQuestion.answers.length - 1} superCondensed={index !== editingQuestion.answers.length - 1} justPadded />
                                                {index !== editingQuestion.answers.length - 1 && <DragDropZoneSelector anchors={editingQuestion.anchors} answerIndex={index} onChoiceAnchorToggle={onChoiceAnchorToggle} selections={editingQuestion.answers[index].matching_anchors} />}
                                            </div>
                                        )
                                    }
                                </>
                            }
                            {
                                editingQuestion.type === 'tap_the_word' &&
                                <>
                                    <PageHeaderText small>Accepted Answers</PageHeaderText>
                                    <TextBlock>Click a character in the stimulus text below to mark the start of a word or phrase, then click another character to mark the end. Click any marked word or phrase to toggle whether or not it is a correct answer. Ctrl + click to remove unwanted words or phrases.</TextBlock>
                                    <TapTheWordInput stimuliText={editingQuestion.stimuli_text} onChangeAnswerRanges={onChangeAnswerRanges} onError={onAnswerRangeError} hasError={editingQuestionErrors.all_answers !== ''} initialAnswers={editingQuestion.answers}/>
                                </>
                            }
                            <StandaloneError spaceafter>{editingQuestionErrors.all_answers}</StandaloneError>
                            <PageHeaderText small labelFor='incorrect-message'>Incorrect Selection Message</PageHeaderText>
                            <TextInput name='incorrect-message' value={ editingQuestion.incorrect_message } onChange={ (value) => {setEditingQuestion(questionData => ({...questionData, incorrect_message: value})); setModified(true); setEditingQuestionErrors(errorData => ({...errorData, incorrect_message: ''}))} } error={editingQuestionErrors.incorrect_message}/>
                            <div style={{marginBottom: '76px'}}>
                                <PageHeaderText small labelFor='incorrect-message' floatLeft>Capstone Question</PageHeaderText>
                                <Slider index={ editingQuestion.is_capstone ? 1 : 0 } fitted firstOption='No' secondOption='Yes' onFirstOption={capstoneToggleHandler} onSecondOption={capstoneToggleHandler}/>
                            </div>
                            {
                                !editingQuestion.is_capstone && hasMatchedFeedback && 
                                <>
                                    <PageHeaderText small labelFor='concept'>Concept</PageHeaderText>
                                    <DropdownInput name='concept' onChange={conceptChangeHandler} value={editingQuestion.feedback?._id || ''} options={conceptOptions} error={editingQuestionErrors.concept}/>
                                    <PageHeaderText small labelFor='skill-misconception'>Targeted Skill or Misconception</PageHeaderText>
                                    <DropdownInput name='skill-misconception' onChange={skillMisconceptionChangeHandler} value={skill_misconception_value || ''} options={skillMisconceptionOptions} error={editingQuestionErrors.skill_misconception}/>
                                </>
                            }
                            <Button onClick={ submitQuestionFormHandler } disabled={ isSubmitting || !modified || !hasChosenNewFeedback }>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
                        </form>
                    </PulloutDrawer>
                </>
            }
            { (!authUserData || !('admin' in authUserData?.roles)) && <EmptyBlock>You are not authorized to view this resource.</EmptyBlock> }
        </SingleColumn>
    );
};

export default QuestionsPage;