import expandIcon from '../../images/expand-icon.svg';
import expandIconAlt from '../../images/expand-icon-alt.svg';
import archiveIconAlt from '../../images/archive-icon-alt.svg';
import editIconAlt from '../../images/edit-icon-alt.svg';
import { useEffect, useState, useCallback } from 'react';
import { database } from '../../util/firebase';
import { ref, onValue } from 'firebase/database';
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox, MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { HiOutlineTrophy } from 'react-icons/hi2';
import classes from './Question.module.css';
import ImageCanvas from '../UI/ImageCanvas';
import onePointIcon from '../../images/rubric-one-point.svg';
import DragDropAnchorSelector from '../UI/DragDropAnchorSelector';
import TapTheWordInput from '../UI/TapTheWordInput';

const Question = (props) => {
    const [answersObject, setAnswersObject] = useState({});
    const [answerTextAndCorrectness, setAnswerTextAndCorrectness] = useState({});
    const [needsAnswer, setNeedsAnswer] = useState(false);

    const listenToAnswersHandler = useCallback (() => {
        const answersRef = ref(database, `answers/${props.question._id}`);
        onValue(answersRef, (snapshot) => {
            setAnswersObject(snapshot.val());
        });
    }, [props.question._id]);

    // Answers and Stimuli data load at different speeds, this ensures they stay in sync
    useEffect(() => {
        let loadedAnswers = {};
        for(const key in answersObject) {
            if(Object.keys(props.question.answers).includes(key)) {
                if(answersObject[key].text && answersObject[key].matching_anchors) {
                    loadedAnswers[key] = {
                        _id: key,
                        text: answersObject[key].text,
                        matching_anchors: answersObject[key].matching_anchors
                    };
                } else if(answersObject[key].text) {
                    loadedAnswers[key] = {
                        _id: key,
                        text: answersObject[key].text,
                        isCorrect: answersObject[key].is_correct
                    };
                } else if(answersObject[key].rect) {
                    loadedAnswers[key] = {
                        _id: key,
                        rect: answersObject[key].rect,
                        isCorrect: answersObject[key].is_correct
                    };
                } else if(answersObject[key].region) {
                    loadedAnswers[key] = {
                        _id: key,
                        region: answersObject[key].region,
                        isCorrect: answersObject[key].is_correct
                    };
                }
            };
        };
    
        setAnswerTextAndCorrectness(loadedAnswers);
    }, [props.question.answers, answersObject])

    useEffect(() => {
        if(needsAnswer && Object.keys(answerTextAndCorrectness).length === 0) {
            listenToAnswersHandler();
        }
    }, [listenToAnswersHandler, needsAnswer, answerTextAndCorrectness]);

    const [anchors, setAnchors] = useState([]);
    useEffect(() => {
        if(!props.question.anchors ) return;

        let updatedAnchors = [];
        for(let key in props.question.anchors) {
            updatedAnchors.push({ id: key, x: props.question.anchors[key].x, y: props.question.anchors[key].y, display_num: props.question.anchors[key].display_num });
        }

        setAnchors(updatedAnchors);
        // console.log('anchors: ', updatedAnchors)
    }, [props.question.anchors]);

    return(
        <div className={ classes.questionWrapper }>
            <div className={ `${classes.question} ${props.expandable ? classes.condenseLeft : ''} ${props.question._id === props.expandedId ? classes.autoHeight : '' }` }>
                {props.expandable && (props.question._id === props.expandedId ?
                    <img src={ expandIconAlt } alt='Less...' className={ classes.expandIcon } onClick={props.claimExpanded.bind(null, '')} /> :
                    <img src={ expandIcon } alt='More...' className={ classes.expandIcon } onClick={() => {setNeedsAnswer(true); props.claimExpanded(props.question._id)}} />)
                }
                {props.expandable && props.question._id === props.expandedId &&
                    <>
                        {props.onEdit && <img src={ editIconAlt } alt='Edit' className={ classes.editIcon } onClick={props.onEdit.bind(null, {...props.question, answers: answerTextAndCorrectness}, props.question._stimulus_id, props.question.image, props.question.stimulus_text)} />}
                        {props.onDelete && <img src={ archiveIconAlt } alt='Archive' className={ classes.archiveIcon } onClick={props.onDelete.bind(null, props.question._id, props.question._stimulus_id)} />}
                    </>
                }
                <div className={classes.iconSpace}>
                    {props.question.is_capstone && <HiOutlineTrophy />}
                </div>
                <div className={classes.questionText}>
                    <strong>{props.adminResults ? `(${Math.round((props.adminResults.correct_count || 0) / props.adminResults.total_count * 100 )}%) ` : ''}</strong>{props.question.prompt}
                </div>
            </div>
            {
                props.expandable &&
                <div className={ classes.sidebar }></div>
            }
            { props.question._id === props.expandedId && props.question.answers &&
                <div className={ `${classes.answers} ${props.standalone ? classes.standalone : ''}` }>
                    { props.question.type === 'multi_select' && Object.keys(props.question.answers).map(key =>
                        <div className={classes.answerRow} key={key}>
                            <div className={classes.iconSpace}>
                                { answerTextAndCorrectness[key]?.isCorrect ? <MdOutlineCheckBox /> : <MdOutlineCheckBoxOutlineBlank />}
                            </div>
                            {props.question.answers[key]}
                        </div>
                    )}
                    { props.question.type === 'multiple_choice' && Object.keys(props.question.answers).map(key =>
                        <div className={classes.answerRow} key={key}>
                            <div className={classes.iconSpace}>
                                { answerTextAndCorrectness[key]?.isCorrect ? <MdOutlineRadioButtonChecked /> : <MdOutlineRadioButtonUnchecked />}
                            </div>
                            {props.question.answers[key]}
                        </div>
                    )}
                    { props.question.type === 'text_entry' &&
                        <div className={classes.textAnswers}>
                            <strong>Accepted Answers:</strong>&nbsp;
                            {
                                Object.keys(props.question.answers).map((key, index) => <span key={key}>{props.question.answers[key]}{index === Object.keys(props.question.answers).length - 1? '' : <>,&nbsp;</>}</span>)
                            }
                        </div>
                    }
                    {
                        props.question.type === 'hot_spot' && (!props.isAdmin || Object.keys(answerTextAndCorrectness).length > 0) && 
                        <>
                            <strong>Answer Regions:</strong>
                            <ImageCanvas imagesrc={props.question.image} initialAnswers={Object.keys(props.question.answers)?.map(key => ({ rect: props.question.answers[key], isCorrect: answerTextAndCorrectness[key]?.isCorrect}))} static onChangeAnswerRegions={null} onError={null} />
                        </>
                    }
                    {
                        props.question.type === 'drag_and_drop' &&
                        <>
                            <DragDropAnchorSelector imagesrc={props.question.image} initialAnchors={anchors} static onChangeAnchors={null} onError={null} />
                            <div className={classes.label}><strong>Draggable Answers & Matching Anchors:</strong></div>
                            {
                                Object.keys(props.question.answers).map((key, index) => 
                                    <div className={`${classes.anchorRow} ${index === anchors.length - 1 ? classes.last : ''}`} key={key}>
                                        {anchors.filter(anchor => answersObject[key]?.matching_anchors[anchor.id]).map(anchor => <div key={anchor.id} className={classes.anchorLabel}>{anchor.display_num}</div>)}
                                        <div className={classes.answerText}>{props.question.answers[key]}</div>
                                    </div>
                            )}
                        </>
                    }
                    {
                        props.question.type === 'short_constructed_response' && 
                        
                        <div className={classes.rubricAnswers}>
                            <strong>Rubric:</strong><br />
                            {
                                Object.keys(props.question.answers).map((key, index) => 
                                    <div className={classes.rubricRow} key={key}>
                                        <img src={ onePointIcon }  className={ classes.onePointIcon } alt='' />
                                        {props.question.answers[key]}
                                    </div>
                            )}
                        </div>
                    }
                    {
                        props.question.type === 'tap_the_word' && (!props.isAdmin || Object.keys(answerTextAndCorrectness).length > 0) &&
                        <>
                            <strong>Answers:</strong>
                            <TapTheWordInput stimuliText={props.question.stimulus_text} initialAnswers={Object.keys(props.question.answers)?.map(key => ({ region: props.question.answers[key], isCorrect: answerTextAndCorrectness[key]?.isCorrect}))} static onChangeAnswerRanges={null} onError={null} />
                        </>
                    }
                </div>
            }
            
        </div>
    );

};

export default Question;