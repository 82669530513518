import assessmentArt from '../../images/assessment-art.svg';
import classes from './Assessment.module.css';

const Assessment = (props) => {
    let standards = '';

    let matchingStandards = [];
    if(props.assessment.standards && props.standardsToShow) {
        Object.keys(props.standardsToShow).forEach(state => {
            Object.keys(props.standardsToShow[state]).forEach(standard_group => {
                if(props.assessment.standards[state]) {
                    if(props.assessment.standards[state]) {
                        if(props.assessment.standards[state][standard_group]) {
                            Object.keys(props.assessment.standards[state][standard_group]).forEach(code => {
                                matchingStandards.push(code);
                            })
                        };
                    };
                };
            })
        })
    };

    if (matchingStandards.length > 0) {
        let firstParts = matchingStandards.map(standard => standard.split(' ').slice(0, 1).join(' '));
        let lastParts = matchingStandards.map(standard => standard.split(' ').slice(-1).join(''));
        standards = firstParts[0] + ' ' + lastParts.join(', ');
    }

    return (
        <div className={ `${classes.assessmentWrapper} ${ !props.assessment.published ? classes.faded : '' } ${ props.last ? classes.last : '' }` } onClick={ props.onClick.bind(null, props.assessment)}>
            <img src={ assessmentArt } alt='' className={ classes.assessmentArt } />
            <div className={ classes.assessmentName }>{ props.assessment.name }</div>
            <div className={ classes.assessmentDescription }>{ props.assessment.description }</div>
            <div className={ classes.capstonesWrapper }>{ props.assessment.capstone_num } Capstone{ props.assessment.capstone_num !== 1 && 's' }</div>
            <div className={ classes.standardsWrapper }>{ standards }</div>
        </div>
    );
};

export default Assessment;