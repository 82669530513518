import classes from './ScreenSizeWarning.module.css';

const ScreenSizeWarning = (props) => {
    return (
        <div className={ classes.blockWrapper }>
            { props.children }
        </div>
    );
};

export default ScreenSizeWarning;