import { useState } from 'react';
import resultArt from '../../images/result-art.svg';
import expandIcon from '../../images/expand-icon.svg';
import expandIconAlt from '../../images/expand-icon-alt.svg';
import classes from './StudentResult.module.css';

// TODO: Fix CSS file and remove extra CSS
const StudentResult = (props) => {
    const [expanded, setExpanded] = useState(false);

    const noteText = "(Note: This attempt was closed by your teacher)";
    const wasClosedByTeacher = props.attempt?.feedback_text && props.attempt.feedback_text.endsWith(noteText);

    const feedbackMain = wasClosedByTeacher ? props.attempt.feedback_text.slice(0, -noteText.length) : props.attempt.feedback_text;
    const feedbackNote = wasClosedByTeacher ? noteText : '';

    return (
        <div className={ `${classes.resultWrapper} ${ expanded ? classes.expanded : '' }` }>
            <div className={ classes.resultName }>{ props.attempt.name }</div>
            <div className={ `${classes.summarizedFeedback} ${ expanded ? classes.expanded : '' }` }>{ feedbackMain }{ wasClosedByTeacher ? <span className={classes.lighter}>{ feedbackNote }</span> : ''}</div>
            <div className={ classes.score }>{props.attempt.score}{(props.attempt.score || props.attempt.score === 0) ? '%' : ''}</div>
            {expanded ?
                <img src={ expandIconAlt } alt='Less...' className={ classes.expandIcon } onClick={() => {setExpanded(false)}} /> :
                <img src={ expandIcon } alt='More...' className={ classes.expandIcon } onClick={() => {setExpanded(true)}} />
            }
            <img src={ resultArt } alt='' className={ classes.resultArt } />
        </div>
    )
}

export default StudentResult;