import SingleColumn from '../components/UI/SingleColumn';
import PageHeaderText from '../components/UI/PageHeaderText';
import Button from '../components/UI/Button';
import BottomRightButton from '../components/UI/BottomRightButton';
import TextInput from '../components/UI/TextInput';
import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { auth, database } from '../util/firebase';
import { ref, update, child, get } from 'firebase/database';
import { updateEmail } from 'firebase/auth';
import EmptyBlock from '../components/UI/EmptyBlock';
import MultiselectInput from '../components/UI/MultiselectInput';
import StandaloneError from '../components/UI/StandaloneError';
import TextBlock from '../components/UI/TextBlock';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { firestoreDb, functions } from '../util/firebase';
import { httpsCallable } from 'firebase/functions';

const AccountSettingsPage = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('small');}, [setHeaderSize]);

    const navigate = useNavigate();

    const [enteredInfo, setEnteredInfo] = useState({ first_name: '', last_name: '', email: '' });
    const [errors, setErrors] = useState({ first_name: '', last_name: '', email: '' });
    const [hasLoaded, setHasLoaded] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [hasSaved, setHasSaved] = useState(false);

    useEffect(() => {
        if(authUserData) {
            setEnteredInfo({ first_name: authUserData.first_name, last_name: authUserData.last_name, email: authUserData.email });
            setHasLoaded(true);
        };
    }, [authUserData]);

    const saveChangesHandler = (event) => {
        event.preventDefault();

        var errorCounter = 0;
        if(enteredInfo.first_name === '') {
            errorCounter++;
            setErrors(errors => ({...errors, first_name: 'This field is required.'}));
        };
        if(enteredInfo.last_name === '') {
            errorCounter++
            setErrors(errors => ({...errors, last_name: 'This field is required.'}));
        }
        if(enteredInfo.email === '') {
            errorCounter++;
            setErrors(errors => ({...errors, email: 'This field is required.'}));
        };
        if(selectedSets?.length === 0) {
            errorCounter++;
            setErrors(state => ({...state, standards: 'Must select at least one standard set.'}))
        }
        if(errorCounter > 0) {return;}

        setIsSaving(true);

        if(enteredInfo.email === authUserData?.email) {
            const updates = {};
            updates[`users/${authUserData.token}/first_name`] = enteredInfo.first_name;
            updates[`users/${authUserData.token}/last_name`] = enteredInfo.last_name;
            updates[`users/${authUserData.token}/standards_environment`] = makeNewStandardsObject();
            updates[`user_accounts/${authUserData.token}/first_name`] = enteredInfo.first_name;
            updates[`user_accounts/${authUserData.token}/last_name`] = enteredInfo.last_name;
            updates[`user_accounts/${authUserData.token}/standards_environment`] = makeNewStandardsObject();
            update(ref(database), updates);
            setIsSaving(false);
            setHasSaved(true);
            return;
        };

        if(enteredInfo.email !== authUserData?.email) {
            updateEmail(auth.currentUser, enteredInfo.email).then(() => {
                const updates = {};
                updates[`users/${authUserData.token}/first_name`] = enteredInfo.first_name;
                updates[`users/${authUserData.token}/last_name`] = enteredInfo.last_name;
                updates[`users/${authUserData.token}/email`] = enteredInfo.email;
                updates[`users/${authUserData.token}/standards_environment`] = makeNewStandardsObject();
                updates[`user_accounts/${authUserData.token}/first_name`] = enteredInfo.first_name;
                updates[`user_accounts/${authUserData.token}/last_name`] = enteredInfo.last_name;
                updates[`user_accounts/${authUserData.token}/email`] = enteredInfo.email;
                updates[`user_accounts/${authUserData.token}/standards_environment`] = makeNewStandardsObject();
                update(ref(database), updates);
                setIsSaving(false);
                setHasSaved(true);
            }).catch((error) => {
                if(error.message === 'Firebase: Error (auth/invalid-email).') {setErrors(errors => ({...errors, email: 'Invalid email address.'}));};
                if(error.message === 'Firebase: Error (auth/email-already-in-use).') {setErrors(errors => ({...errors, email: 'Email address already in use.'}));};
                if(error.message === 'Firebase: Error (auth/requires-recent-login).') {setErrors(errors => ({...errors, email: 'Recent login required. Log out and log in again.'}));};
                setIsSaving(false);
                setHasSaved(false);
            });
        };
    };

    const resetPasswordHandler = (event) => {
        event.preventDefault();
        navigate('/password-reset')
    }

    const isClasslinkRostered = () => {
        if(authUserData?.identity_link === "classlink_rostered" || authUserData?.identify_link === "classlink_rostered") {
            return true;
        };
        return false;
    }

    const [standardSets, setStandardSets] = useState([]);
    const [selectedSets, setSelectedSets] = useState([]);
    useEffect(() => {
        if(!authUserData) return;

        let loadedSets = [];
        let currentSets = [];

        get(child(ref(database), `standards`)).then((snapshot) => {
            if(snapshot.exists()) {
                for(const key in snapshot.val()) {
                    for(const secondaryKey in snapshot.val()[key]) {
                        loadedSets.push({ value: `${key}/${secondaryKey}`, text: `${key} - ${secondaryKey}`})
                    }
                };

                setStandardSets(loadedSets);

                // set the initial standard(s) sets
                const initial_data = authUserData.standards_environment;
                for(const key in initial_data) {
                    for(const secondaryKey in initial_data[key]) {
                        currentSets.push(`${key}/${secondaryKey}`)
                    }
                };

                setSelectedSets(currentSets)
            };
        });
    }, [authUserData]);

    const standardSetModifiedHandler = (new_selections) => {
        setSelectedSets(new_selections);
        setHasSaved(false);
        if(new_selections?.length !== 0) setErrors(state => ({...state, standards: ''}))
    };

    const makeNewStandardsObject = () => {
        let object = {}

        for(let i = 0; i < selectedSets.length; i++) {
            const pieces = selectedSets[i].split('/');
            
            if(!object[pieces[0]]) object[pieces[0]] = {};
            object[pieces[0]][pieces[1]] = true;
        }

        return object
    }

    function formatDate(seconds) {
        const milliseconds = seconds * 1000; // Convert seconds to milliseconds
        const date = new Date(milliseconds); // Create a Date object
      
        // Array of month names
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
      
        const day = date.getUTCDate(); // Get the day of the month
        const monthIndex = date.getUTCMonth(); // Get the month (0-indexed)
        const year = date.getUTCFullYear(); // Get the full year
      
        // Format the date as "Month Day, Year"
        const formattedDate = `${monthNames[monthIndex]} ${day}, ${year}`;
        
        return formattedDate;
    }

    const altFormatDate = (date) => {
        const monthNames = [
          "January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"
        ];
      
        const day = date.getUTCDate(); // Get the day of the month
        const monthIndex = date.getUTCMonth(); // Get the month (0-indexed)
        const year = date.getUTCFullYear(); // Get the full year
      
        // Format the date as "Month Day, Year"
        return `${monthNames[monthIndex]} ${day}, ${year}`;
    }

    const [subStatus, setSubStatus] = useState('loading')
    const [trialStatus, setTrialStatus] = useState('loading');
    const [expiryDate, setExpiryDate] = useState('')
    // handle subscriptions
    useEffect(() => {
        if(!authUserData) return;

        const fetchSubData = async () => {
            // create a query object to the current users active subscriptions
            const q = query(
                // currentUser is provided by firebase, via getAuth().currentUser
                collection(firestoreDb, 'customers', authUserData.token, 'subscriptions'), 
                where('status', 'in', ['trialing', 'active'])
            );
            
            // fetch the active subscriptions
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                const trialStatusRef = ref(database, `users/${authUserData.token}/trial_expiry`);
                const trailStatusInfo = await get(trialStatusRef)

                if (trailStatusInfo.exists()) {
                    const givenDateStr = trailStatusInfo.val();
                    const givenDate = new Date(givenDateStr);
                    const currentDate = new Date();

                    const hasPassed = currentDate > givenDate;
                    if(!hasPassed) {
                        setTrialStatus('active');
                        setExpiryDate(altFormatDate(givenDate));
                    }
                }
            }

            // assuming user only has one active subscription max
            if(querySnapshot?.docs[0]?.data()) {
                // console.log(querySnapshot?.docs[0]?.data())
                const seconds = querySnapshot?.docs[0]?.data()?.current_period_end?.seconds;
                if(seconds) setExpiryDate(formatDate(seconds));
                setSubStatus('active');
            } else {
                setSubStatus(null);
            }
        }

        fetchSubData()
    }, [authUserData])

    const navigateToPricing = (event) => {
        event.preventDefault();
        navigate('/#pricing');
    };

    const launchStripePortal = (event) => {
        event.preventDefault();

        // firebaseApp is object created using initializeApp()
        // may need to change server location
        const createPortalLink = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');

        // request Stripe to create a portal link, and redirect user there
        createPortalLink({
            returnUrl: window.location.origin // can set this to a custom page
        }).then((result) => {
            window.location.assign(result.data.url);
        }).catch((error) => {
            // handle error
        });

    };

    return (
        <SingleColumn>
            { authUserData &&
                <div>
                    <PageHeaderText>Account Settings</PageHeaderText>
                    <form>
                        <PageHeaderText small labelFor='user-first-name'>First Name</PageHeaderText>
                        <TextInput name='user-first-name' value={ enteredInfo.first_name } error={ errors.first_name } onChange={ (value) => {setEnteredInfo(currentInfo => ({...currentInfo, first_name: value})); setErrors(errors => ({...errors, first_name: ''})); setHasSaved(false);} } disabled={ !hasLoaded || isClasslinkRostered() } />
                        <PageHeaderText small labelFor='user-last-name'>Last Name</PageHeaderText>
                        <TextInput name='user-last-name' value={ enteredInfo.last_name } error={ errors.last_name } onChange={ (value) => {setEnteredInfo(currentInfo => ({...currentInfo, last_name: value})); setErrors(errors => ({...errors, last_name: ''})); setHasSaved(false);} } disabled={ !hasLoaded || isClasslinkRostered() } />
                        <PageHeaderText small labelFor='user-email'>Email</PageHeaderText>
                        <TextInput name='user-email' value={ enteredInfo.email } error={ errors.email } onChange={ (value) => {setEnteredInfo(currentInfo => ({...currentInfo, email: value})); setErrors(errors => ({...errors, email: ''})); setHasSaved(false);} } disabled={ !hasLoaded || isClasslinkRostered() } />
                        { standardSets?.length > 0 && 
                            <>
                                <PageHeaderText small labelFor='standard-set'>Standard Set(s)</PageHeaderText>
                                <TextBlock nospace>Teach more than one subject? Select multiple standard sets by holding the CTRL key.</TextBlock>
                                <MultiselectInput name='standard-set' options={ standardSets } onModified={standardSetModifiedHandler} selectedValues={selectedSets} short nospace={errors.standards}/>
                                <StandaloneError spaceafter>{errors.standards}</StandaloneError>
                            </>
                        }
                        <Button blue onClick={ saveChangesHandler } disabled={ isSaving || hasSaved }>
                            { !isSaving && !hasSaved && 'Save Settings' }
                            { isSaving && !hasSaved && 'Saving...' }
                            { hasSaved && 'Saved!' }
                        </Button>
                    </form>
                    { authUserData && !isClasslinkRostered() && <Button altblue onClick={ resetPasswordHandler}>Reset Password</Button> }
                    <br />
                    <br />
                    <br />
                    <PageHeaderText small>Subscription Status</PageHeaderText>
                    <TextBlock>
                        { (authUserData && authUserData.classlink_paid) ? <>Your subscription has been paid by your organization.</> :
                        (subStatus === 'active' ? <>You have an active subscription through {expiryDate}. <span onClick={launchStripePortal} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Manage</span></> :
                            ((trialStatus === 'active' || trialStatus === 'expiring_soon') ? <>You have an active free trial expiring on {expiryDate}. <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Upgrade</span></> : <>You do not have an active subscription. <span onClick={navigateToPricing} style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}>Upgrade</span></>))}
                    </TextBlock>
                    <PageHeaderText small>Need Help?</PageHeaderText>
                    <TextBlock>If something doesn't look right or you've encountered a bug, please reach out to <a href='mailto:support@brainraider.com' >support@brainraider.com</a>. We want you to have a great experience using Brain Raider - we are happy to help resolve any issues right away!</TextBlock>
                    
                    { authUserData && !isClasslinkRostered() && <BottomRightButton bigSpace>Delete Account</BottomRightButton>} 
                    {/* <StandaloneError spaceafter>Errors</StandaloneError> */}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            }
            {
                !authUserData &&
                <EmptyBlock>You are not authorized to view this resource.</EmptyBlock>
            }
        </SingleColumn>
    );
}

export default AccountSettingsPage;