import SingleColumn from '../components/UI/SingleColumn';
import { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { database } from '../util/firebase';
import { ref, push, child, update } from 'firebase/database';
import PageHeaderText from '../components/UI/PageHeaderText';
import TextInput from '../components/UI/TextInput';
import Button from '../components/UI/Button';
import TextBlock from '../components/UI/TextBlock';

const EmailListSignup = () => {
    const [setHeaderSize, authUserData] = useOutletContext();
    useEffect(() => {setHeaderSize('large');}, [setHeaderSize]);

    const [email, setEmail] = useState(authUserData?.email || '');
    const [emailError, setEmailError] = useState('');
    const [standards, setStandards] = useState('');
    const [standardsError, setStandardsError] = useState('');
    const [isJoining, setIsJoining] = useState(false);
    const [emailListJoined, setEmailListJoined] = useState(false);

    const joinEmailListHandler = (event) => {
        event.preventDefault();

        let errorCounter = 0;
        if(email === '') {
            setEmailError('This field is required.');
            errorCounter++;
        };
        if(standards === '') {
            setStandardsError('This field is required.');
            errorCounter++;
        };
        if(errorCounter !== 0) return;

        setIsJoining(true);

        try {
            const newEmailKey = push(child(ref(database), 'email_list')).key;
            const updates = {};
            updates[`email_list/${newEmailKey}`] = {email: email, standards: standards};
            update(ref(database), updates);
            setEmailListJoined(true);
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <SingleColumn>
            <div>
                <PageHeaderText>Join Our Email List</PageHeaderText>
                <TextBlock>We are working hard to build out our question banks. If you don't see your subject yet, join our email list to help us know which subjects are in demand - we will send a notification when your subject goes live!</TextBlock>
                <form onSubmit= { joinEmailListHandler }>
                    <TextInput name='user-email' value={ email } placeholder='Email' leftIcon='mail' error={ emailError } onChange={ (value) => {setEmail(value); setEmailError('')} } />
                    <PageHeaderText small>Which standard(s) do you use?</PageHeaderText>
                    <TextInput name='standard-set' value={ standards } placeholder='' error={ standardsError } onChange={ (value) => {setStandards(value); setStandardsError('')} } />
                    <Button onClick={ joinEmailListHandler } disabled={ emailListJoined || isJoining } confirm>
                        { emailListJoined && 'Joined!' }
                        { !emailListJoined && isJoining && 'Joining...' }
                        { !emailListJoined && !isJoining && 'Join Email List' }
                    </Button>
                </form>
            </div>
        </SingleColumn>
    );
};

export default EmailListSignup;