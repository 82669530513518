import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './pages/RootLayout';
import HomePage from './pages/Home.js';
import GroupsPage from './pages/Groups';
import GroupDetailPage from './pages/GroupDetail';
import AssessmentsPage from './pages/Assessments';
import AssessmentEditor from './pages/AssessmentEditor';
import MonitorPage from './pages/Monitor';
import ResultsPage from './pages/Results';
import AccountSettingsPage from './pages/AccountSettings';
import LoginPage from './pages/Login';
import SignupPage from './pages/Signup';
import PasswordReset from './pages/PasswordReset';
import StudentDashboard from './pages/StudentDashboard';
import FeedbackPage from './pages/Feedback';
import QuestionsPage from './pages/Questions';
import AttemptPage from './pages/Attempt';
import TermsPage from './pages/Terms.js';
import PrivacyPage from './pages/Privacy.js';
import ChildrensPrivacyPage from './pages/ChildrensPrivacy.js';
import ThirdPartyVendorsPage from './pages/ThirdPartyVendors.js';
import VerificationPage from './pages/Verification.js';
import AssessmentDeepDive from './pages/AssessmentDeepDive.js';
import Classlink from './pages/Classlink.js';
import CheckoutPage from './pages/Checkout.js';
import Users from './pages/Users.js';
import QuoteGenerator from './pages/QuoteGenerator.js';
import EmailListSignup from './pages/EmailListSignup.js';
import AccountManagement from './pages/AccountManagement.js';
import QuickImport from './pages/QuickImport.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: 'groups',
        element: <GroupsPage />
      },
      {
        path: 'groups/:id',
        element: <GroupDetailPage />
      },
      {
        path: 'assessments',
        element: <AssessmentsPage />
      },
      {
        path: 'assessment-editor/:id',
        element: <AssessmentEditor />
      },
      {
        path: 'monitor',
        element: <MonitorPage />
      },
      {
        path: 'results',
        element: <ResultsPage />
      },
      {
        path: 'account-settings',
        element: <AccountSettingsPage />
      },
      {
        path: 'login',
        element: <LoginPage />
      },
      {
        path: 'signup',
        element: <SignupPage />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'dashboard',
        element: <StudentDashboard />
      },
      {
        path: 'feedback',
        element: <FeedbackPage />
      },
      {
        path: 'questions',
        element: <QuestionsPage />
      },
      {
        path: 'attempts/:id',
        element: <AttemptPage />
      },
      {
        path: 'terms-of-service',
        element: <TermsPage />
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPage />
      },
      {
        path: 'childrens-privacy-policy',
        element: <ChildrensPrivacyPage />
      },
      {
        path: 'third-party-vendors',
        element: <ThirdPartyVendorsPage />
      },
      {
        path: 'verification',
        element: <VerificationPage />
      },
      {
        path: 'assessments/:id',
        element: <AssessmentDeepDive />
      },
      {
        path: '/classlink',
        element: <Classlink />
      },
      {
        path: '/checkout',
        element: <CheckoutPage />
      },
      {
        path: '/users',
        element: <Users />
      },
      {
        path: '/quote-generator',
        element: <QuoteGenerator />
      },
      {
        path: '/join-email-list',
        element: <EmailListSignup />
      },
      {
        path: '/account-management',
        element: <AccountManagement />
      },
      {
        path: '/quick-import',
        element: <QuickImport />
      }
    ]
  }
]);

export default function App() {
  return <RouterProvider router={router} />;
}
